import { IsEnum, IsOptional, IsString } from 'class-validator';
import { Expose } from 'class-transformer';
import { Currency, PurposeType } from '../common/enums';
import { IncotermType } from '../common/enums/incoterm.enum';

export class CustomsDeclaration {
  constructor(props: Partial<CustomsDeclaration>) {
    Object.assign(this, props);
  }

  @Expose()
  @IsEnum(Currency)
  currency!: Currency;

  @Expose()
  @IsEnum(PurposeType)
  type!: PurposeType;

  @Expose()
  @IsOptional()
  @IsString()
  taxId?: string;

  @Expose()
  @IsEnum(IncotermType)
  incoterm!: IncotermType;
}
