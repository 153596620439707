import { Expose, Type } from 'class-transformer';
import { Money } from '.';

export class PaymentSummary {
  constructor(props: Partial<PaymentSummary>) {
    Object.assign(this, props);
  }

  @Expose()
  @Type(() => Money)
  chargeAmount?: Money;

  @Expose()
  @Type(() => Money)
  refundAmount?: Money;

  @Expose()
  @Type(() => Money)
  netAmount?: Money;
}
