import { Expose } from 'class-transformer';
import { IsArray, IsString } from 'class-validator';
import { Timeslot } from './index';

export class BulkPickup {
  constructor(props: Partial<BulkPickup>) {
    Object.assign(this, props);
  }

  @Expose({ name: 'slug' })
  @IsString()
  serviceProvider!: string;

  @Expose({ name: 'orders' })
  @IsArray()
  shipmentIds!: string[];

  @Expose({ name: 'timeSlot' })
  @IsArray()
  timeslots!: Timeslot[];

  @Expose()
  @IsString()
  type!: string;
}
