import React, { useEffect, useRef } from 'react';
import { styled } from '@linaria/react';
import { Snackbar, zIndex } from '@spaceshipapp/cambridge';

import useAlert, { AlertLevels } from './';
import { NAVBAR_WIDTH } from '../../components/Navbar/NavbarDesktop';

const StyledSnackbar = styled(Snackbar)`
  z-index: ${zIndex.ALERT};
  position: fixed;
  top: 1rem;
  left: 50%;
  margin-left: calc(${NAVBAR_WIDTH} / 2);
  transform: translate(-50%, 0);
`;

const Alert: React.FC = () => {
  const timeout = useRef<number | null>(null);
  const [{ message, level, onClose, delay }, { closeAlert }] = useAlert();

  const handleClose = () => {
    closeAlert();
    onClose?.();
  };

  useEffect(() => {
    if (message) {
      clearTimeout(timeout?.current as number);
      timeout.current = setTimeout(handleClose, delay);
    }

    return () => {
      clearTimeout(timeout.current as number);
    };
  }, [message]);

  if (!message) return null;

  return (
    <StyledSnackbar level={level as AlertLevels} onClose={handleClose}>
      {message}
    </StyledSnackbar>
  );
};

export default Alert;
