export enum OrderStatus {
  VOID = 'VOID',
  CANCEL = 'CANCEL',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  COMPLETE = 'COMPLETE',
  PAYING = 'PAYING',
  HOLD = 'HOLD',
  RETURN = 'RETURN',
}
