import { Expose, Type } from 'class-transformer';
import {
  IsEnum,
  IsInt,
  IsNumber,
  IsOptional,
  IsString,
  MaxLength,
  IsNotEmpty,
  Matches,
  Min,
} from 'class-validator';
import { Country } from '../common/enums';

const NAME_REGEX = /^[0-9A-Za-z\s\-.,/'"():;$&@!?%]+$/;

export const unitValueI18nOptions = {
  min: 0.1,
};

export class ItemForm {
  constructor(props: Partial<ItemForm>) {
    Object.assign(this, props);
  }

  @Expose()
  @IsString()
  @Matches(NAME_REGEX, { message: 'ItemCardError.detail.invalidFormat' })
  @IsNotEmpty({ message: 'ItemCardError.detail.required' })
  @MaxLength(255)
  @Type(() => String)
  detail!: string;

  @Expose()
  @IsNotEmpty()
  @IsString()
  @MaxLength(2)
  @IsEnum(Country, { message: 'ItemCardError.countryOfManufacture.required' })
  countryOfManufacture: Country;

  @Expose()
  @IsOptional()
  @IsString()
  @MaxLength(255)
  @Type(() => String)
  hscode?: string;

  @Expose({ name: 'sku' })
  @IsOptional()
  sku?: string;

  @Expose()
  @IsInt()
  @IsOptional()
  @Type(() => Number)
  quantity?: number = 1;

  @Expose()
  @Type(() => Number)
  @IsNumber()
  @IsNotEmpty()
  @Min(unitValueI18nOptions.min, { message: 'ItemCardError.unitValue.min' })
  unitValue!: number; // value of a single item
}
