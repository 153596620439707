import { Expose, Type } from 'class-transformer';
import { IsArray, IsString, ValidateNested } from 'class-validator';
import { Checkpoint } from './Checkpoint.dto';

export class Tracking {
  constructor(props: Partial<Tracking>) {
    Object.assign(this, props);
  }

  @Expose()
  @IsArray()
  @Type(() => Checkpoint)
  @ValidateNested()
  checkpoints: Checkpoint[] = [];

  @Expose()
  @IsString()
  tag!: string;
}
