import { createModel } from '@rematch/core';
import { PaymentMethod } from '../common/enums';
import { BoxType, StripeResponse } from '../dto';
import { listPackage } from '../services/package';
import { getPaymentInfoByMethod } from '../services/paymentMethod';
import { Pagination } from '../types';
import { RootState } from '../stores';
import type { RootModel } from './index';

type ValueOfMethods = `${PaymentMethod}`;
export type Methods = Record<ValueOfMethods, any>;

export type SettingsState = {
  boxTypes: BoxType[];
  paymentMethods: Methods;
};

export const settings = createModel<RootModel>()({
  state: {} as SettingsState,
  reducers: {
    getSettings: (state: SettingsState) => state,
    setBoxTypes: (state: SettingsState, payload: BoxType[]) =>
      ({ ...state, boxTypes: payload } as SettingsState),
    setPaymentMethods: (state: SettingsState, payload: Methods) =>
      ({ ...state, paymentMethods: payload } as SettingsState),
  },
  effects: (dispatch) => ({
    async getSavedBoxTypes(): Promise<Pagination> {
      const { meta, links, resources } = await listPackage();
      dispatch.settings.setBoxTypes(resources);
      return { links, meta };
    },
    updatePaymentMethod(methods: Methods): void {
      dispatch.settings.setPaymentMethods(methods);
    },
    async getPaymentMethods(): Promise<Methods> {
      const data = (await getPaymentInfoByMethod(PaymentMethod.STRIPE)) as StripeResponse;
      const method = { [PaymentMethod.STRIPE]: data };
      dispatch.settings.setPaymentMethods(method);
      return method;
    },
  }),
});

export const selectBoxTypes = (state: RootState): BoxType[] => {
  return state.settings?.boxTypes;
};

export const selectPaymentMethods = (state: RootState): Methods => {
  return state.settings?.paymentMethods;
};
