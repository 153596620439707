import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@linaria/react';
import useQuery, { createURLString } from '../../hooks/useQuery';
import _toNumber from 'lodash/toNumber';
import SearchFilters, {
  FilterType,
  TransactionsStatusFilter,
} from '../../containers/SearchFilters/index';
import {
  FormControl,
  FormLabel,
  Heading,
  Option,
  Pagination,
  Select,
  Skeleton,
  Stack,
  Text,
} from '@spaceshipapp/cambridge';
import { useTranslation } from 'react-i18next';
import { getDatePickerDefaultDates } from '../../utils/helpers';
import { useHistory, useLocation, Link as ReactRouterLink } from 'react-router-dom';
import SkeletonTable from '../../components/SkeletonTable';
import Page from '../../components/Page';
import { css } from '@linaria/core';
import { useDebounce } from 'use-debounce/lib';
import { selectAllPayments } from '../../models/transactions';
import { PaginationMeta } from '../../types';
import { isTransactionFiltered } from './utils';
import PaymentsSummary from '../../containers/Transactions/PaymentsSummary';
import TransactionsList from '../../containers/Transactions/TransactionsList/index';
import { sendTransactionRecordsFetched } from '../../analytics/transactions';
import { parseKeywords } from '../../containers/SearchFilters/utils';

const Transactions: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { getPurchaseOrdersPayments, getPurchaseOrdersPaymentSummary } = dispatch.transactions;
  const payments = useSelector(selectAllPayments);
  const query = useQuery();
  const search = query.get('search') || '';
  const [debouncedSearch] = useDebounce(search, 800);
  const page = _toNumber(query.get('page') || '1');
  const reasonType = (query.get('status') as TransactionsStatusFilter) || '';
  const receiverCountry = query.get('receiverCountry') || '';
  const carrier = query.get('carrier') || '';
  const multiSearch = query.get('multiSearch') || '';
  const createdFrom = query.get('createdFrom') || getDatePickerDefaultDates().prevDate;
  const createdTo = query.get('createdTo') || getDatePickerDefaultDates().currDate;
  const perPage = Number(query.get('pageSize')) || 15;
  const keywords = parseKeywords(search).join(',');
  const noResultTextValue =
    keywords.length > 80 ? [keywords.substring(0, 80), '...'].join('') : keywords;

  const isFiltered = isTransactionFiltered(
    !!location.search,
    search,
    reasonType,
    carrier,
    receiverCountry,
    createdFrom,
    createdTo,
  );
  const [paginationMeta, setPaginationMeta] = useState<PaginationMeta>();
  const { lastPage } = paginationMeta || {};

  const refetch = useCallback(async () => {
    setIsLoading(true);
    const params = {
      page,
      reasonTypes: reasonType ? [reasonType] : [],
      keywords: parseKeywords(debouncedSearch),
      receiverCountries: receiverCountry ? [receiverCountry] : [],
      from: createdFrom,
      to: createdTo,
      perPage,
      carrierServices: carrier ? [carrier] : [],
    };
    const { meta } = await getPurchaseOrdersPayments(params);
    await getPurchaseOrdersPaymentSummary(params);

    setPaginationMeta(meta);
    setIsLoading(false);
    sendTransactionRecordsFetched({
      courier_service: carrier,
      destination: receiverCountry,
      input_count: params.keywords.length,
      transaction_date_max: createdTo,
      transaction_date_min: createdFrom,
      transaction_type: reasonType,
    });
  }, [
    debouncedSearch,
    page,
    reasonType,
    receiverCountry,
    carrier,
    createdFrom,
    createdTo,
    perPage,
  ]);

  useEffect(() => {
    refetch();
  }, [
    debouncedSearch,
    page,
    reasonType,
    receiverCountry,
    carrier,
    createdFrom,
    createdTo,
    perPage,
  ]);

  const handlePageSizeChange = (value: string) => {
    const url = createURLString(location.pathname, {
      page: 1,
      search,
      carrier,
      reasonType,
      receiverCountry,
      createdFrom,
      createdTo,
      pageSize: Number(value),
    });
    history.replace(url);
  };

  return (
    <StyledPage className="container">
      <Stack>
        <HeadingBar>
          <Heading as="h1" size="md">
            {t('Transactions.heading')}
          </Heading>
        </HeadingBar>
        <SearchFilters
          filterType={FilterType.TRANSACTIONS}
          page={page}
          carrier={carrier}
          search={search}
          multiSearch={multiSearch}
          status={reasonType}
          receiverCountry={receiverCountry}
          createdFrom={createdFrom}
          createdTo={createdTo}
        />
        {isFiltered && (
          <>
            <Skeleton height="1.1875rem" width="17.125rem" isLoaded={!isLoading} />
            {!isLoading && (
              <Text size="sm">
                {t('Transactions.matchedSearchResults', { paymentsLength: paginationMeta?.total })}
              </Text>
            )}
          </>
        )}
        <PaymentsSummary isLoaded={!isLoading} />

        <SkeletonTable isLoaded={!isLoading}>
          {payments.length > 0 && <TransactionsList isLoaded={!isLoading} />}

          {payments.length === 0 && (
            <EmptyPlaceholder>
              <Heading as="h3" size="sm">
                {t('Order.noResultHeading')}
              </Heading>
              {debouncedSearch && (
                <Text size="sm" className="subtext">
                  {t('Order.noResultText', {
                    debouncedSearch: noResultTextValue,
                  })}
                </Text>
              )}
            </EmptyPlaceholder>
          )}
        </SkeletonTable>
        <BottomBar>
          <FormControl className={pageSizeSelectStyle}>
            <FormLabel>{t('ShipmentList.formLabelMaxRowPerPage')}</FormLabel>
            <Select id="pageSize" onChange={handlePageSizeChange} selectedItem={perPage}>
              <Option value={15}>15</Option>
              <Option value={50}>50</Option>
            </Select>
          </FormControl>
          {payments.length > 0 && page && lastPage && (
            <Pagination
              size="xs"
              rootPath={`${createURLString(location.pathname, {
                search,
                status: reasonType,
                carrier,
                receiverCountry,
                createdFrom,
                createdTo,
                pageSize: perPage,
              })}&page=`}
              currentPage={page}
              lastPage={lastPage}
              link={ReactRouterLink}
              loading={isLoading}
              hideLastButton
            />
          )}
        </BottomBar>
      </Stack>
    </StyledPage>
  );
};

export const BottomBar = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const StyledPage = styled(Page)`
  padding-bottom: 8rem;
`;

const HeadingBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const pageSizeSelectStyle = css`
  flex: 0 0 10rem;
`;

const EmptyPlaceholder = styled.div`
  text-align: center;
  width: fit-content;
  margin: 7rem auto;
`;

export default Transactions;
