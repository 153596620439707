import { Exclude, Expose, Type } from 'class-transformer';
import { IsArray, IsNumber, IsOptional, IsString, Min, ValidateNested } from 'class-validator';
import { ParserError } from '../common/errors';
import { ShipmentItem } from './ShipmentItem.dto';

export const PACKAGE_UNIT_WEIGHT_MAX_LIMIT = 30;

export class Package {
  constructor(props: Partial<Package>) {
    Object.assign(this, props);
  }

  @Exclude()
  @IsOptional()
  @IsString()
  orderId?: string;

  @Expose()
  @IsOptional()
  @IsString()
  boxId?: string;

  @Expose()
  @IsNumber()
  height!: number;

  @Expose()
  @IsOptional()
  @IsNumber()
  length!: number;

  @Expose()
  @IsNumber()
  width!: number;

  @Expose()
  @Min(0.1)
  weight!: number;

  @Expose()
  @Min(0.1)
  unitWeight!: number;

  @Expose()
  @IsNumber()
  quantity!: number;

  @Expose()
  @IsArray()
  @Type(() => ShipmentItem)
  @ValidateNested()
  items: ShipmentItem[] = [];

  errors?: ParserError[] = [];
}
