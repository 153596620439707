import { Validate, IsDateString } from 'class-validator';

import { IsDateBeforeOrEqual } from './constraints/isDateBeforeOrEqual';
import { IsDateInRange } from './constraints/isDateInRange';

export class DatePicker {
  constructor(props: Partial<DatePicker>) {
    Object.assign(this, props);
  }

  @Validate(IsDateBeforeOrEqual, ['createdTo'])
  @IsDateString()
  createdFrom!: string;

  @Validate(IsDateInRange, ['createdFrom', 30])
  @IsDateString()
  createdTo!: string;
}
