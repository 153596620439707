import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Text } from '@spaceshipapp/cambridge';
import { Transaction } from '../../../../dto/Transaction.dto';
import { getlocale } from '../../../../utils/helpers';
import formatter from '../../../../utils/formatter';
import { btnStyle, refundTextStyle, subTextStyle } from './styled';
import { cx } from '@linaria/core';
import { Money } from '../../../../dto';
import { handleInvoiceDownload } from '../utils';
import { TransactionsType } from '../../../../types/transactions';

export type RowDataType = Transaction;

interface Props {
  rowData: RowDataType;
}

const TransactionRow: React.FC<Props> = ({ rowData }) => {
  const { t, i18n } = useTranslation();
  const locale = getlocale(i18n.resolvedLanguage);
  const isRefund = rowData.type === TransactionsType.REFUND;

  return (
    <Fragment>
      <tr>
        <td>
          <Text size="sm">
            {formatter.date(
              rowData.createdAt as unknown as string,
              locale,
              t('DateFormat.dateFormat'),
            )}
          </Text>
          <Text className={subTextStyle} size="sm">
            {formatter.date(
              rowData.createdAt as unknown as string,
              locale,
              t('DateFormat.simpleTimeFormat'),
            )}
          </Text>
        </td>
        <td>
          <Text size="sm">{rowData.orderId}</Text>
          <Text className={subTextStyle} size="sm">
            {t(`countries:Countries.${rowData.receiverCountry}`)}
          </Text>
        </td>
        <td>
          <Text size="sm">
            {t(`TransactionsList.typeOptions.${TransactionsType[rowData.type]}`)}
          </Text>
        </td>

        <td>
          <Text size="sm">{rowData.logisticProviderName}</Text>
          <Text className={subTextStyle} size="sm">
            {rowData.logisticServiceName}
          </Text>
        </td>
        <td>{<Text size="sm">{rowData.trackingNumber}</Text>}</td>
        <td>
          <Text className={cx(isRefund && refundTextStyle)} size="sm">
            {formatter.price(rowData.finalTotal as Money)}
          </Text>
        </td>
        <td>
          <Button
            className={btnStyle}
            size="sm"
            variant="primary"
            onClick={() =>
              handleInvoiceDownload(
                rowData.invoiceUrl,
                rowData.logisticProviderName,
                rowData.trackingNumber,
                formatter.price(rowData.finalTotal as Money),
                rowData.receiverCountry,
              )
            }
          >
            {t('TransactionsList.buttonDownloadInvoice')}
          </Button>
        </td>
      </tr>
    </Fragment>
  );
};

export default TransactionRow;
