import React, { Suspense } from 'react';
import { Route, RouteProps, useRouteMatch } from 'react-router-dom';
import Layout from '../Layout';
import Loading from './Loading';

const PublicRoute: React.FC<RouteProps> = ({ component: Component, path }) => {
  const isTracking = !!useRouteMatch('/tracking/:id');

  return (
    <Layout isTracking={isTracking} isPublic={!isTracking}>
      <Suspense fallback={<Loading />}>
        <Route component={Component} path={path} />
      </Suspense>
    </Layout>
  );
};

export default PublicRoute;
