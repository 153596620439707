import React from 'react';
import { useLocation } from 'react-router-dom';

const useQuery = (): URLSearchParams => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const createURLString = (pathname: string, params?: Record<string, any>): string => {
  const url = new URLSearchParams();
  if (params) {
    Object.keys(params).forEach((key) => {
      if (params[key]) {
        url.set(key, params[key]);
      }
    });
  }
  return `${pathname}?${url.toString()}`;
};

export default useQuery;
