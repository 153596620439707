import axios, { AxiosResponse, AxiosRequestConfig, Method } from 'axios';
import { store } from '../stores';
import { getStoredLocale } from '../utils/helpers';

export interface RequestConfig {
  method: Method;
  path?: string;
  url?: string;
  data?: any;
  params?: any;
  headers?: Record<string, string>;
  isPublic?: boolean;
  timeout?: number;
}

export async function apiService(request: RequestConfig): Promise<AxiosResponse> {
  const { headers, isPublic, timeout } = request;
  const state = store.getState();
  const currentLocale = getStoredLocale();
  const token = !isPublic ? state.auth.accessToken : null;

  const config = {
    headers: {
      'Content-Type': 'application/json',
      'x-locale': `${currentLocale === 'en' ? 'en-HK' : currentLocale}`,
      ...headers,
      ...(token && { Authorization: `Bearer ${token}` }),
    },
  };

  const { method, path } = request;
  const url = request.url || `${process.env.REACT_APP_API_BASE_URL}${path}`;

  return axios({
    ...config,
    data: request.data,
    params: request.params,
    method,
    url,
    timeout,
  } as AxiosRequestConfig);
}
