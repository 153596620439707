import { IsArray } from 'class-validator';
import { Expose } from 'class-transformer';
import { PurchaseOrder } from '.';

export class BulkCreateShipments {
  constructor(props: Partial<BulkCreateShipments>) {
    Object.assign(this, props);
  }

  @Expose()
  @IsArray()
  purchaseOrders!: Pick<PurchaseOrder, 'id'>[];
}
