import { AlertState } from '.';
import { Action, SHOW_ALERT, CLOSE_ALERT } from './actions';

export enum AlertLevels {
  'info' = 'info',
  'error' = 'error',
  'success' = 'success',
}

export const initState: AlertState = {
  level: 'info',
  delay: 3000,
};

export const alertReducer = (state = initState, action: Action): AlertState => {
  const { type, payload } = action;

  switch (type) {
    case SHOW_ALERT: {
      return { ...state, ...payload };
    }
    case CLOSE_ALERT: {
      return { ...initState };
    }
    default:
      return state;
  }
};

export default alertReducer;
