import React from 'react';
import {
  Link as ReactRouterLink,
  LinkProps,
  NavLink as ReactRouterNavLink,
  NavLinkProps,
  Redirect as ReactRouterRedirect,
  RedirectProps,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface LocaleLinkProps {
  locale?: string;
}

export const Link = ({
  to,
  children,
  locale,
  ...remain
}: LinkProps & LocaleLinkProps): React.ReactElement => {
  const { i18n } = useTranslation();
  const lang = locale || i18n.resolvedLanguage;
  const defaultLang = i18n.languages[i18n.languages.length - 1];

  const href = lang === defaultLang ? to : `/${lang.toLowerCase()}${to}`;

  return (
    <ReactRouterLink to={href} {...remain}>
      {children}
    </ReactRouterLink>
  );
};

export const NavLink = ({ to, children, ...remain }: NavLinkProps): React.ReactElement => {
  const { i18n } = useTranslation();
  const lang = i18n.resolvedLanguage;
  const defaultLang = i18n.languages[i18n.languages.length - 1];

  const href = lang === defaultLang ? to : `/${lang.toLowerCase()}${to}`;

  return (
    <ReactRouterNavLink to={href} {...remain}>
      {children}
    </ReactRouterNavLink>
  );
};

export const Redirect = ({ to, ...remain }: RedirectProps): React.ReactElement => {
  const { i18n } = useTranslation();
  const lang = i18n.resolvedLanguage;
  const defaultLang = i18n.languages[i18n.languages.length - 1];

  const href = lang === defaultLang ? to : `/${lang.toLowerCase()}${to}`;

  return <ReactRouterRedirect to={href} {...remain} />;
};

export default Link;
