export enum Currency {
  HKD = 'HKD',
  USD = 'USD',
  EUR = 'EUR',
  GBP = 'GBP',
  JPY = 'JPY',
  KRW = 'KRW',
  CNY = 'CNY',
  SGD = 'SGD',
  THB = 'THB',
  CAD = 'CAD',
  AUD = 'AUD',
  MOP = 'MOP',
  ARS = 'ARS',
  BRL = 'BRL',
  CZK = 'CZK',
  DKK = 'DKK',
  EGP = 'EGP',
  HUF = 'HUF',
  ILS = 'ILS',
  INR = 'INR',
  IDR = 'IDR',
  KES = 'KES',
  MYR = 'MYR',
  MXN = 'MXN',
  TWD = 'TWD',
  TRY = 'TRY',
  NZD = 'NZD',
  NOK = 'NOK',
  PKR = 'PKR',
  PHP = 'PHP',
  PLN = 'PLN',
  RUB = 'RUB',
  SAR = 'SAR',
  ZAR = 'ZAR',
  SEK = 'SEK',
  CHF = 'CHF',
  AED = 'AED',
}
