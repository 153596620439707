import { AlertState } from './';
import { Dispatch } from '../types';

export interface Action {
  type: string;
  payload: AlertState;
}

export const SHOW_ALERT = 'SHOW_ALERT';
export const CLOSE_ALERT = 'CLOSE_ALERT';

export const show =
  (dispatch: Dispatch) =>
  (payload: AlertState): void => {
    dispatch({ type: SHOW_ALERT, payload });
  };

export const close = (dispatch: Dispatch) => (): void => {
  dispatch({ type: CLOSE_ALERT });
};
