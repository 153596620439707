import { Expose } from 'class-transformer';
import { IsOptional, IsString } from 'class-validator';

export class Checkpoint {
  constructor(props: Partial<Checkpoint>) {
    Object.assign(this, props);
  }

  @Expose()
  @IsString()
  checkpointTime!: string;

  @Expose()
  @IsOptional()
  @IsString()
  city?: string;

  @Expose()
  @IsString()
  country!: string;

  @Expose()
  @IsString()
  location!: string;

  @Expose()
  @IsString()
  message!: string;

  @Expose()
  @IsOptional()
  @IsString()
  state?: string;

  @Expose()
  @IsString()
  tag!: string;

  @Expose()
  @IsOptional()
  @IsString()
  zip?: string;
}
