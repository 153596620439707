import {
  IsEnum,
  IsDefined,
  IsString,
  IsBoolean,
  IsNumber,
  IsEmail,
  IsArray,
  IsOptional,
} from 'class-validator';
import { Expose, Transform } from 'class-transformer';

import { CustomerType, IncotermType } from '../common/enums';

export class Customer {
  constructor(props: Partial<Customer>) {
    Object.assign(this, {
      ...props,
    });
  }

  @Expose()
  @IsDefined()
  @IsString()
  ref!: string;

  @Expose()
  @IsDefined()
  @IsString()
  language?: string;

  @Expose()
  @IsDefined()
  @IsEnum(CustomerType)
  userType!: CustomerType | null;

  @Expose()
  @IsDefined()
  @IsString()
  name!: string | null;

  @Expose({ name: 'company' })
  @IsDefined()
  @IsString()
  companyName!: string | null;

  @Expose()
  @IsDefined()
  @IsEmail()
  email!: string | undefined;

  @Expose()
  @IsDefined()
  @IsString()
  phone!: string | null;

  @Expose({ name: 'phoneCc' })
  @IsDefined()
  @IsString()
  phoneCountryCode!: string | null;

  @Expose()
  @IsDefined()
  @IsArray()
  chanel!: 'sms' | 'email' | 'whatsapp'[];

  @Expose()
  @IsDefined()
  @IsBoolean()
  basic!: boolean;

  @Expose()
  @IsDefined()
  @IsBoolean()
  newUser!: boolean;

  @Expose()
  @IsDefined()
  @IsBoolean()
  autoPassAdjustment!: boolean;

  @Expose()
  @IsDefined()
  @IsBoolean()
  isMergeLabels!: boolean;

  @Expose()
  @IsDefined()
  @IsBoolean()
  bypass!: boolean;

  @Expose()
  @IsDefined()
  @IsBoolean()
  adminLogin!: boolean | null;

  @Expose()
  @IsDefined()
  @IsBoolean()
  isIossUser!: boolean;

  @Expose()
  @IsEnum(IncotermType)
  defaultIncoterm?: IncotermType;

  @Expose()
  createdAt?: Date;

  @Expose()
  @IsOptional()
  @IsString()
  inchargeSales?: string;

  @Expose()
  @IsOptional()
  @IsString()
  lastWaybill?: string;

  @Expose()
  @IsOptional()
  @IsString()
  lastWaybillDate?: Date;

  @Expose()
  @IsOptional()
  @IsString()
  registerPlatform?: string;

  @Expose()
  @IsOptional()
  @IsNumber()
  totalShipment?: number;

  @Expose()
  @IsOptional()
  @IsNumber()
  @Transform(({ value }) => Math.round(value * 100) / 100 ?? value)
  totalSpending?: number;
}
