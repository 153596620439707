import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Heading, Modal, Text } from '@spaceshipapp/cambridge';

import { Payment } from '../../../../dto/Payment.dto';
import { getlocale } from '../../../../utils/helpers';
import formatter from '../../../../utils/formatter';
import {
  btnStyle,
  modalStyle,
  ModalWrapper,
  refundTextStyle,
  subTextStyle,
  textPadding,
} from '../TransactionRow/styled';

import { handleInvoiceDownload, isDataFiltered } from '../utils';
import TransactionsList from '..';
import { cx } from '@linaria/core';
import { TransactionsType } from '../../../../types/transactions';

interface Props {
  rowData: Payment;
}

const PaymentRow: React.FC<Props> = ({ rowData }) => {
  const { t, i18n } = useTranslation();
  const locale = getlocale(i18n.resolvedLanguage);

  const isRefund = rowData.transaction.type === TransactionsType.REFUND;
  const hasBreakdown = rowData.statistics.totalTransactionCount > 1;
  const isTransactionFiltered = isDataFiltered(
    rowData.statistics.totalTransactionCount,
    rowData.statistics.filteredTransactionCount,
  );

  return (
    <Fragment>
      <tr>
        <td>
          <Text size="sm">
            {formatter.date(
              rowData.transaction.createdAt as unknown as string,
              locale,
              t('DateFormat.dateFormat'),
            )}
          </Text>
          <Text className={subTextStyle} size="sm">
            {formatter.date(
              rowData.transaction.createdAt as unknown as string,
              locale,
              t('DateFormat.simpleTimeFormat'),
            )}
          </Text>
        </td>
        <td>
          {rowData.statistics.totalTransactionCount > 1 ? (
            <>
              {isTransactionFiltered && (
                <Text size="sm">
                  {t(`TransactionsList.totalTransactionCount`, {
                    totalTransactionCount: rowData.statistics.filteredTransactionCount,
                  })}
                </Text>
              )}
              <Text className={cx(isTransactionFiltered && subTextStyle)} size="sm">
                {t(
                  `TransactionsList.${
                    isTransactionFiltered ? 'filteredTransactionCount' : 'totalTransactionCount'
                  }`,
                  {
                    totalTransactionCount: rowData.statistics.totalTransactionCount,
                  },
                )}
              </Text>
            </>
          ) : (
            <>
              <Text size="sm">{rowData.transaction.orderId}</Text>
              <Text className={subTextStyle} size="sm">
                {t(`countries:Countries.${rowData.transaction.receiverCountry}`)}
              </Text>
            </>
          )}
        </td>
        <td>
          <Text size="sm">
            {t(`TransactionsList.typeOptions.${TransactionsType[rowData.transaction.type]}`)}
          </Text>
        </td>

        <td>
          {rowData.statistics.totalCarrierServiceCount > 1 ? (
            <Text size="sm">{t(`TransactionsList.multipleService`)}</Text>
          ) : (
            <>
              <Text size="sm">{rowData.transaction.logisticProviderName}</Text>
              <Text className={subTextStyle} size="sm">
                {rowData.transaction.logisticServiceName}
              </Text>
            </>
          )}
        </td>
        <td>
          {rowData.statistics.totalTrackingNumberCount > 1 ? (
            <>
              {isTransactionFiltered && (
                <Text size="sm">
                  {t(`TransactionsList.totalTrackingNumberCount`, {
                    totalTrackingNumberCount: rowData.statistics.filteredTransactionCount,
                  })}
                </Text>
              )}
              <Text className={cx(isTransactionFiltered && subTextStyle)} size="sm">
                {t(
                  `TransactionsList.${
                    isTransactionFiltered
                      ? 'filteredTrackingNumberCount'
                      : 'totalTrackingNumberCount'
                  }`,
                  {
                    totalTrackingNumberCount: rowData.statistics.totalTransactionCount,
                  },
                )}
              </Text>
            </>
          ) : (
            <Text size="sm">{rowData.transaction.trackingNumber}</Text>
          )}
        </td>
        <td>
          {rowData.statistics.totalTransactionCount > 1 ? (
            <>
              {isTransactionFiltered && (
                <Text className={cx(isRefund && refundTextStyle)} size="sm">{`${formatter.price(
                  rowData.statistics.filteredPaymentAmount,
                )}`}</Text>
              )}
              <Text
                className={cx(
                  isTransactionFiltered && subTextStyle,

                  isRefund && !isTransactionFiltered && refundTextStyle,
                )}
                size="sm"
              >
                {t(`TransactionsList.${isTransactionFiltered ? 'filteredAmount' : 'totalAmount'}`, {
                  totalAmount: `${formatter.price(rowData.statistics.totalPaymentAmount)}`,
                })}
              </Text>
            </>
          ) : (
            <Text className={cx(isRefund && refundTextStyle)} size="sm">
              {formatter.price(rowData.statistics.totalPaymentAmount)}
            </Text>
          )}
        </td>
        <td>
          {hasBreakdown ? (
            <Modal
              className={modalStyle}
              title={
                <>
                  <Heading className={textPadding} size="md">
                    {t('TransactionsList.breakdownModalHeading')}
                  </Heading>
                  <Text className={textPadding} size="sm">
                    {t('TransactionsList.totalTransactionCount', {
                      totalTransactionCount: rowData.statistics.filteredTransactionCount,
                    })}
                  </Text>
                </>
              }
              trigger={
                <Button size="sm" variant="default" className={btnStyle} block>
                  {t('TransactionsList.buttonViewBreakdown')}
                </Button>
              }
            >
              <ModalWrapper>
                <TransactionsList isModalElement paymentId={rowData.id} />
              </ModalWrapper>
            </Modal>
          ) : (
            <Button
              className={btnStyle}
              size="sm"
              variant="primary"
              onClick={() =>
                handleInvoiceDownload(
                  rowData.transaction.invoiceUrl,
                  rowData.transaction.logisticProviderName,
                  rowData.transaction.trackingNumber,
                  formatter.price(rowData.statistics.totalPaymentAmount),
                  rowData.transaction.receiverCountry,
                )
              }
            >
              {t('TransactionsList.buttonDownloadInvoice')}
            </Button>
          )}
        </td>
      </tr>
    </Fragment>
  );
};

export default PaymentRow;
