import React from 'react';
import { styled } from '@linaria/react';
import { useLocation } from 'react-router-dom';
import { colors, zIndex, Popover, Button, List, usePopoverOpen } from '@spaceshipapp/cambridge';
import logo from '../../assets/images/logo-b.svg';
import { Link } from '../../components/Link';
import { useTranslation } from 'react-i18next';
import { setStoredLocale } from '../../utils/helpers';

const locales = ['en', 'zh-HK'];

const trans: Record<string, string> = {
  en: 'English',
  'zh-HK': '繁體中文',
};

const PublicNavbar: React.FC = () => {
  const location = useLocation();

  // only register page and login page have language switcher.
  const showLocaleSwitch =
    location.pathname.includes('/register') || location.pathname.includes('/login');

  return (
    <Header>
      <Nav className="container">
        <Logo>
          <Link to="/login">
            <img src={logo} alt="Spaceship Pro" height={33} width={222} />
          </Link>
        </Logo>
        {showLocaleSwitch && (
          <BtnGroup>
            <Popover trigger={<Button iconName="Globe" size="sm" variant="link" />}>
              <Languages />
            </Popover>
          </BtnGroup>
        )}
      </Nav>
    </Header>
  );
};

export const Languages: React.FC = () => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const togglePopover = usePopoverOpen();

  const currentLang = i18n.resolvedLanguage;
  const defaultLang = i18n.languages[i18n.languages.length - 1];
  const currentPathname = location.pathname.toLowerCase();

  const targetPath =
    currentLang === defaultLang
      ? currentPathname
      : currentPathname.replace(`/${currentLang.toLowerCase()}`, '');

  const changeLocale = (locale: string) => {
    setStoredLocale(locale);
    i18n.changeLanguage(locale);
  };

  const handleClose = () => {
    togglePopover(false);
  };

  return (
    <Menu onClick={handleClose}>
      <List>
        {locales.map((locale) => (
          <Item key={locale}>
            <Link to={targetPath} locale={locale} onClick={() => changeLocale(locale)}>
              {trans[locale]}
            </Link>
          </Item>
        ))}
      </List>
    </Menu>
  );
};

const Header = styled.header`
  padding: 1.5em 0;
  background-color: ${colors.brandSecondary};
  border-bottom: 1px solid ${colors.border};
  position: sticky;
  z-index: ${zIndex.HEADER};
  top: 0;
`;

const Logo = styled.div`
  display: flex;
  align-items: center;

  > *:not(:first-child) {
    margin-left: 0.5rem;
  }
`;

const BtnGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > *:not(:first-child) {
    margin-left: 0.5rem;
  }

  /* fix to allow menu align on the right */
  div[role='dialog'] {
    min-width: 20rem;
  }
`;

const Nav = styled.nav`
  display: grid;
  grid-template-columns: 1fr 1fr;

  a {
    text-decoration: none;

    &.active {
      font-weight: 600;
    }
  }
`;

const Menu = styled.div`
  border: solid 1px ${colors.border};
  box-shadow: 0 0.25em 0.75em rgba(${colors.text}, 0.08);
  background-color: ${colors.surface};
  width: max(320px, 100%);
`;

const Item = styled.li`
  display: flex;
  cursor: default;

  > * {
    display: block;
    width: 100%;
    padding: 1em;
  }

  > a:hover,
  > button:hover {
    background-color: ${colors.brandSecondary};
  }
`;

export default PublicNavbar;
