import { IsOptional, IsString, IsNumber, IsBoolean, IsEnum, IsArray } from 'class-validator';
import { Expose, Type, Transform } from 'class-transformer';
import { LogisticServiceCode, LogisticProviderCode, IncotermType } from '../common/enums';
import { Translation } from '.';

export class Quotation {
  constructor(props: Partial<Quotation>) {
    Object.assign(this, props);
  }

  @Expose()
  @IsBoolean()
  enable!: boolean;

  @Expose()
  @IsBoolean()
  readonly!: boolean;

  @Expose()
  @IsBoolean()
  noService!: boolean;

  @Expose()
  @IsBoolean()
  noTracking!: boolean;

  @Expose()
  @IsString()
  @IsEnum(LogisticProviderCode)
  carrier!: string;

  @Expose()
  @IsString()
  @IsEnum(LogisticServiceCode)
  service!: string;

  @Expose()
  @IsEnum(LogisticServiceCode)
  slug!: string;

  @Expose()
  @IsOptional()
  @IsString()
  logo?: string;

  @Expose()
  @Type(() => Translation)
  carrierName!: Translation;

  @Expose()
  @Type(() => Translation)
  name!: Translation;

  @Expose()
  @Type(() => Translation)
  description!: Translation;

  @Expose()
  @IsArray()
  incoterms!: IncotermType[];

  @Expose()
  @IsEnum(IncotermType)
  incoterm!: IncotermType;

  @Expose()
  @IsBoolean()
  pickup!: boolean;

  @Expose()
  @IsOptional()
  @IsNumber()
  @Transform(({ value }) => Math.round(value * 100) / 100 ?? value)
  pickupFee?: number;

  @Expose()
  @IsOptional()
  deliveryDate?: Date;

  @Expose()
  @IsOptional()
  @IsNumber()
  minDeliveryDays?: number;

  @Expose()
  @IsOptional()
  @IsNumber()
  maxDeliveryDays?: number;

  @Expose()
  @IsNumber()
  @Transform(({ value }) => Math.round(value * 100) / 100)
  freightCharges!: number;

  @Expose()
  @IsNumber()
  @Transform(({ value }) => Math.round(value * 100) / 100)
  fuelSurcharge!: number;

  @Expose()
  @IsNumber()
  @Transform(({ value }) => Math.round(value * 100) / 100)
  otherCharges!: number;

  @Expose()
  @IsNumber()
  @Transform(({ value }) => Math.round(value * 100) / 100)
  totalRate!: number;
}
