import { Expose, Type, Transform } from 'class-transformer';
import { IsDefined, IsOptional, IsString, IsBoolean } from 'class-validator';
import { ParserError } from '../common/errors';

export class AddressCreate {
  constructor(props: Partial<AddressCreate>) {
    Object.assign(this, props);
  }

  @Expose()
  @IsString()
  @IsOptional()
  name?: string;

  @Expose({ name: 'companyName' })
  @IsString()
  @IsOptional()
  company?: string;

  @Expose()
  @IsString()
  @IsOptional()
  @Type(() => String)
  phone?: string;

  @Expose()
  @IsOptional()
  @IsString()
  @Type(() => String)
  phoneCountryCode?: string;

  @Expose()
  @IsOptional()
  @IsString()
  email?: string;

  @Expose()
  @IsDefined()
  @IsString()
  street1?: string;

  @Expose()
  @IsOptional()
  @IsString()
  street2?: string;

  @Expose()
  @IsOptional()
  @IsString()
  street3?: string;

  @Expose()
  @IsString()
  @IsOptional()
  city?: string;

  @Expose()
  @IsOptional()
  @IsString()
  state?: string;

  @Expose()
  @IsString()
  @IsOptional()
  country?: string;

  @Expose()
  @IsBoolean()
  @IsOptional()
  @Transform(
    ({ value }) =>
      (typeof value === 'string' && ['y', 'yes', 'true'].includes(value.toLowerCase())) ||
      value === true,
  )
  isResidential?: boolean;

  @Expose()
  @IsOptional()
  @IsString()
  @Type(() => String)
  postalCode?: string;

  errors?: ParserError[] = [];
}
