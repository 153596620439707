import { Expose, Exclude, Type } from 'class-transformer';
import { IsEnum, IsInt, IsNumber, IsOptional, IsString, MaxLength } from 'class-validator';
import { Currency } from '../common/enums';
import { ParserError } from '../common/errors';

export class ItemDataCreate {
  constructor(props: Partial<ItemDataCreate>) {
    Object.assign(this, props);
  }

  @Exclude()
  id?: string;

  @Expose()
  @IsString()
  @MaxLength(255)
  @Type(() => String)
  detail!: string;

  @Expose()
  @IsOptional()
  @IsString()
  countryOfManufacture?: string;

  @Expose()
  @IsOptional()
  @IsString()
  @MaxLength(255)
  @Type(() => String)
  hscode?: string;

  @Expose()
  @IsOptional()
  sku?: string;

  @Expose()
  @IsInt()
  @IsOptional()
  quantity?: number = 1;

  @Expose()
  @IsNumber()
  @IsOptional()
  unitValue?: number; // value of a single item

  @IsEnum(Currency)
  @IsOptional()
  currency?: Currency;

  errors?: ParserError[] = [];
}
