import { IsObject, IsUrl, IsOptional, IsString, ValidateIf, IsNumberString } from 'class-validator';
import { Expose } from 'class-transformer';

export class ProfileUpdate {
  constructor(props: Partial<ProfileUpdate>) {
    Object.assign(this, props);
  }

  @Expose()
  @IsOptional()
  @ValidateIf((e) => e.phone !== '')
  @IsNumberString({}, { message: 'Register.errors.invalidPhoneNumber' })
  phone!: string | null;

  @Expose()
  @IsOptional()
  @IsString()
  phoneCountryCode!: string | null;

  @Expose()
  @IsOptional()
  @ValidateIf((e) => e.website !== '')
  @IsUrl({}, { message: 'Register.errors.invalidWebsiteFormat' })
  website!: string;

  @Expose()
  @IsOptional()
  @IsObject({
    message: 'Register.errors.monthlyShipmentsIsRequired',
  })
  monthlyShipments!: { min: number | null; max: number | null };
}
