import { Expose } from 'class-transformer';
import { IsDefined, IsEnum, IsNumber } from 'class-validator';
import { Currency } from '../common/enums';

export class Money {
  constructor(props: Partial<Money>) {
    Object.assign(this, props);
  }

  @Expose({ name: 'value' })
  @IsDefined()
  @IsNumber()
  value!: number;

  @Expose({ name: 'currency' })
  @IsEnum(Currency)
  currency!: Currency;
}
