import { IsEmail, ValidateIf, IsString, MaxLength, MinLength, IsNotEmpty } from 'class-validator';
import { Expose } from 'class-transformer';

export class Register {
  constructor(props: Partial<Register>) {
    Object.assign(this, props);
  }
  @Expose()
  @IsString({ message: 'Register.errors.invalidNameFormat' })
  @IsNotEmpty({ message: 'Register.errors.nameIsRequired' })
  name!: string;

  @Expose()
  @IsEmail({}, { message: 'Register.errors.invalidEmailFormat' })
  email!: string;

  @Expose()
  @IsString({ message: 'Register.errors.invalidPasswordFormat' })
  @MinLength(6, { message: 'Register.errors.invalidPasswordFormat' })
  password!: string;

  @Expose()
  @ValidateIf((o) => !!o.referrerCode)
  @MinLength(6, { message: 'Register.errors.invalidReferralCodeFormat' })
  @MaxLength(6, { message: 'Register.errors.invalidReferralCodeFormat' })
  referrerCode?: string;
}
