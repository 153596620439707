import { Expose, Type } from 'class-transformer';
import { IsEnum, IsNumber, IsArray } from 'class-validator';
import { PurchaseOrder } from './PurchaseOrder.dto';
import { ProcessBulkPurchaseOrdersStatus } from '../common/enums';

export class BulkPurchaseOrdersConfirmationResponse {
  constructor(props: Partial<BulkPurchaseOrdersConfirmationResponse>) {
    Object.assign(this, props);
  }

  @Expose()
  @IsNumber()
  id!: number;

  @IsArray()
  @Type(() => PurchaseOrder)
  purchaseOrders?: PurchaseOrder[] = [];

  @Expose()
  @IsEnum(ProcessBulkPurchaseOrdersStatus)
  status!: ProcessBulkPurchaseOrdersStatus;
}
