import React, { useEffect, useRef } from 'react';
import { css } from '@linaria/core';
import { Button } from '@spaceshipapp/cambridge';
import { forwardRef } from 'react';

interface Props extends React.ButtonHTMLAttributes<HTMLDivElement> {
  disabled?: boolean;
  isOpen?: boolean;
}

const HiddenButton = (props: Props, ref: React.ForwardedRef<HTMLDivElement>) => {
  const { isOpen, children, ...remain } = props;
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (buttonRef.current && isOpen !== undefined) buttonRef.current.click();
  }, [isOpen]);

  return (
    <div {...remain} ref={ref}>
      <Button type="button" className={hiddenButtonStyle} ref={buttonRef}>
        {children}
      </Button>
    </div>
  );
};

const hiddenButtonStyle = css`
  display: none;
`;

export default forwardRef(HiddenButton);
