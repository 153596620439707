import {
  ValidatorConstraint,
  ValidatorConstraintInterface,
  ValidationArguments,
} from 'class-validator';

@ValidatorConstraint({ name: 'IsDateInRange', async: false })
export class IsDateInRange implements ValidatorConstraintInterface {
  validate(propertyValue: string, args: ValidationArguments): boolean {
    const propertyName = args.constraints[0];
    const range = args.constraints[1];

    const date1 = new Date(propertyValue);
    const date2 = new Date((args.object as any)[propertyName]);

    const diffTime = Math.abs(date2.getTime() - date1.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays <= range;
  }

  defaultMessage(): string {
    return `DatePicker.dateRangeError`;
  }
}
