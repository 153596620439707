import { deserialize, plainToInstance } from 'class-transformer';
import { apiService } from './api';
import { AddressBook, AddressBookCreate } from '../dto';
import { PaginationLinks, PaginationMeta } from '../types';
import { Country } from '../common/enums';

export interface PaginatedAddressBooks {
  links: PaginationLinks;
  meta: PaginationMeta;
  resources: AddressBook[];
}

export async function createAddressBook(input: AddressBookCreate): Promise<AddressBook | null> {
  try {
    const payload = deserialize(AddressBookCreate, JSON.stringify(input));
    const path = '/address-books';

    const res = await apiService({
      method: 'post',
      data: payload,
      path,
    });

    const { success, data } = res?.data;

    if (!success) throw new Error('Error creating address');
    return plainToInstance(AddressBook, data as AddressBook);
  } catch (err: any) {
    throw err?.response?.data ?? err;
  }
}

export async function deleteAddressBook(id: number): Promise<boolean> {
  try {
    const path = `/address-books/${id}`;

    await apiService({
      method: 'delete',
      path,
    });

    return true;
  } catch (err: any) {
    throw err?.response?.data ?? err;
  }
}

export async function updateAddressBook(
  id: number,
  input: AddressBookCreate,
): Promise<AddressBook | null> {
  try {
    const payload = deserialize(AddressBookCreate, JSON.stringify(input));
    const path = `/address-books/${id}`;

    const res = await apiService({
      method: 'put',
      data: payload,
      path,
    });

    const { success, data } = res?.data;

    if (!success) throw new Error('Error updating address');
    return plainToInstance(AddressBook, data as AddressBook);
  } catch (err: any) {
    throw err?.response?.data ?? err;
  }
}

export async function listAddressBooks(
  url?: string,
  country: Country = Country.HK,
): Promise<PaginatedAddressBooks | null> {
  try {
    const path = url ?? `/address-books/${country}`;
    const res = await apiService({ method: 'get', path });
    const { success, data } = res?.data;

    if (!success) throw new Error('Error listing addresses');

    return {
      ...data,
      resources: data.resources.map((el: AddressBook) => plainToInstance(AddressBook, el)),
    };
  } catch (err: any) {
    throw err?.response?.data ?? err;
  }
}

export async function getDefaultAddressBook(country: Country): Promise<AddressBook> {
  try {
    const path = `/address-books/${country}/default`;
    const res = await apiService({
      method: 'get',
      path,
    });
    const { data, success } = res?.data;

    if (data && success) {
      return data;
    } else {
      throw new Error('Error getting default address');
    }
  } catch (err: any) {
    throw err?.response?.data ?? err;
  }
}
