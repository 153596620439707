import { IsArray, IsOptional, IsString } from 'class-validator';
import { Expose } from 'class-transformer';

export class PurchaseOrderError {
  constructor(props: Partial<PurchaseOrderError>) {
    Object.assign(this, props);
  }

  @Expose()
  @IsString()
  @IsOptional()
  createShipmentError?: string;

  @Expose()
  @IsArray()
  @IsOptional()
  missFields?: string[];

  @Expose()
  @IsOptional()
  invalidFields?: Record<string, string>;

  @Expose()
  @IsString()
  @IsOptional()
  paidError?: string;

  @Expose()
  @IsString()
  @IsOptional()
  suspenseServiceError?: string;
}
