import { History } from 'history';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useLocaleHistory = (): History => {
  const { i18n } = useTranslation();
  const { push, ...remain } = useHistory();

  const lang = i18n.resolvedLanguage;
  const defaultLang = i18n.languages[i18n.languages.length - 1];

  const pushWithLocale = (path: string, state?: unknown) => {
    const href = lang === defaultLang ? path : `/${lang.toLowerCase()}${path}`;
    return push(href, state);
  };

  return { push: pushWithLocale, ...remain };
};

export default useLocaleHistory;
