import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { enUS } from 'date-fns/locale';
import { zhTW } from 'date-fns/locale';
import { Country } from '../common/enums';
import { Address, Money, AddressBook, Package } from '../dto';
import { COUNTRIES_NAME_MAP } from '../common/constants/countries';

class Formatter {
  date(date?: string, locale?: string, pattern = 'd MMM yyyy H:mm'): string {
    if (!date) return '';
    return format(parseISO(date), pattern, {
      locale: locale === 'zh' ? zhTW : enUS,
    });
  }

  address(address: Address | AddressBook): string {
    const { street1, street2, street3, city, state, country } = address;
    const addressItems = [
      street1,
      street2,
      street3,
      city,
      state,
      COUNTRIES_NAME_MAP[country as Country] || country,
    ].filter((el) => el);
    return addressItems.join(', ');
  }

  price(money: Money): string {
    const { value, currency } = money;
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
    }).format(value);
  }

  itemNumber(packages: Package[]): number {
    return packages.map((el) => el?.items?.length ?? 0).reduce((a: number, b: number) => a + b, 0);
  }

  phone(phone: string, countryCode: string): string {
    const number = phone.replace(/^0/, ''); // remove leading zero for mobile phone nubmer
    return countryCode + number;
  }
}

export default new Formatter();
