import { IsArray, IsEnum, IsOptional, IsString, IsUrl, ValidateNested } from 'class-validator';
import { Expose, Transform, Type } from 'class-transformer';
import { Address, Money, Package, PurchaseOrderError, PurchaseOrderQuotation, Shipment } from '.';
import {
  Currency,
  PurposeType,
  LogisticProviderCode,
  LogisticServiceCode,
  PurchaseOrderStatus,
  OrderStatus,
} from '../common/enums';
import { IncotermType } from '../common/enums';
import { translateOrderStatus } from './Shipment.dto';

export class PurchaseOrder {
  constructor(props: Partial<PurchaseOrder>) {
    Object.assign(this, props);
  }

  @Expose()
  @IsString()
  id!: string;

  @Expose({ name: 'orderNumber' })
  @IsString()
  orderId!: string;

  @Expose()
  @IsEnum(Currency)
  currency!: Currency;

  @Expose({ name: 'shipmentPurpose' })
  @IsEnum(PurposeType)
  @IsOptional()
  type?: PurposeType;

  @Expose()
  @IsOptional()
  @IsEnum(LogisticProviderCode)
  logisticProvider?: string;

  @Expose()
  @IsOptional()
  @IsEnum(LogisticServiceCode)
  logisticServiceCode?: LogisticServiceCode;

  @Expose()
  @IsArray()
  logisticServiceIncoterms?: IncotermType[];

  @Expose()
  @IsOptional()
  @IsString()
  trackingNumber?: string;

  @Expose()
  @IsOptional()
  @IsString()
  taxId?: string;

  @Expose()
  @IsArray()
  @Type(() => Package)
  packages: Package[] = [];

  @Expose({ name: 'receiver' })
  @Type(() => Address)
  @ValidateNested()
  receiverAddress?: Address;

  @Expose({ name: 'shipper' })
  @Type(() => Address)
  @ValidateNested()
  shipperAddress?: Address;

  @Expose()
  @IsEnum(IncotermType)
  incoterm!: IncotermType;

  @Expose()
  @IsEnum(PurchaseOrderStatus)
  status!: PurchaseOrderStatus;

  @Expose()
  @IsOptional()
  @IsUrl()
  labelUrl?: string;

  @Expose()
  @IsOptional()
  errors?: PurchaseOrderError;

  @Expose()
  @IsOptional()
  quotation?: PurchaseOrderQuotation;

  @Expose()
  @IsOptional()
  shipmentRef?: string;

  @Expose()
  createdAt?: Date;

  @Expose()
  @IsOptional()
  transactionRef?: string;

  @Expose()
  @IsOptional()
  finalTotal?: Money;

  @Expose()
  @IsOptional()
  shipment?: Shipment;

  @Expose()
  @IsOptional()
  transactionCreatedAt?: Date;

  @Expose()
  @IsOptional()
  @IsEnum(OrderStatus)
  @Transform(({ value }) => translateOrderStatus(value), { toClassOnly: true })
  transactionStatus?: OrderStatus;
}
