import Shipment from '../../containers/OrdersList/Shipment/index';
import { Country } from '../../common/enums';
export interface TransactionPayload {
  id: number;
  finalTotal: Record<string, any>;
  shipment: Shipment;
  invoice: Record<string, string>;
  payment: Payment;
}

export interface PaymentPayload {
  id: number;
  createdAt: string;
  reasonType: TransactionsType;
  shipment: Shipment;
  invoice: Record<string, string>;
  payment: Payment;
  statistics: Statistic;
}

export interface Statistic {
  totalPaymentAmount: Record<string, any>;
  filteredPaymentAmount: Record<string, any>;
  totalTransactionCount: number;
  filteredTransactionCount: number;
  totalCarrierServiceCount: Record<string, string>;
}

export enum TransactionsType {
  SHIPMENT_CHARGE = 'SHIPMENT_CHARGE',
  ADJUSTMENT = 'ADJUSTMENT',
  REFUND = 'REFUND',
  All = 'All',
}

interface Shipment {
  orderNumber: string;
  trackingNumber: string;
  toAddress: Record<string, Country>;
  carrierService: CarrierService;
}

interface Payment {
  id: number;
  createdAt: string;
  reasonType: TransactionsType;
}

interface CarrierService {
  name: Record<string, string>;
  carrierName: Record<string, string>;
}
