import { Expose } from 'class-transformer';
import { IsNumber, IsOptional, IsString } from 'class-validator';
import { ParserError } from '../common/errors';

export const BOX_TYPE_SIZE_MAX_LIMIT = 120;

export class BoxType {
  constructor(props: Partial<BoxType>) {
    Object.assign(this, props);
  }

  @Expose()
  @IsNumber()
  @IsOptional()
  id?: number;

  @Expose()
  @IsString()
  name!: string;

  @Expose()
  @IsNumber()
  height!: number;

  @Expose()
  @IsNumber()
  width!: number;

  @Expose()
  @IsNumber()
  length!: number;

  errors?: ParserError[] = [];
}
