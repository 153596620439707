import React, { lazy, useEffect, Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Segment from '../../components/Segment';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import { Redirect } from '../../components/Link';
import Transactions from '../../pages/Transactions';
import { RootState } from '../../stores';

const Login = lazy(() => import('../../pages/Auth/Login'));
const Logout = lazy(() => import('../../pages/Auth/Logout'));
const Register = lazy(() => import('../../pages/Auth/Register'));
const Orders = lazy(() => import('../../pages/Orders'));
const OrderCreate = lazy(() => import('../../pages/Orders/OrderCreate'));
const OrderUpdate = lazy(() => import('../../pages/Orders/OrderUpdate'));
const Shipments = lazy(() => import('../../pages/Shipments'));
const Pickups = lazy(() => import('../../pages/Pickups'));
const PickupCreate = lazy(() => import('../../pages/Pickups/PickupCreate'));
const OrderImport = lazy(() => import('../../pages/Orders/OrderImport'));
const Settings = lazy(() => import('../../pages/Settings'));
const Tracking = lazy(() => import('../../pages/Tracking'));

const App: React.FC = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);

  useEffect(() => {
    dispatch.auth.resumeSession();
    if (isAuthenticated) dispatch.auth.getUserInfo();
    dispatch.services.getCarrierServices();
  }, []);

  return (
    <Fragment>
      <Segment />
      <Switch>
        <PrivateRoute path="/:lang?/logout" component={Logout} />
        <PrivateRoute path="/:lang?/orders/create" component={OrderCreate} />
        <PrivateRoute path="/:lang?/orders/import" component={OrderImport} />
        <PrivateRoute path="/:lang?/orders/:id" component={OrderUpdate} />
        <PrivateRoute path="/:lang?/orders" component={Orders} />
        <PrivateRoute path="/:lang?/shipments" component={Shipments} />
        <PrivateRoute path="/:lang?/transactions" component={Transactions} />
        <PrivateRoute path="/:lang?/pickups/create" component={PickupCreate} />
        <PrivateRoute path="/:lang?/pickups" component={Pickups} />
        <PrivateRoute path="/:lang?/settings" component={Settings} />
        <PublicRoute path="/:lang?/login" component={Login} />
        <PublicRoute path="/:lang?/register" component={Register} />
        <PublicRoute path="/:lang?/tracking/:id" component={Tracking} />
        <Route>
          <Redirect to="/orders" />
        </Route>
      </Switch>
    </Fragment>
  );
};

export default App;
