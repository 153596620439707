import Analytics from './index';
import { TransactionsStatusFilter } from '../containers/SearchFilters';

interface TransactionRecords {
  courier_service: string;
  destination: string;
  input_count: number;
  transaction_date_max: string;
  transaction_date_min: string;
  transaction_type: TransactionsStatusFilter;
}

interface TransactionRecordsBreakdown extends TransactionRecords {
  payment_id: string;
  breakdown_transaction_count?: number;
}

interface InvoiceDownload {
  courier_service?: string;
  waybill_no?: string;
  total_value?: string;
  destination?: string;
}

export const sendTransactionRecordsFetched = ({
  courier_service,
  destination,
  input_count,
  transaction_date_max,
  transaction_date_min,
  transaction_type,
}: TransactionRecords): void => {
  Analytics.event('Transaction records fetched', {
    courier_service,
    destination,
    input_count,
    transaction_date_max,
    transaction_date_min,
    transaction_type,
  });
};

export const sendTransactionRecordsBreakdownFetched = ({
  courier_service,
  destination,
  input_count,
  transaction_date_max,
  transaction_date_min,
  transaction_type,
  payment_id,
  breakdown_transaction_count,
}: TransactionRecordsBreakdown): void => {
  Analytics.event('Transaction records breakdown fetched', {
    courier_service,
    destination,
    input_count,
    transaction_date_max,
    transaction_date_min,
    transaction_type,
    payment_id,
    breakdown_transaction_count,
  });
};

export const sendShipmentInvoiceDownloaded = ({
  courier_service,
  waybill_no,
  total_value,
  destination,
}: InvoiceDownload): void => {
  Analytics.event('Shipment invoice downloaded', {
    courier_service,
    waybill_no,
    total_value,
    destination,
  });
};
