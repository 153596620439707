import { deserialize, deserializeArray, plainToInstance } from 'class-transformer';

import {
  AddressCreate,
  PurchaseOrder,
  ShipmentItem,
  ItemDataCreate,
  BulkPurchaseOrdersConfirmationResponse,
  BulkCreateShipments,
  ShipmentEventResponse,
  PurchaseOrderCreate,
  PackageCreate,
  BulkPurchaseOrdersEdit,
  PurchaseOrderUpdate,
  Money,
  Package,
  Address,
} from '../dto';
import { apiService } from './api';
import { Pagination } from '../types';
import { Currency, PurposeType } from '../common/enums';
import { IncotermType } from '../common/enums/incoterm.enum';

export function getShippingFee(input: Record<string, any>): Money {
  const { amount, currency, minor } = input;
  const value = amount / 10 ** minor;
  return new Money({
    value,
    currency: Currency[currency as keyof typeof Currency],
  });
}

function sumReducer(acc: number, curr: number): number {
  return acc + curr;
}

export async function bulkCreatePurchaseOrders(
  input: PurchaseOrderCreate[],
): Promise<BulkPurchaseOrdersConfirmationResponse> {
  try {
    const orders = convertInput(input);
    const payload = {
      purchaseOrders: orders,
      source: 'SPACESHIP_BIZ',
    };
    const path = `/purchase-orders/bulk`;
    const res = await apiService({
      method: 'post',
      data: payload,
      path,
    });

    const { data, success } = res?.data;

    if (data && success) {
      return {
        id: data.id,
        status: data.status,
        purchaseOrders: data.purchaseOrders,
      };
    } else {
      throw new Error('Failed to bulk create purchase orders');
    }
  } catch (err: any) {
    if (err.response) {
      throw err.response.data;
    } else {
      throw err;
    }
  }
}

export async function createPurchaseOrder(input: PurchaseOrderCreate): Promise<PurchaseOrder> {
  try {
    const path = `/purchase-orders`;
    const res = await apiService({
      method: 'post',
      data: input,
      path,
    });
    const { data } = res?.data;

    if (data) {
      const order = parsePurchaseOrder(data);
      return deserialize(PurchaseOrder, JSON.stringify(order));
    } else {
      throw new Error('Failed to create purchase order');
    }
  } catch (err) {
    throw err;
  }
}

export async function getPurchaseOrder(id: string): Promise<PurchaseOrder> {
  try {
    const path = `/purchase-orders/${id}`;
    const res = await apiService({
      method: 'get',
      path,
    });
    const { data } = res?.data;

    if (data) {
      const order = parsePurchaseOrder(data);
      return deserialize(PurchaseOrder, JSON.stringify(order), {
        excludeExtraneousValues: true,
      });
    } else {
      throw new Error('Failed to get purchase order');
    }
  } catch (err) {
    throw err;
  }
}

export async function removePurchaseOrder(ids: string[]): Promise<void> {
  const path = '/purchase-orders/bulk/delete';
  const res = await apiService({
    method: 'post',
    path,
    data: {
      purchaseOrders: ids.map((id) => ({ id })),
    },
  });
  return res?.data;
}

export interface GetPaginatedPurchaseOrdersInput {
  page: number;
  status?: string[];
  from?: string;
  to?: string;
  search?: string;
  receiverCountry?: string;
  perPage?: number;
}

export async function getPurchaseOrders(
  input: GetPaginatedPurchaseOrdersInput,
): Promise<{ purchaseOrders: PurchaseOrder[] } & Pagination> {
  try {
    const { page, search, status, receiverCountry, from, to, perPage = 15 } = input;
    const path = '/purchase-orders';
    const res = await apiService({
      method: 'get',
      path,
      params: {
        page,
        keyword: search,
        status,
        receiverCountry,
        from,
        to,
        orderBy: 'createdAt',
        sorting: 'DESC',
        perPage,
      },
    });
    const { data } = res?.data;

    if (data) {
      const { resources, meta, links } = data;
      const orders = resources.map(parsePurchaseOrder);

      return {
        purchaseOrders: deserializeArray(PurchaseOrder, JSON.stringify(orders)),
        meta,
        links,
      };
    }
    throw new Error('Failed to get purchase orders');
  } catch (err) {
    throw err;
  }
}

export async function getPurchaseOrdersByIds(ids: string[]): Promise<PurchaseOrder[]> {
  try {
    const path = '/purchase-orders';
    const res = await apiService({
      method: 'get',
      path,
      params: { ids, perPage: ids.length > 50 ? 50 : ids.length }, // max perPage size
    });
    const { data } = res?.data;
    if (data) {
      const { resources } = data;
      const orders = resources.map(parsePurchaseOrder);

      return deserializeArray(PurchaseOrder, JSON.stringify(orders));
    }
    throw new Error('Failed to get purchase orders');
  } catch (err) {
    throw err;
  }
}

export async function updatePurchaseOrder(
  input: Partial<PurchaseOrderUpdate>,
): Promise<PurchaseOrder> {
  const { id, ...remain } = input;
  try {
    const path = `/purchase-orders/${id}`;
    const res = await apiService({
      method: 'patch',
      data: remain,
      path,
    });
    const { data } = res?.data;

    if (data) {
      const order = parsePurchaseOrder(data);
      return deserialize(PurchaseOrder, JSON.stringify(order), {
        excludeExtraneousValues: true,
      });
    } else {
      throw new Error('Failed to update purchase order');
    }
  } catch (err) {
    throw err;
  }
}

export interface BulkEditPayload {
  orders: PurchaseOrder[];
  logisticServiceCode?: string;
  countryOfManufacture?: string;
  currency?: string;
  incoterm?: IncotermType;
  packages?: Package[];
  type?: PurposeType;
  shipper?: Address;
}

export async function bulkUpdatePurchaseOrders(payload: BulkEditPayload): Promise<PurchaseOrder[]> {
  try {
    const path = `/purchase-orders/bulk/edit`;
    const res = await apiService({
      method: 'post',
      data: convertToBulkPurchaseOrdersEdit(payload),
      path,
    });
    const { data } = res?.data;

    if (data) {
      const { resources } = data;
      const orders = resources.map(parsePurchaseOrder);

      return deserializeArray(PurchaseOrder, JSON.stringify(orders));
    }
    return [];
  } catch (err) {
    throw err;
  }
}

export async function bulkCreatePurchaseOrdersQuotation(
  input: PurchaseOrder[],
): Promise<PurchaseOrder[]> {
  try {
    const purchaseOrders = getIdList(input);
    const path = `/purchase-orders/quotations`;
    const res = await apiService({
      method: 'post',
      data: { purchaseOrders },
      path,
    });
    const { data } = res?.data;

    if (data) {
      const { purchaseOrders } = data;
      const orders = purchaseOrders.map(parsePurchaseOrder);

      return deserializeArray(PurchaseOrder, JSON.stringify(orders));
    }
    return [];
  } catch (err) {
    throw err;
  }
}

export async function bulkCreateShipments(input: PurchaseOrder[]): Promise<ShipmentEventResponse> {
  try {
    const payload = convertToBulkCreateShipments(input);
    const path = `/purchase-orders/shipment-events`;
    const res = await apiService({
      method: 'post',
      data: payload,
      path,
    });
    const { data, success } = res?.data;

    if (data && success) {
      const { id, status, purchaseOrders } = data;
      const orders = purchaseOrders.map(parsePurchaseOrder);

      return {
        id,
        status,
        purchaseOrders: deserializeArray(PurchaseOrder, JSON.stringify(orders)),
      };
    }
    throw new Error('Failed to bulk create shipments');
  } catch (err) {
    throw err;
  }
}

export async function getShipmentEvent(eventId: number): Promise<ShipmentEventResponse> {
  try {
    const path = `/purchase-orders/shipment-events/${eventId}`;
    const res = await apiService({
      method: 'get',
      path,
    });
    const { data, success } = res?.data;

    if (data && success) {
      const { id, status, purchaseOrders } = data;
      const orders = purchaseOrders.map(parsePurchaseOrder);

      return {
        id,
        status,
        purchaseOrders: deserializeArray(PurchaseOrder, JSON.stringify(orders)),
      };
    }
    throw new Error('Failed to get bulk create shipments status');
  } catch (err) {
    throw err;
  }
}

export async function createPurchaseOrdersPayment(orders: PurchaseOrder[]): Promise<boolean> {
  try {
    const payload = {
      slug: 'stripe',
      purchaseOrders: orders.map((order) => ({ id: order.id })),
    };

    const path = `/purchase-orders/payment`;
    const res = await apiService({
      method: 'post',
      data: payload,
      path,
    });

    const { success } = res?.data;
    return success;
  } catch (err: any) {
    if (err.response) {
      throw err.response.data;
    } else {
      throw err;
    }
  }
}

const convertInput = (input: PurchaseOrderCreate[] = []): any =>
  input.map(({ orderNumber, shipmentPurpose, packages, receiver, shipper, taxId, incoterm }) => {
    const currency = packages?.[0]?.items?.[0]?.currency;

    return {
      orderNumber,
      currency,
      shipmentPurpose,
      taxId,
      incoterm,
      packages: convertPackages(packages),
      receiver: convertAddress(receiver),
      shipper: convertAddress(shipper),
    };
  });

const convertAddress = (address?: AddressCreate) => {
  if (!address) {
    return {};
  }

  const { street1, street2, street3, ...remain } = address;
  return {
    street1: street1?.toString(),
    street2: street2?.toString(),
    street3: street3?.toString(),
    ...remain,
  };
};

const convertShipmentItems = (items: ItemDataCreate[] = []): any =>
  items.map((item) => {
    const { detail, hscode, sku, unitValue, ...remain } = item;
    return {
      detail,
      hscode,
      unitValue,
      sku: sku?.toString(),
      ...remain,
    };
  });

const convertPackages = (packages: PackageCreate[] = []): any =>
  packages.map((pkg) => {
    const { height, width, length, unitWeight, items } = pkg;
    return {
      height,
      width,
      length,
      unitWeight,
      items: convertShipmentItems(items),
    };
  });

export const sumTotalValue = (items: ShipmentItem[] = []): number => {
  return items
    .map((s: ShipmentItem) => Number(s.unitValue) * Number(s.quantity))
    .reduce(sumReducer, 0);
};

const getIdList = (input: PurchaseOrder[] = []): Pick<PurchaseOrder, 'id'>[] => {
  return input.map((p) => ({ id: p.id }));
};

const convertToBulkPurchaseOrdersEdit = (input: BulkEditPayload): BulkPurchaseOrdersEdit => {
  const { orders, packages, ...rest } = input;
  const purchaseOrders = getIdList(orders);
  const pkg = packages?.[0];

  return plainToInstance(
    BulkPurchaseOrdersEdit,
    { purchaseOrders, package: pkg, ...rest },
    { excludeExtraneousValues: true },
  );
};

const convertToBulkCreateShipments = (input: PurchaseOrder[] = []): BulkCreateShipments => {
  const purchaseOrders = getIdList(input);

  return plainToInstance(
    BulkCreateShipments,
    { purchaseOrders },
    { excludeExtraneousValues: true },
  );
};

const parsePurchaseOrder = (order: any) => {
  return {
    ...order,
    finalTotal: order?.finalTotal && getShippingFee(order.finalTotal),
    logisticProvider: order?.carrierService?.carrier,
    logisticServiceCode: order?.carrierService?.slug,
    logisticServiceIncoterms: order?.carrierService?.incoterms,
  };
};
