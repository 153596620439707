import { IsEnum, IsDefined, IsOptional, IsString, IsBoolean, Length } from 'class-validator';
import { Expose } from 'class-transformer';
import { Country, stringToCountry } from '../common/enums';

export class Address {
  constructor(props: Partial<Address>) {
    const { country } = props || {};
    Object.assign(this, {
      ...props,
      ...(country && { country: stringToCountry(country) }),
    });
  }

  @Expose()
  @IsDefined()
  @IsString()
  @Length(1, 35)
  name!: string;

  @Expose({ name: 'company' })
  @IsOptional()
  @IsString()
  companyName?: string;

  @Expose()
  @IsString()
  @IsDefined()
  phone!: string;

  @Expose()
  @IsDefined()
  @IsString()
  phoneCountryCode!: string;

  @Expose()
  @IsOptional()
  @IsString()
  email?: string;

  @Expose()
  @IsDefined()
  @IsString()
  street1!: string;

  @Expose()
  @IsOptional()
  @IsString()
  street2?: string;

  @Expose()
  @IsOptional()
  @IsString()
  street3?: string;

  @Expose()
  @IsDefined()
  @IsString()
  city!: string;

  @Expose()
  @IsOptional()
  @IsString()
  state?: string;

  @Expose()
  @IsDefined()
  @IsEnum(Country)
  country!: Country;

  @Expose()
  @IsOptional()
  @IsString()
  postalCode?: string;

  @Expose()
  @IsBoolean()
  @IsOptional()
  isResidential?: boolean;
}
