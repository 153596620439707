import { apiService } from './api';

import { ImportTemplate } from '../containers/Import/types';

export async function createImportTemplate(
  name: string,
  mapping: Record<string, string>,
): Promise<{ data: ImportTemplate }> {
  try {
    const path = '/purchase-orders/spreadsheet-configs/';
    const data = {
      name,
      keys: mapping,
    };
    const res = await apiService({
      method: 'post',
      data,
      path,
    });

    return res.data;
  } catch (e: any) {
    throw e.response.data;
  }
}

export async function fetchImportTemplate(): Promise<{ resources: ImportTemplate[] }> {
  try {
    const path = '/purchase-orders/spreadsheet-configs/';
    const res = await apiService({ method: 'get', path });

    return res.data.data;
  } catch (e: any) {
    throw e.response.data;
  }
}
