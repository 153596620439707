import { Validate, IsDateString } from 'class-validator';
import { IsDateBeforeOrEqual } from './constraints/isDateBeforeOrEqual';
import { IsDateInRange } from './constraints/isDateInRange';

export class ShipmentExport {
  constructor(props: Partial<ShipmentExport>) {
    Object.assign(this, props);
  }

  @Validate(IsDateBeforeOrEqual, ['createdTo'])
  @IsDateString()
  createdFrom!: string;

  @Validate(IsDateInRange, ['createdFrom', 7])
  @IsDateString()
  createdTo!: string;
}
