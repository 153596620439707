import React from 'react';
import { styled } from '@linaria/react';
import { useFormContext } from 'react-hook-form';
import { Stack, Button, Input, FormControl, FormLabel } from '@spaceshipapp/cambridge';
import { useTranslation } from 'react-i18next';

export interface DatePickerProps {
  createdFrom?: string;
  createdTo?: string;
  onReset: () => void;
  disabled?: boolean;
  submitLabel?: string;
  cancelLabel?: string;
  validDateRange: number;
}

const DatePicker: React.FC<DatePickerProps> = ({
  createdFrom,
  createdTo,
  onReset,
  disabled = false,
  validDateRange,
}) => {
  const { formState, register } = useFormContext();
  const { isSubmitting, errors } = formState;
  const { t } = useTranslation();

  const handleClose = () => {
    onReset();
  };

  return (
    <Stack>
      <Row>
        <FormControl errorMessage={t(errors?.createdFrom?.message, { days: validDateRange })}>
          <FormLabel htmlFor="createdFrom">{t('DatePicker.startDate')}</FormLabel>
          <Input
            {...register('createdFrom')}
            id="createdFrom"
            type="date"
            isError={!!errors?.createdFrom?.message}
            defaultValue={createdFrom}
            disabled={disabled}
          />
        </FormControl>
        <FormControl errorMessage={t(errors?.createdTo?.message, { days: validDateRange })}>
          <FormLabel htmlFor="createdTo">{t('DatePicker.endDate')}</FormLabel>
          <Input
            {...register('createdTo')}
            id="createdTo"
            type="date"
            isError={!!errors?.createdTo?.message}
            defaultValue={createdTo}
            disabled={disabled}
          />
        </FormControl>
      </Row>
      <Row>
        <Button onClick={handleClose} disabled={disabled || isSubmitting} block>
          {t('DatePicker.buttonClearAll')}
        </Button>
        <Button type="submit" variant="primary" loading={isSubmitting} disabled={disabled} block>
          {t('DatePicker.buttonApply')}
        </Button>
      </Row>
    </Stack>
  );
};

const Row = styled.div`
  display: grid;
  gap: 1em;
  grid-template-columns: repeat(2, minmax(0, 1fr));
`;

export default DatePicker;
