import { Expose, Type } from 'class-transformer';
import { IsNumber } from 'class-validator';
import { Money } from './Money.dto';

export class PaymentStatistics {
  constructor(props: Partial<PaymentStatistics>) {
    Object.assign(this, props);
  }

  @Expose()
  @Type(() => Money)
  totalPaymentAmount!: Money;

  @Expose()
  @Type(() => Money)
  filteredPaymentAmount!: Money;

  @Expose()
  @IsNumber()
  totalTransactionCount!: number;

  @Expose()
  @IsNumber()
  totalTrackingNumberCount!: number;

  @Expose()
  @IsNumber()
  filteredTransactionCount!: number;

  @Expose()
  @IsNumber()
  filteredTrackingNumberCount!: number;

  @Expose()
  @IsNumber()
  totalCarrierServiceCount!: number;
}
