export enum ShipmentStatus {
  LABEL_CREATED = 'label-created',
  TO_BE_COLLECTED = 'to-be-collected',
  PROCESSING = 'processing',
  ACTIONS_REQUIRED = 'actions-required',
  ON_THE_WAY = 'on-the-way',
  ON_HOLD = 'on-hold',
  DELIVERED = 'delivered',
  CANCELLED = 'cancelled',
  ALL = 'all',
}
