import React, { Fragment } from 'react';
import { Skeleton } from '@spaceshipapp/cambridge';
import { Table, TableHeader, TH, TBody } from '../Table';

interface Props {
  isLoaded?: boolean;
}

const SkeletonTable: React.FC<Props> = ({ isLoaded, children }) => {
  if (isLoaded) return <Fragment>{children}</Fragment>;

  return (
    <Table>
      <TableHeader>
        <tr>
          <TH>
            <Skeleton height="2rem" width="auto" isLoaded={false} />
          </TH>
        </tr>
      </TableHeader>
      <TBody>
        <tr>
          <td>
            <Skeleton height="2rem" width="auto" isLoaded={false} />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton height="2rem" width="auto" isLoaded={false} />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton height="2rem" width="auto" isLoaded={false} />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton height="2rem" width="auto" isLoaded={false} />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton height="2rem" width="auto" isLoaded={false} />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton height="2rem" width="auto" isLoaded={false} />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton height="2rem" width="auto" isLoaded={false} />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton height="2rem" width="auto" isLoaded={false} />
          </td>
        </tr>
        <tr>
          <td>
            <Skeleton height="2rem" width="auto" isLoaded={false} />
          </td>
        </tr>
      </TBody>
    </Table>
  );
};

export default SkeletonTable;
