import { getDatePickerDefaultDates } from '../../utils/helpers';

export const isTransactionFiltered = (
  isUrlContainsParams: boolean,
  search: string,
  reasonType: string,
  carrier: string,
  receiverCountry: string,
  createdFrom: string,
  createdTo: string,
): boolean => {
  return (
    isUrlContainsParams &&
    (!!search ||
      !!reasonType ||
      !!carrier ||
      !!receiverCountry ||
      createdFrom !== getDatePickerDefaultDates().prevDate ||
      createdTo !== getDatePickerDefaultDates().currDate)
  );
};
