import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Text, Skeleton } from '@spaceshipapp/cambridge';
import { useSelector } from 'react-redux';

import {
  getOrderPrice,
  isDraft,
  isFailed,
  isFulfilled,
  isQuotationWaiting,
  isOrderDetailFulfilled,
  getErrorType,
  getErrorMessage,
  getErrorFieldsCount,
  isPending,
  isIncotermValid,
  incotermsTransformer,
} from '../../../utils/helpers';
import { RootState } from '../../../stores';
import { PurchaseOrder } from '../../../dto';
import Link from '../../../components/Link';
import { isServiceValid } from '../utils';
import { Spacer } from './styled';
import { selectIsBypass } from '../../../models/auth';

interface Props {
  order: PurchaseOrder;
  isPaymentMethodSet: boolean;
}

const Shipment: React.FC<Props> = ({ order, isPaymentMethodSet }) => {
  const { t } = useTranslation();

  const carrierNameMap = useSelector((state: RootState) => state.services.carrierNameMap);
  const serviceNameMap = useSelector((state: RootState) => state.services.serviceNameMap);
  const isBypassUser = useSelector(selectIsBypass);

  const { trackingNumber, errors, logisticProvider, logisticServiceCode } = order || {};

  const logisticProviderName = logisticProvider
    ? carrierNameMap[logisticProvider]
    : logisticProvider;
  const logisticServiceName = logisticServiceCode
    ? serviceNameMap[logisticServiceCode]
    : logisticServiceCode;

  const handleCopy = async (e: React.MouseEvent<HTMLButtonElement>) => {
    try {
      const { trackingNumber } = e.currentTarget.dataset;
      if (trackingNumber) await navigator.clipboard.writeText(trackingNumber);
    } catch (err) {
      // handle error sliently
    }
  };

  if (isFulfilled(order)) {
    return (
      <Spacer>
        <span>
          <Text size="sm">
            {logisticProviderName} {logisticServiceName}
          </Text>
          <Text size="sm" className="subtext">
            <Link rel="noreferrer" target="_blank" to={`/tracking/${trackingNumber}`}>
              {trackingNumber}
            </Link>
          </Text>
        </span>
        {trackingNumber && (
          <Button
            size="xs"
            iconName="Copy"
            variant="link"
            onClick={handleCopy}
            data-tracking-number={trackingNumber}
          />
        )}
      </Spacer>
    );
  } else if (!isPaymentMethodSet && !isBypassUser && isPending(order)) {
    return (
      <Fragment>
        <Text size="sm">{t('OrdersList.paymentMethodError')}</Text>
        <Text size="sm" className="subtext">
          {t('OrdersList.paymentMethodSetupHint')}
        </Text>
      </Fragment>
    );
  } else if (isDraft(order)) {
    return (
      <Fragment>
        <Text size="sm">{t('OrdersList.textIncompleteInfo')}</Text>
        <Text size="sm" className="subtext">
          {t('OrdersList.missingFieldsCount', {
            count: getErrorFieldsCount(errors),
          })}
        </Text>
      </Fragment>
    );
  } else if (isOrderDetailFulfilled(order)) {
    return (
      <Fragment>
        <Text size="sm">{t('OrdersList.textIncompleteInfo')}</Text>
        <Text size="sm" className="subtext">
          {t('OrdersList.serviceSelectionRequired')}
        </Text>
      </Fragment>
    );
  } else if (isQuotationWaiting(order)) {
    return (
      <Fragment>
        <Text size="sm">
          {logisticProviderName} {logisticServiceName}
        </Text>
        <Skeleton height="1rem" width="4rem" />
      </Fragment>
    );
  } else if (!isIncotermValid(order)) {
    return (
      <Fragment>
        <Text size="sm">{t('OrdersList.taxHandlingError')}</Text>
        <Text size="sm" className="subtext">
          {t('RatesCard.changeTaxHandlingHint', {
            incoterm: incotermsTransformer([order?.incoterm]),
          })}
        </Text>
      </Fragment>
    );
  } else if (!isServiceValid(order)) {
    return (
      <Fragment>
        <Text size="sm">{t('OrdersList.textShipmentError')}</Text>
        <Text size="sm" className="subtext">
          {t('OrdersList.serviceUnavailableHint')}
        </Text>
      </Fragment>
    );
  } else if (isFailed(order)) {
    return (
      <Fragment>
        <Text size="sm">
          {getErrorType(errors) || `${logisticProviderName} ${logisticServiceName}`}
        </Text>
        <Text size="sm" className="subtext">
          {getErrorMessage(errors)}
        </Text>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Text size="sm">
        {logisticProviderName} {logisticServiceName}
      </Text>
      <Text size="sm" className="subtext">
        {getOrderPrice(order)}
      </Text>
    </Fragment>
  );
};

export default Shipment;
