import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import * as snippet from '@segment/snippet';
import { useLocation } from 'react-router-dom';
import Analytics from '../../analytics';

const ANALYTICS_WRITE_KEY = process.env.REACT_APP_ANALYTICS_WRITE_KEY || '';

const Segment: React.FC = () => {
  const location = useLocation();

  const renderSegmentSnippet = () => {
    const opts = {
      apiKey: ANALYTICS_WRITE_KEY,
      page: false,
    };

    if (process.env.NODE_ENV === 'development') {
      return snippet.max(opts);
    }

    return snippet.min(opts);
  };

  useEffect(() => {
    Analytics.page();
  }, [location]);

  return <Helmet script={[{ innerHTML: renderSegmentSnippet() }]} />;
};

export default Segment;
