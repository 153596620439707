import { createModel } from '@rematch/core';
import { getCarrierServices } from '../services';
import type { RootModel } from './index';
import { Service } from '../dto/Service.dto';

const isAvailableService = (service: Service) => service.enable && !service.noService;

interface ServicesState {
  byService: Record<string, Service>;
  carrierServicesIdMap: Record<string, string[]>;
  carrierNameMap: Record<string, string>;
  serviceNameMap: Record<string, string>;
}

const defaultState = {
  byService: {},
  carrierServicesIdMap: {},
  carrierNameMap: {},
  serviceNameMap: {},
};

export const services = createModel<RootModel>()({
  state: defaultState as ServicesState,
  reducers: {
    read: (state: ServicesState, payload: Service[]) => {
      return {
        byService: payload.reduce((acc, service) => {
          return {
            ...acc,
            [service.slug]: service,
          };
        }, {} as ServicesState['byService']),
        carrierServicesIdMap: payload
          .filter(isAvailableService)
          .reduce((acc, { slug, carrier }) => {
            return {
              ...acc,
              [carrier]: [...(acc[carrier] || []), slug],
            };
          }, {} as ServicesState['carrierServicesIdMap']),
        carrierNameMap: payload.reduce((acc, { carrierName, carrier }) => {
          return {
            ...acc,
            [carrier]: carrierName?.en || carrier,
          };
        }, {} as ServicesState['carrierNameMap']),
        serviceNameMap: payload.reduce((acc, { slug, name }) => {
          return {
            ...acc,
            [slug]: name?.en || slug,
          };
        }, {} as ServicesState['serviceNameMap']),
      };
    },
  },
  effects: (dispatch) => ({
    async getCarrierServices() {
      const list = await getCarrierServices();
      dispatch.services.read(list);
    },
  }),
});
