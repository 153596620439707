import { IsArray, IsEnum, IsOptional } from 'class-validator';
import { Expose, Type } from 'class-transformer';
import { PurchaseOrder, Package, Address } from '.';
import { LogisticServiceCode, PurposeType, Currency, Country, IncotermType } from '../common/enums';

export class BulkPurchaseOrdersEdit {
  constructor(props: Partial<BulkPurchaseOrdersEdit>) {
    Object.assign(this, props);
  }

  @Expose()
  @IsArray()
  purchaseOrders!: Pick<PurchaseOrder, 'id'>[];

  @Expose({ name: 'logisticServiceCode' })
  @IsOptional()
  @IsEnum(LogisticServiceCode)
  carrierService?: LogisticServiceCode;

  @Expose({ name: 'type' })
  @IsOptional()
  @IsEnum(PurposeType)
  shipmentPurpose?: PurposeType;

  @Expose()
  @IsOptional()
  @Type(() => Address)
  shipper?: Address;

  @Expose()
  @IsOptional()
  @IsEnum(IncotermType)
  incoterm?: IncotermType;

  @Expose()
  @IsOptional()
  @IsEnum(Currency)
  currency?: Currency;

  @Expose()
  @IsOptional()
  @Type(() => Package)
  package?: Package;

  @Expose()
  @IsOptional()
  @IsEnum(Country)
  countryOfManufacture?: Country;
}
