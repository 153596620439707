import { User } from '../contexts';
import { apiService } from './api';
import { Credential, Register } from '../dto';
import { ProfileUpdate } from '../dto/ProfileUpdate.dto';

interface AuthResponse {
  user: User;
  accessToken: string;
}

export interface ProfileUpdatePayload extends ProfileUpdate {
  name: string;
}

export async function login(params: Credential): Promise<AuthResponse> {
  try {
    const res = await apiService({
      method: 'post',
      path: '/auth/login',
      data: params,
      isPublic: true,
    });
    const { success, data, message } = res.data;
    if (!success) throw new Error(message);

    return data;
  } catch (err: any) {
    return Promise.reject(err?.response?.data);
  }
}

export async function register(data: Register): Promise<AuthResponse> {
  const { email, password, referrerCode } = data;
  try {
    const res = await apiService({
      method: 'post',
      path: '/auth/register',
      data: { userType: 'business', email, password, referrerCode },
      isPublic: true,
    });
    const { success, data, message } = res.data;
    if (!success) throw new Error(message);

    return { referrerCode, ...data };
  } catch (err: any) {
    return Promise.reject(err?.response?.data);
  }
}

export async function updateProfile(data: ProfileUpdatePayload): Promise<AuthResponse> {
  const { name, phone, phoneCountryCode, monthlyShipments } = data;

  try {
    const res = await apiService({
      method: 'post',
      path: '/auth/basic',
      data: {
        userType: 'business',
        name,
        phone,
        phoneCc: phoneCountryCode,
        minAvgShipments: monthlyShipments.min,
        maxAvgShipments: monthlyShipments.max,
        company: '',
        whatsapp: false,
        minAvgWeight: null,
        maxAvgWeight: null,
        categories: null,
      },
    });
    const { success, data, message } = res.data;
    if (!success) throw new Error(message);

    return data;
  } catch (err: any) {
    return Promise.reject(err?.response?.data);
  }
}
