import { PurchaseOrder, Quotation } from '../../dto';
import { IncotermType, PurchaseOrderQuotationStatus } from '../../common/enums';

export const isQuoteFetched = (order: PurchaseOrder): boolean =>
  order?.quotation?.status === PurchaseOrderQuotationStatus.FULFILLED ||
  order?.quotation?.status === PurchaseOrderQuotationStatus.FAILED;

export const isServiceValid = (order: PurchaseOrder): Quotation | undefined =>
  (order?.quotation?.snapshot || []).find(
    (quote) =>
      quote.slug === order.logisticServiceCode &&
      quote.enable &&
      !quote.noService &&
      !quote.readonly,
  );

export const getSelectedQuote = (
  snapshot: Quotation[] = [],
  logisticServiceCode = '',
  incoterm: IncotermType,
): Quotation | undefined =>
  (snapshot || []).find(
    (quote) => quote.slug === logisticServiceCode && quote.incoterm === incoterm,
  );
