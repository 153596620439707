import { Expose, Type } from 'class-transformer';
import { IsArray, IsNumber, IsOptional, ValidateNested } from 'class-validator';
import { ItemDataCreate } from './ItemDataCreate.dto';
import { ParserError } from '../common/errors';

export class PackageCreate {
  constructor(props: Partial<PackageCreate>) {
    Object.assign(this, props);
  }

  @Expose()
  @IsOptional()
  @IsNumber()
  @Type(() => Number)
  height?: number;

  @Expose()
  @IsOptional()
  @IsNumber()
  @Type(() => Number)
  length?: number;

  @Expose()
  @IsOptional()
  @IsNumber()
  @Type(() => Number)
  width?: number;

  @Expose()
  @IsOptional()
  @IsNumber()
  @Type(() => Number)
  unitWeight?: number;

  @Expose()
  @IsNumber()
  quantity = 1;

  @Expose()
  @IsArray()
  @Type(() => ItemDataCreate)
  @ValidateNested()
  items?: ItemDataCreate[] = [];

  errors?: ParserError[] = [];
}
