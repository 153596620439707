import React from 'react';
import { useTranslation } from 'react-i18next';
import { TableHeader, TH } from '../../../../components/Table';

const TransactionsTableHeader: React.FC = () => {
  const { t } = useTranslation();
  return (
    <TableHeader>
      <tr>
        <TH>{t('TransactionsList.columnTransactionDate')}</TH>
        <TH>{t('TransactionsList.columnOrder')}</TH>
        <TH>{t('TransactionsList.columnType')}</TH>
        <TH>{t('TransactionsList.columnCarrierService')}</TH>
        <TH>{t('TransactionsList.columnTrackingNumber')}</TH>
        <TH>{t('TransactionsList.columnAmount')}</TH>
        <TH></TH>
      </tr>
    </TableHeader>
  );
};

export default TransactionsTableHeader;
