import { createModel } from '@rematch/core';
import { Pagination } from '../types';
import type { RootModel } from './index';
import { Payment } from '../dto/Payment.dto';
import { Transaction } from '../dto/Transaction.dto';
import {
  getPurchaseOrdersPayments,
  getPurchaseOrdersPaymentSummary,
  getPurchaseOrdersTransactionsByPaymentId,
  GetTransactionsInputType,
} from '../services/transactions';
import { PaymentSummary } from '../dto/PaymentSummary.dto';
import { RootState } from '../stores';

export type TransactionsState = {
  payments: Payment[];
  transactions: Transaction[];
  paymentSummary: PaymentSummary;
};

export const transactions = createModel<RootModel>()({
  state: {
    payments: [],
    transactions: [],
    paymentSummary: {},
  } as TransactionsState,
  reducers: {
    paymentsUpdate: (state: TransactionsState, payload: Payment[]) => {
      return {
        ...state,
        payments: payload,
      };
    },
    transactionsUpdate: (state: TransactionsState, payload: Transaction[]) => {
      return {
        ...state,
        transactions: payload,
      };
    },
    paymentSummaryUpdate: (state: TransactionsState, payload: PaymentSummary) => {
      return {
        ...state,
        paymentSummary: payload,
      };
    },
  },
  effects: (dispatch) => ({
    async getPurchaseOrdersPayments(payload: GetTransactionsInputType): Promise<Pagination> {
      const { payments, links, meta } = await getPurchaseOrdersPayments(payload);
      dispatch.transactions.paymentsUpdate(payments);
      return { links, meta };
    },
    async getPurchaseOrdersPaymentSummary(payload: GetTransactionsInputType): Promise<void> {
      const PaymentsSummary = await getPurchaseOrdersPaymentSummary(payload);
      dispatch.transactions.paymentSummaryUpdate(PaymentsSummary);
    },
    async getPurchaseOrdersTransactionsByPaymentId(
      payload: GetTransactionsInputType,
    ): Promise<Pagination> {
      const { transactions, links, meta } = await getPurchaseOrdersTransactionsByPaymentId(payload);
      dispatch.transactions.transactionsUpdate(transactions);
      return { links, meta };
    },
  }),
});

export const selectAllPayments = (state: RootState): Payment[] => {
  return state.transactions.payments;
};

export const selectCurrentPaymentTransactions = (state: RootState): Transaction[] => {
  return state.transactions.transactions;
};
export const selectPaymentsSummary = (state: RootState): PaymentSummary => {
  return state.transactions.paymentSummary;
};
