import React from 'react';
import { useTranslation } from 'react-i18next';
import { Skeleton, Text, colors } from '@spaceshipapp/cambridge';

import { styled } from '@linaria/react';
import { useSelector } from 'react-redux';
import { selectPaymentsSummary } from '../../../models/transactions';
import { css, cx } from '@linaria/core';
import formatter from '../../../utils/formatter';
import { refundTextStyle, symbolStyle } from '../TransactionsList/TransactionRow/styled';

interface Props {
  isLoaded: boolean;
}

enum PaymentsSummaryType {
  totalPayment = 'totalPayment',
  totalRefund = 'totalRefund',
  netPayment = 'netPayment',
}

interface SummaryColumnProps {
  type: PaymentsSummaryType;
  isLoaded: boolean;
  paymentValue?: string;
}

const PaymentsSummary: React.FC<Props> = ({ isLoaded }) => {
  const paymentsSummary = useSelector(selectPaymentsSummary);
  const { chargeAmount, refundAmount } = paymentsSummary;
  const paymentValueMap: Record<PaymentsSummaryType, string> = {
    totalPayment: chargeAmount && chargeAmount?.value !== 0 ? formatter.price(chargeAmount) : '',
    totalRefund: refundAmount && refundAmount?.value !== 0 ? formatter.price(refundAmount) : '',
    netPayment:
      chargeAmount && refundAmount && chargeAmount?.value - refundAmount?.value > 0
        ? formatter.price({
            value: chargeAmount?.value - refundAmount?.value,
            currency: chargeAmount.currency,
          })
        : '',
  };

  return (
    <SummaryWrapper>
      {(Object.keys(PaymentsSummaryType) as (keyof typeof PaymentsSummaryType)[]).map((key) => (
        <SummaryColumn
          key={key}
          paymentValue={paymentValueMap?.[key]}
          isLoaded={isLoaded}
          type={PaymentsSummaryType[key]}
        />
      ))}
    </SummaryWrapper>
  );
};

const SummaryColumn: React.FC<SummaryColumnProps> = ({ type, isLoaded, paymentValue }) => {
  const { t } = useTranslation();

  return (
    <ItemWrapper>
      <Skeleton className={skeletonStyle} height="1.8125rem" width="auto" isLoaded={isLoaded} />
      {isLoaded && (
        <Text
          className={cx(
            symbolStyle,
            type === PaymentsSummaryType.totalRefund && paymentValue && refundTextStyle,
          )}
          data-valid={paymentValue ? 'valid' : 'invalid'}
          size="md"
        >
          {paymentValue}
        </Text>
      )}

      <Text size="sm">{t(`Transactions.paymentSummary.${type}`)}</Text>
    </ItemWrapper>
  );
};

const SummaryWrapper = styled.div`
  display: flex;
  gap: 2.45rem;
  align-items: center;
  text-align: center;
  > :not(:last-child) {
    border-right: 1px solid ${colors.border};
    padding-right: 2.45rem;
  }
`;

const ItemWrapper = styled.div`
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
`;

const skeletonStyle = css`
  margin-bottom: 0.5rem;
`;

export default PaymentsSummary;
