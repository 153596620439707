import isToday from 'date-fns/isToday';
import isTomorrow from 'date-fns/isTomorrow';
import parseISO from 'date-fns/parseISO';
import differenceInHours from 'date-fns/differenceInHours';

import { Pagination } from '../types';
import Formatter from './formatter';
import {
  BulkPickup,
  Money,
  Package,
  PurchaseOrder,
  PurchaseOrderError,
  PurchaseOrderQuotation,
  Quotation,
  Shipment,
  Timeslot,
} from '../dto';
import {
  PurchaseOrderStatus,
  PaginationType,
  Currency,
  PurchaseOrderQuotationStatus,
  OrderStatus,
  ShipmentStatus,
  PickupStatus,
  PickupType,
  TrackingTag,
  IncotermType,
} from '../common/enums';
import { formatISO, subDays } from 'date-fns';

const LOCAL_STORAGE_KEY_LANGUAGE = 'LANGUAGE';

export const getlocale = (lang?: string): 'zh' | 'en' => {
  if (!lang || /^en/.test(lang)) return 'en';
  else if (/^zh/.test(lang)) return 'zh';
  return 'en';
};

export const getStoredLocale = (): string | null => {
  return window.localStorage.getItem(LOCAL_STORAGE_KEY_LANGUAGE) ?? null;
};

export const setStoredLocale = (locale: string): void => {
  return window.localStorage.setItem(LOCAL_STORAGE_KEY_LANGUAGE, locale);
};

const VALID_QUOTE_HOURS = 24;

export const sumTotal = (moneyArr: Money[]): Money => {
  if (!moneyArr || !moneyArr.length) return { value: 0, currency: Currency.HKD };
  return moneyArr.reduce((acc, cur) => ({
    value: acc.value + cur.value,
    currency: cur.currency || Currency.HKD,
  }));
};

export const dateFormat = `d MMM yyyy`;

export const timeFormat = `hh:mm aa`;

export const datetimeFormat = `d MMM yyyy hh:mm aa`;

export const getPaginationUrl = (
  pagination?: Pagination,
  type?: PaginationType,
): string | undefined => {
  if (pagination) {
    const { links } = pagination;
    switch (type) {
      case PaginationType.FIRST:
        return links.first;
      case PaginationType.LAST:
        return links.last;
      case PaginationType.PREV:
        return links.prev;
      case PaginationType.NEXT:
        return links.next;
    }
  }
  return undefined;
};

// TODO: please remove "PENDING" if u trigger payment in backend
export const isReadyToSubmit = (order: PurchaseOrder): boolean =>
  [PurchaseOrderStatus.READY, PurchaseOrderStatus.FAILED, PurchaseOrderStatus.PENDING].includes(
    order.status,
  );

export const hasLabel = (order: PurchaseOrder): boolean => Boolean(order.labelUrl);

export const isPending = (order: PurchaseOrder): boolean =>
  order.status === PurchaseOrderStatus.PENDING;

export const isFulfilled = (order: PurchaseOrder): boolean =>
  order.status === PurchaseOrderStatus.FULFILLED;

export const isDraft = (order: PurchaseOrder): boolean =>
  order.status === PurchaseOrderStatus.DRAFT;

export const isOrderDetailFulfilled = (order: PurchaseOrder): boolean =>
  order.status === PurchaseOrderStatus.ORDER_DETAIL_FULFILLED;

export const isFailed = (order: PurchaseOrder): boolean =>
  order.status === PurchaseOrderStatus.FAILED;

export const isBulkCreateShipmentsProcessFinished = (orders: PurchaseOrder[]): boolean =>
  !orders.some(
    (order: PurchaseOrder) =>
      order.status !== PurchaseOrderStatus.FAILED && order.status !== PurchaseOrderStatus.FULFILLED,
  );

export const isIncotermValid = (order: PurchaseOrder): boolean =>
  !!order.logisticServiceIncoterms?.includes(order.incoterm) || !order.logisticServiceIncoterms;

export const isQuotationWaiting = (order: PurchaseOrder): boolean =>
  order?.quotation?.status === PurchaseOrderQuotationStatus.CREATED ||
  (order.status === PurchaseOrderStatus.ORDER_DETAIL_FULFILLED &&
    order?.quotation?.status === PurchaseOrderQuotationStatus.EXPIRED) ||
  (order.status === PurchaseOrderStatus.READY &&
    order?.quotation?.status === PurchaseOrderQuotationStatus.EXPIRED);

export const isQuotationRefreshRequired = (quotation?: PurchaseOrderQuotation): boolean =>
  !quotation ||
  quotation?.status === PurchaseOrderQuotationStatus.EXPIRED ||
  quotation?.status === PurchaseOrderQuotationStatus.FAILED ||
  (quotation?.status === PurchaseOrderQuotationStatus.FULFILLED &&
    differenceInHours(new Date(), parseISO(quotation.createdAt)) > VALID_QUOTE_HOURS);

export const isQuotationFetched = (quotation?: PurchaseOrderQuotation): boolean =>
  !!quotation &&
  (quotation?.status === PurchaseOrderQuotationStatus.FULFILLED ||
    quotation?.status === PurchaseOrderQuotationStatus.FAILED);

export const isQuotationValid = (quotation?: PurchaseOrderQuotation): boolean =>
  quotation?.status === PurchaseOrderQuotationStatus.FULFILLED &&
  differenceInHours(new Date(), parseISO(quotation.createdAt)) <= VALID_QUOTE_HOURS;

export const getOrderPrice = (order: PurchaseOrder): string => {
  const { finalTotal, logisticServiceCode, quotation, incoterm } = order;
  const expectedPrice = (quotation?.snapshot ?? []).find(
    (quote) => quote.service === logisticServiceCode && quote.incoterm === incoterm,
  )?.totalRate;

  if (finalTotal || expectedPrice) {
    return Formatter.price(
      finalTotal || {
        value: expectedPrice as number,
        currency: Currency.HKD,
      },
    );
  }
  return '';
};

export const getErrorMessage = (errors?: PurchaseOrderError): string | null => {
  const { createShipmentError, paidError, suspenseServiceError } = errors || {};

  if (createShipmentError) return createShipmentError;

  if (paidError) return paidError;

  if (suspenseServiceError)
    return `The service you selected might be unavailable for this receiver address.`;

  return null;
};

export const getSuspenseServices = (orders: PurchaseOrder[]): PurchaseOrder[] => {
  return orders.filter((order) => {
    const { logisticServiceCode, errors } = order;
    const { suspenseServiceError } = errors || {};
    return Boolean(suspenseServiceError) && logisticServiceCode;
  });
};

export const getErrorType = (errors?: PurchaseOrderError): string | null => {
  const { createShipmentError, paidError, suspenseServiceError } = errors || {};

  if (createShipmentError || suspenseServiceError) {
    return 'Shipment Error';
  } else if (paidError) {
    return 'Payment Error';
  }

  return null;
};

export const getErrorFieldsCount = (errors?: PurchaseOrderError): number => {
  if (!errors) return 0;

  let count = 0;
  count += errors?.missFields?.length || 0;
  count += Object.keys(errors?.invalidFields || {}).length || 0;
  return count;
};

export const isSubset = (parent?: Record<string, any>, child?: Record<string, any>): boolean => {
  if (!parent || !child) return false;
  return Object.keys(child).every((key) => {
    const pv = parent[key];
    const cv = child[key];

    switch (typeof cv) {
      case 'object':
        return JSON.stringify(pv) === JSON.stringify(cv);
      default:
        return pv === cv;
    }
  });
};

export const omitInvalid = (obj?: Record<string, any>): Record<string, any> | undefined => {
  if (!obj) return undefined;
  return Object.entries(obj).reduce(
    (a: Record<string, any>, [k, v]) => (v == null ? a : ((a[k] = v), a)),
    {},
  );
};

export const findBy = (
  collection: any[],
  conditions: Record<string, any> | Record<string, any>[],
): any => {
  return collection.find((item) => {
    if (Array.isArray(conditions)) {
      return conditions.reduce(
        (a: boolean, b: Record<string, any>) => a || isSubset(item, b),
        false,
      );
    }

    return isSubset(item, conditions);
  });
};

export function getMoney(input: Record<string, any>): Money {
  const { amount, currency, minor } = input;
  const value = amount / 10 ** minor;
  return new Money({
    value,
    currency: Currency[currency as keyof typeof Currency],
  });
}

export const parseDateStringAndTimeString = (dateString: string, timeString: string): Date => {
  const [year, month, day] = dateString.split('-');
  const [h, m] = timeString.split(':');
  return new Date(parseInt(year), parseInt(month) - 1, parseInt(day), parseInt(h), parseInt(m));
};

export const parseTimeString = (timeString: string): Date => {
  const [h, m] = timeString.split(':');
  return new Date(0, 0, 0, parseInt(h), parseInt(m));
};

export const getAvailableTimeSlot = (
  serviceProvider: string,
  timeslots: BulkPickup['timeslots'],
): BulkPickup['timeslots'] => {
  if (['zeek', 'morning'].includes(serviceProvider)) {
    //Only allow at least 3 hour(s) between your shipment ready time and pickup time.
    return timeslots.filter(
      (timeslot: Timeslot) =>
        differenceInHours(parseDateStringAndTimeString(timeslot.date, timeslot.from), new Date()) >=
        3,
    );
  }

  // the logic is moved to backend for SF
  return timeslots;
};

export const getDatePickerDefaultDates = (): { currDate: string; prevDate: string } => {
  return {
    currDate: formatISO(new Date(), { representation: 'date' }),
    prevDate: formatISO(subDays(new Date(), 6), { representation: 'date' }),
  };
};

export const renderRelativeDate = (date: string): string => {
  if (isToday(parseISO(date))) return 'Today';
  if (isTomorrow(parseISO(date))) return 'Tomorrow';
  return Formatter.date(date, dateFormat);
};

export const isAvailableService = (quote: Quotation): boolean =>
  quote.enable && !quote.noService && !quote.readonly;

export const duplicatedServicesFilter = (quote: Quotation, order: PurchaseOrder): boolean =>
  quote.incoterms.length < 3 || quote.incoterm === order.incoterm;

export const isPackageCreated = (pkgs?: Package[]): boolean => {
  return (
    Boolean(pkgs) &&
    (pkgs ?? []).length > 0 &&
    (Boolean(pkgs?.[0]?.height) ||
      Boolean(pkgs?.[0]?.width) ||
      Boolean(pkgs?.[0]?.length) ||
      Boolean(pkgs?.[0]?.unitWeight))
  );
};

export const isPackageDimensionFilled = (pkgs?: Package[]): boolean => {
  return (
    Boolean(pkgs) &&
    (pkgs ?? []).length > 0 &&
    !!pkgs?.[0]?.height &&
    !!pkgs?.[0]?.width &&
    !!pkgs?.[0]?.length
  );
};

export const getShipmentStatus = (shipment: Shipment): ShipmentStatus => {
  const { status, pickup, tracking } = shipment;
  const { type: pickupType, status: pickupStatus } = pickup || {};
  const tag = tracking?.tag;

  switch (status) {
    case OrderStatus.VOID:
    case OrderStatus.RETURN:
    case OrderStatus.CANCEL:
      return ShipmentStatus.CANCELLED;
    case OrderStatus.HOLD:
      return ShipmentStatus.ACTIONS_REQUIRED;
  }

  switch (tag) {
    case TrackingTag.FIRST_MILE_DELIVERD:
    case TrackingTag.TAG_PPS_PARCEL_SCANNED:
      return ShipmentStatus.PROCESSING;
    case TrackingTag.IN_TRANSIT:
    case TrackingTag.OUT_FOR_DELIVERY:
    case TrackingTag.ATTEMPT_FAIL:
    case TrackingTag.TAG_PENDING:
      return ShipmentStatus.ON_THE_WAY;
    case TrackingTag.EXCEPTION:
    case TrackingTag.EXPIRED:
      return ShipmentStatus.ON_HOLD;
    case TrackingTag.DELIVERED:
      return ShipmentStatus.DELIVERED;
    case TrackingTag.INFO_RECEIVED:
    case TrackingTag.AVAILABLE_FOR_PICKUP:
    case TrackingTag.FIRST_MILE_INFO_REVEIVED:
    default:
      if (
        !pickup ||
        pickupType === PickupType.DROPOFF ||
        pickupStatus !== PickupStatus.STATUS_SUCCESS
      ) {
        return ShipmentStatus.LABEL_CREATED;
      }
      return ShipmentStatus.TO_BE_COLLECTED;
  }
};

export const getShipmentStatusTranslationKey = (shipment: Shipment): string => {
  const status = getShipmentStatus(shipment);

  const translations: Record<string, any> = {
    [ShipmentStatus.LABEL_CREATED]: 'ShipmentInfo.statusLabelCreated',
    [ShipmentStatus.TO_BE_COLLECTED]: 'ShipmentInfo.statusToBeCollected',
    [ShipmentStatus.PROCESSING]: 'ShipmentInfo.statusProcessing',
    [ShipmentStatus.ON_THE_WAY]: 'ShipmentInfo.statusOnTheWay',
    [ShipmentStatus.ACTIONS_REQUIRED]: 'ShipmentInfo.statusActionRequired',
    [ShipmentStatus.ON_HOLD]: 'ShipmentInfo.statusOnHold',
    [ShipmentStatus.CANCELLED]: 'ShipmentInfo.statusCancelled',
    [ShipmentStatus.DELIVERED]: 'ShipmentInfo.statusDelivered',
  };

  return translations[status];
};

export const incotermsTransformer = (incoterms: IncotermType[]): string[] =>
  incoterms.map((incoterm) => (incoterm === IncotermType.DDU ? 'DAP' : incoterm));
