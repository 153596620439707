import React from 'react';
import { Button, Icon, Text } from '@spaceshipapp/cambridge';

import { useTranslation } from 'react-i18next';
import {
  backButtonStyle,
  modalStyle,
  StyledResultStack,
  successIconStyle,
  TitleWrapper,
} from './styled';
import Overlay from '../../../components/Overlay';
import { useDispatch } from 'react-redux';

export enum ResultType {
  PAYMENT_SUCCESS = 'paymentSuccess',
  SHIPMENT_CANCEL = 'shipmentCanceled',
}

interface AdjustmentResultProps {
  result: ResultType;
}

const AdjustmentResult: React.FC<AdjustmentResultProps> = ({ result }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { removeAdjustmentSuccessType } = dispatch.shipments;

  const onClose = () => {
    removeAdjustmentSuccessType();
  };

  const renderTitle = () => (
    <TitleWrapper>
      <Text className="title">
        <Icon.CheckCircle width={25} height={25} className={successIconStyle} />
        <span>{t(`AdjustmentModel.${result}`)}</span>
      </Text>
      <Icon.Delete onClick={onClose} className="close" />
    </TitleWrapper>
  );
  return (
    <Overlay
      visible
      className={modalStyle}
      title={renderTitle()}
      onClose={undefined}
      scrollable={false}
    >
      <StyledResultStack gap="md">
        <Text>{t(`AdjustmentModel.${result}Description`)}</Text>
        <Button className={backButtonStyle} size="sm" onClick={onClose} variant="primary">
          {t('AdjustmentModel.buttonBackToShipment')}
        </Button>
      </StyledResultStack>
    </Overlay>
  );
};

export default AdjustmentResult;
