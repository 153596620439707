import { IsArray, IsEnum, IsOptional, IsString, ValidateNested } from 'class-validator';
import { Expose, Type } from 'class-transformer';
import { AddressCreate, PackageCreate } from '.';
import { Currency, PurposeType, LogisticServiceCode, IncotermType } from '../common/enums';

export class PurchaseOrderUpdate {
  constructor(props: Partial<PurchaseOrderUpdate>) {
    Object.assign(this, props);
  }

  @Expose()
  @IsString()
  id!: string;

  @Expose({ name: 'orderId' })
  @IsString()
  orderNumber!: string;

  @Expose({ name: 'type' })
  @IsEnum(PurposeType)
  shipmentPurpose!: PurposeType;

  @Expose({ name: 'logisticServiceCode' })
  @IsOptional()
  @IsEnum(LogisticServiceCode)
  carrierService?: LogisticServiceCode;

  @Expose()
  @IsEnum(Currency)
  currency!: Currency;

  @Expose()
  @IsOptional()
  @IsString()
  taxId?: string;

  @Expose()
  @IsArray()
  @IsOptional()
  @Type(() => PackageCreate)
  @ValidateNested()
  packages?: PackageCreate[] = [];

  @Expose({ name: 'receiverAddress' })
  @IsOptional()
  @Type(() => AddressCreate)
  @ValidateNested()
  receiver?: AddressCreate;

  @Expose({ name: 'shipperAddress' })
  @Type(() => AddressCreate)
  @ValidateNested()
  shipper!: AddressCreate;

  @Expose()
  @IsEnum(IncotermType)
  incoterm!: IncotermType;
}
