import { Expose, Type } from 'class-transformer';
import { IsNumber, IsNotEmpty, Min, IsInt } from 'class-validator';

export class PackageForm {
  constructor(props: Partial<PackageForm>) {
    Object.assign(this, props);
  }

  @Expose()
  @Type(() => Number)
  @IsInt()
  @Min(1)
  @IsNotEmpty()
  height!: number;

  @Expose()
  @Type(() => Number)
  @IsInt()
  @Min(1)
  @IsNotEmpty()
  length!: number;

  @Expose()
  @Type(() => Number)
  @IsInt()
  @Min(1)
  @IsNotEmpty()
  width!: number;

  @Expose()
  @Type(() => Number)
  @IsNumber()
  @Min(0.1)
  @IsNotEmpty()
  unitWeight!: number;
}
