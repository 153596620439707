import React from 'react';
import { styled } from '@linaria/react';
import { Button, colors, Popover, zIndex } from '@spaceshipapp/cambridge';
import logo from '../../assets/images/logo.svg';
import { Link } from '../../components/Link';
import { Languages } from './PublicNavbar';

const TrackingNavbar: React.FC = () => {
  return (
    <Header>
      <Nav className="container">
        <Logo>
          <Link to="/login">
            <img src={logo} alt="Spaceship Pro" height={33} width={222} />
          </Link>
        </Logo>
        <BtnGroup>
          <Popover
            trigger={
              <Button
                iconName="Globe"
                style={{ color: `${colors.surface}` }}
                size="sm"
                variant="link"
              />
            }
          >
            <Languages />
          </Popover>
        </BtnGroup>
      </Nav>
    </Header>
  );
};

const Header = styled.header`
  padding: 1.5em 0;
  background-color: ${colors.text};
  border-bottom: 1px solid ${colors.text};
  position: sticky;
  z-index: ${zIndex.HEADER};
  top: 0;
`;
const Nav = styled.nav`
  display: grid;
  grid-template-columns: 1fr 1fr;

  a {
    text-decoration: none;

    &.active {
      font-weight: 600;
    }
  }
`;

const Logo = styled.div`
  display: flex;
  align-items: center;

  > *:not(:first-child) {
    margin-left: 0.5rem;
  }
`;
const BtnGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > *:not(:first-child) {
    margin-left: 0.5rem;
  }

  /* fix to allow menu align on the right */
  div[role='dialog'] {
    min-width: 20rem;
  }
`;

export default TrackingNavbar;
