import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { styled } from '@linaria/react';
import { css, cx } from '@linaria/core';
import { zIndex, Dialog } from '@spaceshipapp/cambridge';

interface Props {
  visible: boolean;
  title?: string | React.ReactElement;
  onClose?(): void;
  className?: string;
  children: React.ReactNode;
  scrollable?: boolean;
}

const Overlay: React.FC<Props> = ({
  visible,
  title = '',
  className,
  scrollable = true,
  ...rest
}) => {
  const modalEl = document.getElementById('modal');

  useEffect(() => {
    if (!scrollable) {
      document.body.style.overflowY = 'hidden';

      return () => {
        document.body.style.overflowY = 'auto';
      };
    }
  }, [scrollable]);

  if (!visible || !modalEl) return null;

  return ReactDOM.createPortal(
    <Wrapper>
      <Dialog title={title} className={cx(style, className)} {...rest} />
    </Wrapper>,
    modalEl,
  );
};

const Wrapper = styled.div`
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  position: fixed;
  z-index: ${zIndex.MODAL};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(34, 34, 34, 0.3);
  animation: fadeIn 300ms;
  animation-fill-mode: forwards;
`;

const style = css`
  top: 50%;
  left: 50%;
  bottom: auto;
  transform: translate(-50%, -50%);
  position: fixed;
  z-index: ${zIndex.MODAL + 1};
`;

export default Overlay;
