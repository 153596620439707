import { IsArray, IsOptional, IsString, IsEnum, ValidateNested } from 'class-validator';
import { Expose, Type } from 'class-transformer';

import { Quotation } from '.';
import { PurchaseOrderQuotationStatus } from '../common/enums';

export class PurchaseOrderQuotation {
  constructor(props: Partial<PurchaseOrderQuotation>) {
    Object.assign(this, props);
  }

  @Expose()
  @IsString()
  id!: string;

  @Expose()
  @IsEnum(PurchaseOrderQuotationStatus)
  status!: PurchaseOrderQuotationStatus;

  @Expose()
  @IsString()
  @IsOptional()
  error?: string;

  @Expose()
  @IsArray()
  @IsOptional()
  @ValidateNested()
  @Type(() => Quotation)
  snapshot?: Quotation[] = [];

  @Expose()
  createdAt: string;
}
