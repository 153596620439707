import axios from 'axios';
import { downloadZip, InputWithMeta } from 'client-zip';
import { PDFDocument } from 'pdf-lib';
import { chunk } from 'lodash';

const BATCH_DOWNLOAD_URLS_CHUNK_SIZE = 10;

export interface FileMeta {
  url: string;
  filename?: string;
  lastModified?: Date;
}

const download = async (url: string) => {
  const response = await axios.get(url, { responseType: 'blob' });
  return response.data;
};

const getFilename = (url: string) => {
  const filename = url.split('/').pop() || `unknown.pdf`;
  return filename.includes('.pdf') ? filename : `${filename}.pdf`;
};

export const createInputWithMeta = (file: FileMeta): Promise<any> =>
  new Promise<InputWithMeta>((resolve, reject) => {
    const { url, filename, lastModified } = file;

    download(url)
      .then((data) =>
        resolve({
          name: filename || getFilename(url),
          lastModified: lastModified || new Date(),
          input: data,
        }),
      )
      .catch((err) => reject(err));
  });

export const createZipFile = async (
  files: (File | InputWithMeta)[],
  zipName: string,
): Promise<void> => {
  const zippedBlob = await downloadZip(files).blob();

  const link = document.createElement('a');
  link.href = URL.createObjectURL(zippedBlob);
  link.download = zipName;
  link.click();
  link.remove();

  URL.revokeObjectURL(link.href);
};

export const convertDataUriToBlob = async (dataUri: string): Promise<File> => {
  const { data } = await axios.get(dataUri, { responseType: 'blob' });

  return data as File;
};

export const createMergedPdfsDataUri = async (urls: string[]): Promise<string> => {
  const pdfDoc = await PDFDocument.create();
  const urlChunks = chunk(urls, BATCH_DOWNLOAD_URLS_CHUNK_SIZE);

  for (const urlChunk of urlChunks) {
    const pdfs = await Promise.all(
      urlChunk.map((url) => {
        return axios.get(url, {
          responseType: 'arraybuffer',
        });
      }),
    );

    for (let i = 0; i < pdfs.length; i++) {
      const url = urlChunk[i];
      const pdfArrayBuffer = pdfs[i].data;

      let loadedPdf = await PDFDocument.load(pdfArrayBuffer, {
        ignoreEncryption: true,
      });

      if (loadedPdf.isEncrypted) {
        const a = await axios.post('/api/decrypt-pdf', { url }, { responseType: 'arraybuffer' });

        loadedPdf = await PDFDocument.load(a.data, {
          ignoreEncryption: true,
        });
      }
      const pdfPageCount = loadedPdf.getPageCount();
      for (let k = 0; k < pdfPageCount; k++) {
        const [page] = await pdfDoc.copyPages(loadedPdf, [k]);
        pdfDoc.addPage(page);
      }
    }
  }

  const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });

  return pdfDataUri;
};

export const mergePdfFilesByUrls = async (urls: string[], fileName: string): Promise<void> => {
  const fileUri = await createMergedPdfsDataUri(urls);

  const link = document.createElement('a');
  link.href = fileUri;
  link.download = fileName;
  link.click();
  link.remove();
  URL.revokeObjectURL(link.href);
};
