import React, { Fragment, useEffect } from 'react';
import { css } from '@linaria/core';
import { styled } from '@linaria/react';
import Navbar, { NAVBAR_WIDTH } from '../../components/Navbar/NavbarDesktop';
import PublicNavbar from '../../components/PublicNavbar';
import TrackingNavbar from '../../components/PublicNavbar/TrackingNavbar';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useLocaleHistory from '../../hooks/useLocaleHistory';
import { getStoredLocale, setStoredLocale } from '../../utils/helpers';
import SwitchToNewVersionBanner from './SwitchToNewVersionBanner';

interface LayoutProps {
  isPublic?: boolean;
  isTracking?: boolean;
}

const Layout: React.FC<LayoutProps> = ({ isPublic, isTracking, children }) => {
  const location = useLocation();
  const { i18n } = useTranslation();
  const history = useLocaleHistory();
  const currentPathname = location.pathname.toLowerCase();
  const currentLang = i18n.resolvedLanguage;
  const defaultLang = i18n.languages[i18n.languages.length - 1];

  const handleLocaleChange = (locale: string) => {
    if (locale !== currentLang) {
      const paths = currentPathname
        .replace(`/${currentLang.toLowerCase()}`, '')
        .split('/')
        .slice(1);
      const route =
        locale === defaultLang
          ? `/${paths.join('/')}`
          : `/${[locale.toLowerCase(), ...paths].join('/')}`;
      history.replace(route);
      i18n.changeLanguage(locale);
    }
    setStoredLocale(locale);
  };

  useEffect(() => {
    const storedLocale = getStoredLocale();
    if (storedLocale) {
      handleLocaleChange(storedLocale);
    } else {
      setStoredLocale(currentLang);
    }
  }, []);

  useEffect(() => {
    document.body.classList.toggle('public', !!isPublic);
  }, [isPublic]);

  if (isPublic)
    return (
      <Fragment>
        <PublicNavbar />
        <Main className="container">{children}</Main>
      </Fragment>
    );

  if (isTracking)
    return (
      <Fragment>
        <TrackingNavbar />
        <Main className="container">{children}</Main>
      </Fragment>
    );

  return (
    <Fragment>
      <SwitchToNewVersionBanner />
      <StyledNavbar isAuthenticated={!isPublic} />
      <Main className={authStyle}>{children}</Main>
    </Fragment>
  );
};

const Main = styled.main`
  display: grid;
`;

const authStyle = css`
  padding-left: ${NAVBAR_WIDTH};
`;

const StyledNavbar = styled(Navbar)`
  top: 56px;
`;

export default Layout;
