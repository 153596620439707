import decode, { JwtPayload } from 'jwt-decode';
import { createModel } from '@rematch/core';
import { Credential, Register } from '../dto';
import type { RootModel } from './index';
import {
  login,
  register,
  updateProfile,
  getInfo,
  updateIsMergeLabels,
  ProfileUpdatePayload,
} from '../services';
import { User } from '../contexts';
import { RootState } from '../stores';

const LOCAL_STORAGE_KEY = 'accessToken';

export interface AuthState {
  accessToken: string | null;
  isAuthenticated: boolean;
  user?: User;
  isNewUser?: boolean;
}

const defaultState = {
  accessToken: null,
  isAuthenticated: false,
  user: {} as User,
  isNewUser: false,
};

interface LoginPayload {
  accessToken: string;
  user?: User;
}

interface RegisterPayload {
  accessToken: string;
  user?: User;
  isNewUser?: boolean;
}

interface TokenPayload extends JwtPayload {
  user: User;
}

export const auth = createModel<RootModel>()({
  state: {
    ...defaultState,
    isAuthenticated: !!window.localStorage.getItem(LOCAL_STORAGE_KEY),
  } as AuthState,
  reducers: {
    loginSuccess: (state: AuthState, { accessToken, user }: LoginPayload) => ({
      accessToken,
      isAuthenticated: true,
      user: user ?? state.user,
    }),
    registerSuccess: (state: AuthState, { accessToken, user }: RegisterPayload) => ({
      accessToken,
      isAuthenticated: true,
      user: user ?? state.user,
      isNewUser: true,
    }),
    logoutSuccess: () => defaultState,
    updateUser: (state: AuthState, user: User) => {
      return {
        ...state,
        user,
      };
    },
    updateUserProfile: (state: AuthState, isNewUser: boolean) => {
      return {
        ...state,
        isNewUser,
      };
    },
  },
  effects: (dispatch) => ({
    async login(payload: Credential) {
      const { accessToken, user } = await login(payload);
      window.localStorage.setItem(LOCAL_STORAGE_KEY, accessToken);
      dispatch.auth.loginSuccess({ accessToken, user });
      return user;
    },
    async logout() {
      window.localStorage.removeItem(LOCAL_STORAGE_KEY);
      dispatch.auth.logoutSuccess();
    },
    async register(payload: Register) {
      const reg = await register(payload);
      const { name } = payload;
      window.localStorage.setItem(LOCAL_STORAGE_KEY, reg.accessToken);
      dispatch.auth.registerSuccess({
        accessToken: reg.accessToken,
        user: { ...reg.user, name },
      });
      return reg.user;
    },
    async updateProfile(payload: ProfileUpdatePayload) {
      const profile = await updateProfile(payload);
      dispatch.auth.updateUser(profile.user as User);
    },
    updateUserStatus(payload: boolean) {
      dispatch.auth.updateUserProfile(payload);
    },
    async resumeSession() {
      const accessToken = window.localStorage.getItem(LOCAL_STORAGE_KEY);
      const currentTime = +new Date() / 1000;

      if (accessToken) {
        try {
          const { exp }: TokenPayload = decode(accessToken);
          if (exp && currentTime >= exp) {
            throw new Error('[Auth] Token expired.');
          }

          dispatch.auth.loginSuccess({ accessToken });
        } catch (err) {
          dispatch.auth.logout();
        }
      }
    },
    async getUserInfo() {
      const user = await getInfo();
      dispatch.auth.updateUser(user as User);
      return user;
    },
    async updateLabelSettings(enable: string) {
      const user = await updateIsMergeLabels(enable);
      dispatch.auth.updateUser(user as User);
    },
  }),
});

export const selectIsMergeLabels = (state: RootState): boolean | undefined => {
  const { user } = state.auth;
  return user?.isMergeLabels;
};

export const selectIsBypass = (state: RootState): boolean | undefined => {
  const { user } = state.auth;
  return user?.bypass;
};

export const selectIsIOSSUser = (state: RootState): boolean | undefined => {
  const { user } = state.auth;
  return user?.isIossUser;
};
export const selectIsNewUser = (state: RootState): boolean | undefined => {
  const { isNewUser } = state.auth;
  return isNewUser;
};

export const selectUser = (state: RootState): User | undefined => {
  const { user } = state.auth;
  return user;
};

export const selectIsThermalLabel = (state: RootState): boolean | undefined => {
  const { user } = state.auth;
  return user?.thermalLabel;
};
