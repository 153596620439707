import { IsString, IsBoolean, IsEnum } from 'class-validator';
import { Expose, Type } from 'class-transformer';

import { LogisticServiceCode } from '../common/enums/logistic-service-code.enum';
import { LogisticProviderCode } from '../common/enums/logistic-provider.enum';
import { Translation } from './Translation.dto';

export class Service {
  constructor(props: Partial<Service>) {
    Object.assign(this, props);
  }

  @Expose()
  @Type(() => Translation)
  name!: Translation;

  @Expose()
  @IsString()
  @IsEnum(LogisticProviderCode)
  carrier!: string;

  @Expose()
  @Type(() => Translation)
  carrierName!: Translation;

  @Expose()
  @IsEnum(LogisticServiceCode)
  slug!: string;

  @Expose()
  @Type(() => Translation)
  description!: Translation;

  @Expose()
  @IsBoolean()
  readonly!: boolean;

  @Expose()
  @IsBoolean()
  enable!: boolean;

  @Expose()
  @IsBoolean()
  noService!: boolean;
}
