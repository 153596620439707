import { Expose } from 'class-transformer';
import { IsEnum, IsNotEmpty, IsNumber, IsOptional, IsString, MaxLength } from 'class-validator';
import { Country, Currency, PurposeType } from '../common/enums';
import { ParserError } from '../common/errors';

export class ShipmentItem {
  constructor(props: Partial<ShipmentItem>) {
    Object.assign(this, props);
  }

  @Expose()
  @IsString()
  id!: string;

  @IsOptional()
  @IsString()
  name?: string;

  @Expose()
  @IsNotEmpty()
  @MaxLength(255)
  detail!: string;

  @IsOptional()
  category?: string;

  @IsOptional()
  mainCategory?: string;

  @Expose()
  @IsOptional()
  @IsString()
  @MaxLength(2)
  countryOfManufacture?: Country;

  @IsOptional()
  @IsString()
  customerReference?: string;

  @Expose()
  @IsOptional()
  hscode?: string;

  @Expose({ name: 'sku' })
  @IsOptional()
  sku?: string;

  @Expose({ name: 'type' })
  @IsEnum(PurposeType)
  @IsOptional()
  shipmentPurpose?: PurposeType;

  @Expose()
  @IsNumber()
  quantity!: number;

  @IsOptional()
  @MaxLength(255)
  remarks?: string;

  @IsEnum(Currency)
  currency!: Currency;

  @Expose()
  @IsNumber()
  unitValue!: number; // value of a single item

  errors?: ParserError[] = [];
}
