import React, { useRef } from 'react';
import { styled } from '@linaria/react';
import { FilterType, OnSearchType } from '../../containers/SearchFilters/index';
import {
  Button,
  FormControl,
  FormLabel,
  Stack,
  Textarea,
  usePopoverOpen,
} from '@spaceshipapp/cambridge';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import searchTextAreaSchema, { searchTextAreaForm } from '../../schemas/searchTextArea';
import { Controller, useForm } from 'react-hook-form';
import { css } from '@linaria/core';
import { parseKeywords } from '../../containers/SearchFilters/utils';

interface SearchTextAreaProps {
  filterType: FilterType;
  multiSearch: string;
  onSearch: (e: OnSearchType) => void;
}

const SearchTextArea: React.FC<SearchTextAreaProps> = ({ onSearch, multiSearch }) => {
  const { t } = useTranslation();
  const togglePopover = usePopoverOpen();

  const stackRef = useRef<HTMLDivElement | null>(null);
  const searchTextAreaForm = useForm({
    resolver: yupResolver(searchTextAreaSchema),
  });
  const { formState, handleSubmit, control } = searchTextAreaForm;
  const { errors, isSubmitting } = formState;
  const textAreaDefaultValue = parseKeywords(multiSearch).join('\n');

  const handleSearch = (formData: searchTextAreaForm) => {
    onSearch(formData.transactionIds);
    togglePopover(false);
  };

  const onClose = () => {
    togglePopover(false);
  };

  return (
    <StyledStack ref={stackRef} as="form" onSubmit={handleSubmit(handleSearch)}>
      <FormControl errorMessage={t(errors?.transactionIds?.message, '')}>
        <FormLabel>{t('SearchFilter.searchMultipleModal.formLabelTransactionIds')}</FormLabel>
        <Controller
          name="transactionIds"
          control={control}
          render={({ field }) => {
            const { value, name, ...input } = field;
            return (
              <StyledTextArea
                className={formLabelStyle}
                id={name}
                placeholder={t('SearchFilter.searchMultipleModal.placeholder')}
                value={value}
                isError={!!errors?.transactionIds?.message}
                disabled={isSubmitting}
                defaultValue={textAreaDefaultValue}
                {...input}
              />
            );
          }}
        />
      </FormControl>
      <Row>
        <Button size="sm" type="button" disabled={formState.isSubmitting} onClick={onClose} block>
          {t('SearchFilter.searchMultipleModal.buttonCancel')}
        </Button>
        <Button
          size="sm"
          type="submit"
          variant="primary"
          disabled={!formState.isDirty}
          loading={formState.isSubmitting}
          block
        >
          {t('SearchFilter.searchMultipleModal.buttonSave')}
        </Button>
      </Row>
    </StyledStack>
  );
};

const StyledStack = styled(Stack)`
  margin: 0.75rem 0.375rem;
`;

const StyledTextArea = styled(Textarea)`
  height: 15rem;
  width: 47rem;
  outline: none;
`;

const Row = styled.div`
  display: grid;
  gap: 1rem;
  align-content: center;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
`;

const formLabelStyle = css`
  margin-top: 0.75rem;
`;

export default SearchTextArea;
