import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const convertLocaleKey = (locale: string) => {
  if (locale.includes('-')) {
    const [lang, region] = locale.split('-');
    return `${lang.toLowerCase()}-${region.toUpperCase()}`;
  }
  return locale;
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: ['common', 'countries'],
    defaultNS: 'common',
    fallbackLng: 'en',
    backend: {
      loadPath: (lng, ns) => `/locales/${convertLocaleKey(lng[0])}/${ns}.json`,
    },
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['path'],
      lookupFromPathIndex: 0,
      caches: [],
    },
  });
