import { Expose, Type } from 'class-transformer';
import { IsEnum, IsNumber, IsString, ValidateNested } from 'class-validator';
import { PickupStatus } from '../common/enums';
import { Address } from './Address.dto';
import { Shipment } from './Shipment.dto';

export class Pickup {
  constructor(props: Partial<Pickup>) {
    Object.assign(this, props);
  }

  @Expose({ name: 'ref' })
  @IsString()
  id!: string;

  @IsString()
  serviceProvider: string;

  @Expose({ name: 'pickupNumber' })
  @IsString()
  trackingNumber!: string;

  @Expose()
  @IsNumber()
  @IsEnum(PickupStatus)
  status?: PickupStatus;

  @Expose()
  @IsString()
  type!: string;

  @Expose()
  maxDatetime!: string;

  @Expose()
  minDatetime!: string;

  @Expose()
  @Type(() => Address)
  address!: Address;

  @Expose({ name: 'shipments' })
  @Type(() => Shipment)
  @ValidateNested()
  shipments: Shipment[] = [];

  @Expose()
  withOuterCollectionBags: boolean | null;

  @Expose()
  numberOfBags: number | null;
}
