import { Country } from '../enums/country.enum';

export interface CountryListItem {
  name: string;
  continent: string;
  code: string;
  'require-postal-code': boolean;
  'postal-code-sample': string;
}

export const COUNTRIES = [
  {
    name: {
      zh: '安道爾',
      en: 'Andorra',
    },
    continent: 'Europe',
    'alpha-2': 'AD',
    'alpha-3': 'AND',
    code: '20',
    'require-postal-code': false,
    'postal-code-sample': 'AD500',
    'zone-code': 'CET',
    'time-zone-offset': '+01:00',
    'zone-name': 'Europe/Andorra',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '阿拉伯聯合酋長國',
      en: 'United Arab Emirates',
    },
    continent: 'Asia',
    'alpha-2': 'AE',
    'alpha-3': 'ARE',
    code: '784',
    'require-postal-code': false,
    'postal-code-sample': '10000',
    'zone-code': 'GST',
    'time-zone-offset': '+04:00',
    'zone-name': 'Asia/Dubai',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '阿富汗',
      en: 'Afghanistan',
    },
    continent: 'Asia',
    'alpha-2': 'AF',
    'alpha-3': 'AFG',
    code: '4',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'AFT',
    'time-zone-offset': '+04:30',
    'zone-name': 'Asia/Kabul',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '安圭拉',
      en: 'Anguilla',
    },
    continent: 'North America',
    'alpha-2': 'AI',
    'alpha-3': 'AIA',
    code: '660',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'AST',
    'time-zone-offset': '-04:00',
    'zone-name': 'America/Anguilla',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '阿爾巴尼亞',
      en: 'Albania',
    },
    continent: 'Europe',
    'alpha-2': 'AL',
    'alpha-3': 'ALB',
    code: '8',
    'require-postal-code': false,
    'postal-code-sample': '1000',
    'zone-code': 'CET',
    'time-zone-offset': '+01:00',
    'zone-name': 'Europe/Tirane',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '亞美尼亞',
      en: 'Armenia',
    },
    continent: 'Europe',
    'alpha-2': 'AM',
    'alpha-3': 'ARM',
    code: '51',
    'require-postal-code': true,
    'postal-code-sample': '1',
    'zone-code': 'AMT',
    'time-zone-offset': '+04:00',
    'zone-name': 'Asia/Yerevan',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '安哥拉',
      en: 'Angola',
    },
    continent: 'Africa',
    'alpha-2': 'AO',
    'alpha-3': 'AGO',
    code: '24',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'WAT',
    'time-zone-offset': '+01:00',
    'zone-name': 'Africa/Luanda',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '阿根廷',
      en: 'Argentina',
    },
    continent: 'South America',
    'alpha-2': 'AR',
    'alpha-3': 'ARG',
    code: '32',
    'require-postal-code': true,
    'postal-code-sample': '5900',
    'zone-code': 'ART',
    'time-zone-offset': '-03:00',
    'zone-name': 'America/Argentina/Buenos_Aires',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '美屬薩摩亞',
      en: 'American Samoa',
    },
    continent: 'Oceania',
    'alpha-2': 'AS',
    'alpha-3': 'ASM',
    code: '16',
    'require-postal-code': false,
    'postal-code-sample': '96799',
    'zone-code': 'SST',
    'time-zone-offset': '-11:00',
    'zone-name': 'Pacific/Pago_Pago',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '奧地利',
      en: 'Austria',
    },
    continent: 'Europe',
    'alpha-2': 'AT',
    'alpha-3': 'AUT',
    code: '40',
    'require-postal-code': true,
    'postal-code-sample': '1063',
    'zone-code': 'CET',
    'time-zone-offset': '+01:00',
    'zone-name': 'Europe/Vienna',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '澳洲',
      en: 'Australia',
    },
    continent: 'Oceania',
    'alpha-2': 'AU',
    'alpha-3': 'AUS',
    code: '36',
    'require-postal-code': true,
    'postal-code-sample': '2600',
    'zone-code': 'AEST',
    'time-zone-offset': '+10:00',
    'zone-name': 'Australia/Sydney',
    hot: true,
    enable: true,
  },
  {
    name: {
      zh: '阿魯巴',
      en: 'Aruba',
    },
    continent: 'North America',
    'alpha-2': 'AW',
    'alpha-3': 'ABW',
    code: '533',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'AST',
    'time-zone-offset': '-04:00',
    'zone-name': 'America/Aruba',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '阿塞拜疆',
      en: 'Azerbaijan',
    },
    continent: 'Europe',
    'alpha-2': 'AZ',
    'alpha-3': 'AZE',
    code: '31',
    'require-postal-code': true,
    'postal-code-sample': 'AZ-1000',
    'zone-code': 'AZT',
    'time-zone-offset': '+04:00',
    'zone-name': 'Asia/Baku',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '波斯尼亞和黑塞哥維那',
      en: 'Bosnia-Herzegovina',
    },
    continent: 'Europe',
    'alpha-2': 'BA',
    'alpha-3': 'BIH',
    code: '70',
    'require-postal-code': true,
    'postal-code-sample': '71000',
    'zone-code': 'CET',
    'time-zone-offset': '+01:00',
    'zone-name': 'Europe/Sarajevo',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '巴巴多斯',
      en: 'Barbados',
    },
    continent: 'North America',
    'alpha-2': 'BB',
    'alpha-3': 'BRB',
    code: '52',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'AST',
    'time-zone-offset': '-04:00',
    'zone-name': 'America/Barbados',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '孟加拉國',
      en: 'Bangladesh',
    },
    continent: 'Asia',
    'alpha-2': 'BD',
    'alpha-3': 'BGD',
    code: '50',
    'require-postal-code': true,
    'postal-code-sample': '1000',
    'zone-code': 'BDST',
    'time-zone-offset': '+06:00',
    'zone-name': 'Asia/Dhaka',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '比利時',
      en: 'Belgium',
    },
    continent: 'Europe',
    'alpha-2': 'BE',
    'alpha-3': 'BEL',
    code: '56',
    'require-postal-code': true,
    'postal-code-sample': '1000',
    'zone-code': 'CET',
    'time-zone-offset': '+01:00',
    'zone-name': 'Europe/Brussels',
    hot: true,
    enable: true,
  },
  {
    name: {
      zh: '布基納法索',
      en: 'Burkina Faso',
    },
    continent: 'Africa',
    'alpha-2': 'BF',
    'alpha-3': 'BFA',
    code: '854',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'GMT',
    'time-zone-offset': '+00:00',
    'zone-name': 'Africa/Ouagadougou',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '保加利亞',
      en: 'Bulgaria',
    },
    continent: 'Europe',
    'alpha-2': 'BG',
    'alpha-3': 'BGR',
    code: '100',
    'require-postal-code': true,
    'postal-code-sample': '1217',
    'zone-code': 'EET',
    'time-zone-offset': '+02:00',
    'zone-name': 'Europe/Sofia',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '巴林',
      en: 'Bahrain',
    },
    continent: 'Asia',
    'alpha-2': 'BH',
    'alpha-3': 'BHR',
    code: '48',
    'require-postal-code': false,
    'postal-code-sample': '9999',
    'zone-code': 'AST',
    'time-zone-offset': '+03:00',
    'zone-name': 'Asia/Bahrain',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '布隆迪',
      en: 'Burundi',
    },
    continent: 'Africa',
    'alpha-2': 'BI',
    'alpha-3': 'BDI',
    code: '108',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'CAT',
    'time-zone-offset': '+02:00',
    'zone-name': 'Africa/Bujumbura',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '貝寧',
      en: 'Benin',
    },
    continent: 'Africa',
    'alpha-2': 'BJ',
    'alpha-3': 'BEN',
    code: '204',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'WAT',
    'time-zone-offset': '+01:00',
    'zone-name': 'Africa/Porto-Novo',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '聖巴泰勒米',
      en: 'St. Barthelemy',
    },
    continent: 'North America',
    'alpha-2': 'BL',
    'alpha-3': 'BLM',
    code: '652',
    'require-postal-code': true,
    'postal-code-sample': '97133',
    'zone-code': 'AST',
    'time-zone-offset': '-04:00',
    'zone-name': 'America/St_Barthelemy',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '百慕達',
      en: 'Bermuda',
    },
    continent: 'North America',
    'alpha-2': 'BM',
    'alpha-3': 'BMU',
    code: '60',
    'require-postal-code': false,
    'postal-code-sample': 'HM 08',
    'zone-code': 'AST',
    'time-zone-offset': '-04:00',
    'zone-name': 'Atlantic/Bermuda',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '汶萊',
      en: 'Brunei',
    },
    continent: 'Asia',
    'alpha-2': 'BN',
    'alpha-3': 'BRN',
    code: '96',
    'require-postal-code': true,
    'postal-code-sample': 'BA1111',
    'zone-code': 'BNT',
    'time-zone-offset': '+08:00',
    'zone-name': 'Asia/Brunei',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '玻利維亞',
      en: 'Bolivia',
    },
    continent: 'South America',
    'alpha-2': 'BO',
    'alpha-3': 'BOL',
    code: '68',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'BOT',
    'time-zone-offset': '-04:00',
    'zone-name': 'America/La_Paz',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '巴西',
      en: 'Brazil',
    },
    continent: 'South America',
    'alpha-2': 'BR',
    'alpha-3': 'BRA',
    code: '76',
    'require-postal-code': true,
    'postal-code-sample': '70000-000',
    'zone-code': 'BRT',
    'time-zone-offset': '-03:00',
    'zone-name': 'America/Sao_Paulo',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '巴哈馬',
      en: 'Bahamas',
    },
    continent: 'North America',
    'alpha-2': 'BS',
    'alpha-3': 'BHS',
    code: '44',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'EST',
    'time-zone-offset': '-05:00',
    'zone-name': 'America/Nassau',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '不丹',
      en: 'Bhutan',
    },
    continent: 'Asia',
    'alpha-2': 'BT',
    'alpha-3': 'BTN',
    code: '64',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'BTT',
    'time-zone-offset': '+06:00',
    'zone-name': 'Asia/Thimphu',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '博茨瓦納',
      en: 'Botswana',
    },
    continent: 'Africa',
    'alpha-2': 'BW',
    'alpha-3': 'BWA',
    code: '72',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'CAT',
    'time-zone-offset': '+02:00',
    'zone-name': 'Africa/Gaborone',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '白俄羅斯',
      en: 'Belarus',
    },
    continent: 'Europe',
    'alpha-2': 'BY',
    'alpha-3': 'BLR',
    code: '112',
    'require-postal-code': true,
    'postal-code-sample': '220001',
    'zone-code': 'EET',
    'time-zone-offset': '+03:00',
    'zone-name': 'Europe/Minsk',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '伯利茲',
      en: 'Belize',
    },
    continent: 'North America',
    'alpha-2': 'BZ',
    'alpha-3': 'BLZ',
    code: '84',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'CST',
    'time-zone-offset': '-06:00',
    'zone-name': 'America/Belize',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '加拿大',
      en: 'Canada',
    },
    continent: 'North America',
    'alpha-2': 'CA',
    'alpha-3': 'CAN',
    code: '124',
    'require-postal-code': true,
    'postal-code-sample': 'K1A 0K4',
    'zone-code': 'EST',
    'time-zone-offset': '-05:00',
    'zone-name': 'America/Toronto',
    hot: true,
    enable: true,
  },
  {
    name: {
      zh: '剛果民主共和國',
      en: 'Congo, Dem Rep Of',
    },
    continent: 'Africa',
    'alpha-2': 'CD',
    'alpha-3': 'COD',
    code: '180',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'WAT',
    'time-zone-offset': '+01:00',
    'zone-name': 'Africa/Kinshasa',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '剛果',
      en: 'Congo',
    },
    continent: 'Africa',
    'alpha-2': 'CG',
    'alpha-3': 'COG',
    code: '178',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'WAT',
    'time-zone-offset': '+01:00',
    'zone-name': 'Africa/Brazzaville',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '瑞士',
      en: 'Switzerland',
    },
    continent: 'Europe',
    'alpha-2': 'CH',
    'alpha-3': 'CHE',
    code: '756',
    'require-postal-code': true,
    'postal-code-sample': '3000',
    'zone-code': 'CET',
    'time-zone-offset': '+01:00',
    'zone-name': 'Europe/Zurich',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '科特迪瓦',
      en: 'Ivory Coast',
    },
    continent: 'Africa',
    'alpha-2': 'CI',
    'alpha-3': 'CIV',
    code: '384',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'GMT',
    'time-zone-offset': '+00:00',
    'zone-name': 'Africa/Abidjan',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '庫克群島',
      en: 'Cook Islands',
    },
    continent: 'Oceania',
    'alpha-2': 'CK',
    'alpha-3': 'COK',
    code: '184',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'HAST',
    'time-zone-offset': '-10:00',
    'zone-name': 'Pacific/Rarotonga',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '智利',
      en: 'Chile',
    },
    continent: 'South America',
    'alpha-2': 'CL',
    'alpha-3': 'CHL',
    code: '152',
    'require-postal-code': true,
    'postal-code-sample': '9340000',
    'zone-code': 'CLT',
    'time-zone-offset': '-04:00',
    'zone-name': 'America/Santiago',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '喀麥隆',
      en: 'Cameroon',
    },
    continent: 'Africa',
    'alpha-2': 'CM',
    'alpha-3': 'CMR',
    code: '120',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'WAT',
    'time-zone-offset': '+01:00',
    'zone-name': 'Africa/Douala',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '中國',
      en: 'China',
    },
    continent: 'Asia',
    'alpha-2': 'CN',
    'alpha-3': 'CHN',
    code: '156',
    'require-postal-code': true,
    'postal-code-sample': '100001',
    'zone-code': 'CST',
    'time-zone-offset': '+08:00',
    'zone-name': 'Asia/Shanghai',
    hot: true,
    enable: false,
  },
  {
    name: {
      zh: '哥倫比亞',
      en: 'Colombia',
    },
    continent: 'South America',
    'alpha-2': 'CO',
    'alpha-3': 'COL',
    code: '170',
    'require-postal-code': true,
    'postal-code-sample': '110111',
    'zone-code': 'COT',
    'time-zone-offset': '-05:00',
    'zone-name': 'America/Bogota',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '哥斯達黎加',
      en: 'Costa Rica',
    },
    continent: 'North America',
    'alpha-2': 'CR',
    'alpha-3': 'CRI',
    code: '188',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'CST',
    'time-zone-offset': '-06:00',
    'zone-name': 'America/Costa_Rica',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '維德角',
      en: 'Cape',
    },
    continent: 'Africa',
    'alpha-2': 'CV',
    'alpha-3': 'CPV',
    code: '132',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'CVT',
    'time-zone-offset': '-01:00',
    'zone-name': 'Atlantic/Cape_Verde',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '塞浦路斯',
      en: 'Cyprus',
    },
    continent: 'Europe',
    'alpha-2': 'CY',
    'alpha-3': 'CYP',
    code: '196',
    'require-postal-code': true,
    'postal-code-sample': '1000',
    'zone-code': 'EET',
    'time-zone-offset': '+02:00',
    'zone-name': 'Asia/Nicosia',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '捷克',
      en: 'Czech Republic',
    },
    continent: 'Europe',
    'alpha-2': 'CZ',
    'alpha-3': 'CZE',
    code: '203',
    'require-postal-code': true,
    'postal-code-sample': '100 00',
    'zone-code': 'CET',
    'time-zone-offset': '+01:00',
    'zone-name': 'Europe/Prague',
    hot: true,
    enable: true,
  },
  {
    name: {
      zh: '德國',
      en: 'Germany',
    },
    continent: 'Europe',
    'alpha-2': 'DE',
    'alpha-3': 'DEU',
    code: '276',
    'require-postal-code': true,
    'postal-code-sample': '10001',
    'zone-code': 'CET',
    'time-zone-offset': '+01:00',
    'zone-name': 'Europe/Berlin',
    hot: true,
    enable: true,
  },
  {
    name: {
      zh: '吉布提',
      en: 'Djibouti',
    },
    continent: 'Africa',
    'alpha-2': 'DJ',
    'alpha-3': 'DJI',
    code: '262',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'EAT',
    'time-zone-offset': '+03:00',
    'zone-name': 'Africa/Djibouti',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '丹麥',
      en: 'Denmark',
    },
    continent: 'Europe',
    'alpha-2': 'DK',
    'alpha-3': 'DNK',
    code: '208',
    'require-postal-code': true,
    'postal-code-sample': '1620',
    'zone-code': 'CET',
    'time-zone-offset': '+01:00',
    'zone-name': 'Europe/Copenhagen',
    hot: true,
    enable: true,
  },
  {
    name: {
      zh: '多米尼克',
      en: 'Dominica',
    },
    continent: 'North America',
    'alpha-2': 'DM',
    'alpha-3': 'DMA',
    code: '212',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'AST',
    'time-zone-offset': '-04:00',
    'zone-name': 'America/Dominica',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '多明尼加',
      en: 'Dominican Republic',
    },
    continent: 'North America',
    'alpha-2': 'DO',
    'alpha-3': 'DOM',
    code: '214',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'AST',
    'time-zone-offset': '-04:00',
    'zone-name': 'America/Santo_Domingo',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '阿爾及利亞',
      en: 'Algeria',
    },
    continent: 'Africa',
    'alpha-2': 'DZ',
    'alpha-3': 'DZA',
    code: '12',
    'require-postal-code': true,
    'postal-code-sample': '16000',
    'zone-code': 'CET',
    'time-zone-offset': '+01:00',
    'zone-name': 'Africa/Algiers',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '厄瓜多爾',
      en: 'Ecuador',
    },
    continent: 'South America',
    'alpha-2': 'EC',
    'alpha-3': 'ECU',
    code: '218',
    'require-postal-code': false,
    'postal-code-sample': '170101',
    'zone-code': 'ECT',
    'time-zone-offset': '-05:00',
    'zone-name': 'America/Guayaquil',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '愛沙尼亞',
      en: 'Estonia',
    },
    continent: 'Europe',
    'alpha-2': 'EE',
    'alpha-3': 'EST',
    code: '233',
    'require-postal-code': true,
    'postal-code-sample': '10001',
    'zone-code': 'EET',
    'time-zone-offset': '+02:00',
    'zone-name': 'Europe/Tallinn',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '埃及',
      en: 'Egypt',
    },
    continent: 'Africa',
    'alpha-2': 'EG',
    'alpha-3': 'EGY',
    code: '818',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'EET',
    'time-zone-offset': '+02:00',
    'zone-name': 'Africa/Cairo',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '厄立特里亞',
      en: 'Eritrea',
    },
    continent: 'Africa',
    'alpha-2': 'ER',
    'alpha-3': 'ERI',
    code: '232',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'EAT',
    'time-zone-offset': '+03:00',
    'zone-name': 'Africa/Asmara',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '西班牙',
      en: 'Spain',
    },
    continent: 'Europe',
    'alpha-2': 'ES',
    'alpha-3': 'ESP',
    code: '724',
    'require-postal-code': true,
    'postal-code-sample': '28001',
    'zone-code': 'CET',
    'time-zone-offset': '+01:00',
    'zone-name': 'Europe/Madrid',
    hot: true,
    enable: true,
  },
  {
    name: {
      zh: '埃塞俄比亞',
      en: 'Ethiopia',
    },
    continent: 'Africa',
    'alpha-2': 'ET',
    'alpha-3': 'ETH',
    code: '231',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'EAT',
    'time-zone-offset': '+03:00',
    'zone-name': 'Africa/Addis_Ababa',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '芬蘭',
      en: 'Finland',
    },
    continent: 'Europe',
    'alpha-2': 'FI',
    'alpha-3': 'FIN',
    code: '246',
    'require-postal-code': true,
    'postal-code-sample': '10',
    'zone-code': 'EET',
    'time-zone-offset': '+02:00',
    'zone-name': 'Europe/Helsinki',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '斐濟',
      en: 'Fiji',
    },
    continent: 'Oceania',
    'alpha-2': 'FJ',
    'alpha-3': 'FJI',
    code: '242',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'FJT',
    'time-zone-offset': '+12:00',
    'zone-name': 'Pacific/Fiji',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '密克羅尼西亞',
      en: 'Micronesia',
    },
    continent: 'Oceania',
    'alpha-2': 'FM',
    'alpha-3': 'FSM',
    code: '583',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'KOST',
    'time-zone-offset': '+11:00',
    'zone-name': 'Pacific/Pohnpei',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '法國',
      en: 'France',
    },
    continent: 'Europe',
    'alpha-2': 'FR',
    'alpha-3': 'FRA',
    code: '250',
    'require-postal-code': true,
    'postal-code-sample': '75001',
    'zone-code': 'CET',
    'time-zone-offset': '+01:00',
    'zone-name': 'Europe/Paris',
    hot: true,
    enable: true,
  },
  {
    name: {
      zh: '加蓬',
      en: 'Gabon',
    },
    continent: 'Africa',
    'alpha-2': 'GA',
    'alpha-3': 'GAB',
    code: '266',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'WAT',
    'time-zone-offset': '+01:00',
    'zone-name': 'Africa/Libreville',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '英國',
      en: 'United Kingdom',
    },
    continent: 'Europe',
    'alpha-2': 'GB',
    'alpha-3': 'GBR',
    code: '826',
    'require-postal-code': true,
    'postal-code-sample': 'E1 0AA',
    'zone-code': 'GMT',
    'time-zone-offset': '+00:00',
    'zone-name': 'Europe/London',
    hot: true,
    enable: true,
  },
  {
    name: {
      zh: '格林納達',
      en: 'Grenada',
    },
    continent: 'North America',
    'alpha-2': 'GD',
    'alpha-3': 'GRD',
    code: '308',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'AST',
    'time-zone-offset': '-04:00',
    'zone-name': 'America/Grenada',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '格魯吉亞',
      en: 'Georgia',
    },
    continent: 'Europe',
    'alpha-2': 'GE',
    'alpha-3': 'GEO',
    code: '268',
    'require-postal-code': true,
    'postal-code-sample': '100',
    'zone-code': 'GET',
    'time-zone-offset': '+04:00',
    'zone-name': 'Asia/Tbilisi',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '法屬圭亞那',
      en: 'French Guiana',
    },
    continent: 'South America',
    'alpha-2': 'GF',
    'alpha-3': 'GUF',
    code: '254',
    'require-postal-code': false,
    'postal-code-sample': '97300',
    'zone-code': 'GFT',
    'time-zone-offset': '-03:00',
    'zone-name': 'America/Cayenne',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '加納',
      en: 'Ghana',
    },
    continent: 'Africa',
    'alpha-2': 'GH',
    'alpha-3': 'GHA',
    code: '288',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'GMT',
    'time-zone-offset': '+00:00',
    'zone-name': 'Africa/Accra',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '直布羅陀',
      en: 'Gibraltar',
    },
    continent: 'Europe',
    'alpha-2': 'GI',
    'alpha-3': 'GIB',
    code: '292',
    'require-postal-code': false,
    'postal-code-sample': 'GX11 1AA',
    'zone-code': 'CET',
    'time-zone-offset': '+01:00',
    'zone-name': 'Europe/Gibraltar',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '格陵蘭',
      en: 'Greenland',
    },
    continent: 'North America',
    'alpha-2': 'GL',
    'alpha-3': 'GRL',
    code: '304',
    'require-postal-code': true,
    'postal-code-sample': '3900',
    'zone-code': 'EGT',
    'time-zone-offset': '-01:00',
    'zone-name': 'America/Scoresbysund',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '甘比亞',
      en: 'Gambia',
    },
    continent: 'Africa',
    'alpha-2': 'GM',
    'alpha-3': 'GMB',
    code: '270',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'GMT',
    'time-zone-offset': '+00:00',
    'zone-name': 'Africa/Banjul',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '畿內亞',
      en: 'Guinea',
    },
    continent: 'Africa',
    'alpha-2': 'GN',
    'alpha-3': 'GIN',
    code: '324',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'GMT',
    'time-zone-offset': '+00:00',
    'zone-name': 'Africa/Conakry',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '瓜德羅普',
      en: 'Guadeloupe',
    },
    continent: 'North America',
    'alpha-2': 'GP',
    'alpha-3': 'GLP',
    code: '312',
    'require-postal-code': false,
    'postal-code-sample': '97100',
    'zone-code': 'AST',
    'time-zone-offset': '-04:00',
    'zone-name': 'America/Guadeloupe',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '赤道畿內亞',
      en: 'Guinea',
    },
    continent: 'Africa',
    'alpha-2': 'GQ',
    'alpha-3': 'GNQ',
    code: '226',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'WAT',
    'time-zone-offset': '+01:00',
    'zone-name': 'Africa/Malabo',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '希臘',
      en: 'Greece',
    },
    continent: 'Europe',
    'alpha-2': 'GR',
    'alpha-3': 'GRC',
    code: '300',
    'require-postal-code': true,
    'postal-code-sample': '104 31',
    'zone-code': 'EET',
    'time-zone-offset': '+02:00',
    'zone-name': 'Europe/Athens',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '危地馬拉',
      en: 'Guatemala',
    },
    continent: 'North America',
    'alpha-2': 'GT',
    'alpha-3': 'GTM',
    code: '320',
    'require-postal-code': false,
    'postal-code-sample': '1000',
    'zone-code': 'CST',
    'time-zone-offset': '-06:00',
    'zone-name': 'America/Guatemala',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '關島',
      en: 'Guam',
    },
    continent: 'Oceania',
    'alpha-2': 'GU',
    'alpha-3': 'GUM',
    code: '316',
    'require-postal-code': true,
    'postal-code-sample': '96910',
    'zone-code': 'ChST',
    'time-zone-offset': '+10:00',
    'zone-name': 'Pacific/Guam',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '圭亞那',
      en: 'Guyana',
    },
    continent: 'South America',
    'alpha-2': 'GY',
    'alpha-3': 'GUY',
    code: '328',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'GYT',
    'time-zone-offset': '-04:00',
    'zone-name': 'America/Guyana',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '香港',
      en: 'Hong Kong',
    },
    continent: 'Asia',
    'alpha-2': 'HK',
    'alpha-3': 'HKG',
    code: '344',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'HKT',
    'time-zone-offset': '+08:00',
    'zone-name': 'Asia/Hong_Kong',
    hot: true,
    enable: true,
  },
  {
    name: {
      zh: '洪都拉斯',
      en: 'Honduras',
    },
    continent: 'North America',
    'alpha-2': 'HN',
    'alpha-3': 'HND',
    code: '340',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'CST',
    'time-zone-offset': '-06:00',
    'zone-name': 'America/Tegucigalpa',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '克羅地亞',
      en: 'Croatia',
    },
    continent: 'Europe',
    'alpha-2': 'HR',
    'alpha-3': 'HRV',
    code: '191',
    'require-postal-code': true,
    'postal-code-sample': '10000',
    'zone-code': 'CET',
    'time-zone-offset': '+01:00',
    'zone-name': 'Europe/Zagreb',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '海地',
      en: 'Haiti',
    },
    continent: 'North America',
    'alpha-2': 'HT',
    'alpha-3': 'HTI',
    code: '332',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'EST',
    'time-zone-offset': '-05:00',
    'zone-name': 'America/Port-au-Prince',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '匈牙利',
      en: 'Hungary',
    },
    continent: 'Europe',
    'alpha-2': 'HU',
    'alpha-3': 'HUN',
    code: '348',
    'require-postal-code': true,
    'postal-code-sample': '1011',
    'zone-code': 'CET',
    'time-zone-offset': '+01:00',
    'zone-name': 'Europe/Budapest',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '印尼',
      en: 'Indonesia',
    },
    continent: 'Asia',
    'alpha-2': 'ID',
    'alpha-3': 'IDN',
    code: '360',
    'require-postal-code': true,
    'postal-code-sample': '10110',
    'zone-code': 'WIB',
    'time-zone-offset': '+07:00',
    'zone-name': 'Asia/Jakarta',
    hot: true,
    enable: true,
  },
  {
    name: {
      zh: '愛爾蘭',
      en: 'Ireland',
    },
    continent: 'Europe',
    'alpha-2': 'IE',
    'alpha-3': 'IRL',
    code: '372',
    'require-postal-code': true,
    'postal-code-sample': 'DU  1',
    'zone-code': 'GMT',
    'time-zone-offset': '+00:00',
    'zone-name': 'Europe/Dublin',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '以色列',
      en: 'Israel',
    },
    continent: 'Asia',
    'alpha-2': 'IL',
    'alpha-3': 'ISR',
    code: '376',
    'require-postal-code': true,
    'postal-code-sample': '9025800',
    'zone-code': 'IST',
    'time-zone-offset': '+02:00',
    'zone-name': 'Asia/Jerusalem',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '印度',
      en: 'India',
    },
    continent: 'Asia',
    'alpha-2': 'IN',
    'alpha-3': 'IND',
    code: '356',
    'require-postal-code': true,
    'postal-code-sample': '110001',
    'zone-code': 'IST',
    'time-zone-offset': '+05:30',
    'zone-name': 'Asia/Kolkata',
    hot: true,
    enable: true,
  },
  {
    name: {
      zh: '伊拉克',
      en: 'Iraq',
    },
    continent: 'Asia',
    'alpha-2': 'IQ',
    'alpha-3': 'IRQ',
    code: '368',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'AST',
    'time-zone-offset': '+03:00',
    'zone-name': 'Asia/Baghdad',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '冰島',
      en: 'Iceland',
    },
    continent: 'Europe',
    'alpha-2': 'IS',
    'alpha-3': 'ISL',
    code: '352',
    'require-postal-code': false,
    'postal-code-sample': '101',
    'zone-code': 'GMT',
    'time-zone-offset': '+00:00',
    'zone-name': 'Atlantic/Reykjavik',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '意大利',
      en: 'Italy',
    },
    continent: 'Europe',
    'alpha-2': 'IT',
    'alpha-3': 'ITA',
    code: '380',
    'require-postal-code': true,
    'postal-code-sample': '00199',
    'zone-code': 'CET',
    'time-zone-offset': '+01:00',
    'zone-name': 'Europe/Rome',
    hot: true,
    enable: true,
  },
  {
    name: {
      zh: '牙買加',
      en: 'Jamaica',
    },
    continent: 'North America',
    'alpha-2': 'JM',
    'alpha-3': 'JAM',
    code: '388',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'EST',
    'time-zone-offset': '-05:00',
    'zone-name': 'America/Jamaica',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '約旦',
      en: 'Jordan',
    },
    continent: 'Asia',
    'alpha-2': 'JO',
    'alpha-3': 'JOR',
    code: '400',
    'require-postal-code': true,
    'postal-code-sample': '11118',
    'zone-code': 'AST',
    'time-zone-offset': '+03:00',
    'zone-name': 'Asia/Amman',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '日本',
      en: 'Japan',
    },
    continent: 'Asia',
    'alpha-2': 'JP',
    'alpha-3': 'JPN',
    code: '392',
    'require-postal-code': true,
    'postal-code-sample': '1008601',
    'zone-code': 'JST',
    'time-zone-offset': '+09:00',
    'zone-name': 'Asia/Tokyo',
    hot: true,
    enable: true,
  },
  {
    name: {
      zh: '肯雅',
      en: 'Kenya',
    },
    continent: 'Africa',
    'alpha-2': 'KE',
    'alpha-3': 'KEN',
    code: '404',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'EAT',
    'time-zone-offset': '+03:00',
    'zone-name': 'Africa/Nairobi',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '吉爾吉斯',
      en: 'Kyrgyzstan',
    },
    continent: 'Asia',
    'alpha-2': 'KG',
    'alpha-3': 'KGZ',
    code: '417',
    'require-postal-code': true,
    'postal-code-sample': '720000',
    'zone-code': 'KGT',
    'time-zone-offset': '+06:00',
    'zone-name': 'Asia/Bishkek',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '柬埔寨',
      en: 'Cambodia',
    },
    continent: 'Asia',
    'alpha-2': 'KH',
    'alpha-3': 'KHM',
    code: '116',
    'require-postal-code': true,
    'postal-code-sample': '',
    'zone-code': 'ICT',
    'time-zone-offset': '+07:00',
    'zone-name': 'Asia/Phnom_Penh',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '南韓',
      en: 'South Korea',
    },
    continent: 'Asia',
    'alpha-2': 'KR',
    'alpha-3': 'KOR',
    code: '410',
    'require-postal-code': true,
    'postal-code-sample': '47307',
    'zone-code': 'KST',
    'time-zone-offset': '+09:00',
    'zone-name': 'Asia/Seoul',
    hot: true,
    enable: true,
  },
  {
    name: {
      zh: '科威特',
      en: 'Kuwait',
    },
    continent: 'Asia',
    'alpha-2': 'KW',
    'alpha-3': 'KWT',
    code: '414',
    'require-postal-code': false,
    'postal-code-sample': '99999',
    'zone-code': 'AST',
    'time-zone-offset': '+03:00',
    'zone-name': 'Asia/Kuwait',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '開曼羣島',
      en: 'Cayman',
    },
    continent: 'North America',
    'alpha-2': 'KY',
    'alpha-3': 'CYM',
    code: '136',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'EST',
    'time-zone-offset': '-05:00',
    'zone-name': 'America/Cayman',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '哈薩克',
      en: 'Kazakhstan',
    },
    continent: 'Europe',
    'alpha-2': 'KZ',
    'alpha-3': 'KAZ',
    code: '398',
    'require-postal-code': true,
    'postal-code-sample': '010000',
    'zone-code': 'AQTT',
    'time-zone-offset': '+05:00',
    'zone-name': 'Asia/Aqtau',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '黎巴嫩',
      en: 'Lebanon',
    },
    continent: 'Asia',
    'alpha-2': 'LB',
    'alpha-3': 'LBN',
    code: '422',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'EET',
    'time-zone-offset': '+02:00',
    'zone-name': 'Asia/Beirut',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '列支敦士登',
      en: 'Liechtenstein',
    },
    continent: 'Europe',
    'alpha-2': 'LI',
    'alpha-3': 'LIE',
    code: '438',
    'require-postal-code': true,
    'postal-code-sample': '9490',
    'zone-code': 'CET',
    'time-zone-offset': '+01:00',
    'zone-name': 'Europe/Vaduz',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '斯里蘭卡',
      en: 'Sri Lanka',
    },
    continent: 'Asia',
    'alpha-2': 'LK',
    'alpha-3': 'LKA',
    code: '144',
    'require-postal-code': true,
    'postal-code-sample': '20000',
    'zone-code': 'LKT',
    'time-zone-offset': '+05:30',
    'zone-name': 'Asia/Colombo',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '利比利亞',
      en: 'Liberia',
    },
    continent: 'Africa',
    'alpha-2': 'LR',
    'alpha-3': 'LBR',
    code: '430',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'GMT',
    'time-zone-offset': '+00:00',
    'zone-name': 'Africa/Monrovia',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '萊索托',
      en: 'Lesotho',
    },
    continent: 'Africa',
    'alpha-2': 'LS',
    'alpha-3': 'LSO',
    code: '426',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'SAST',
    'time-zone-offset': '+02:00',
    'zone-name': 'Africa/Maseru',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '立陶宛',
      en: 'Lithuania',
    },
    continent: 'Europe',
    'alpha-2': 'LT',
    'alpha-3': 'LTU',
    code: '440',
    'require-postal-code': true,
    'postal-code-sample': 'LT-01001',
    'zone-code': 'EET',
    'time-zone-offset': '+02:00',
    'zone-name': 'Europe/Vilnius',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '盧森堡',
      en: 'Luxembourg',
    },
    continent: 'Europe',
    'alpha-2': 'LU',
    'alpha-3': 'LUX',
    code: '442',
    'require-postal-code': true,
    'postal-code-sample': '1009',
    'zone-code': 'CET',
    'time-zone-offset': '+01:00',
    'zone-name': 'Europe/Luxembourg',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '拉脫維亞',
      en: 'Latvia',
    },
    continent: 'Europe',
    'alpha-2': 'LV',
    'alpha-3': 'LVA',
    code: '428',
    'require-postal-code': true,
    'postal-code-sample': 'LV-1024',
    'zone-code': 'EET',
    'time-zone-offset': '+02:00',
    'zone-name': 'Europe/Riga',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '利比亞',
      en: 'Libya',
    },
    continent: 'Africa',
    'alpha-2': 'LY',
    'alpha-3': 'LBY',
    code: '434',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'EET',
    'time-zone-offset': '+02:00',
    'zone-name': 'Africa/Tripoli',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '摩洛哥',
      en: 'Morocco',
    },
    continent: 'Africa',
    'alpha-2': 'MA',
    'alpha-3': 'MAR',
    code: '504',
    'require-postal-code': false,
    'postal-code-sample': '10050',
    'zone-code': 'WET',
    'time-zone-offset': '+00:00',
    'zone-name': 'Africa/Casablanca',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '摩納哥',
      en: 'Monaco',
    },
    continent: 'Europe',
    'alpha-2': 'MC',
    'alpha-3': 'MCO',
    code: '492',
    'require-postal-code': true,
    'postal-code-sample': '98000',
    'zone-code': 'CET',
    'time-zone-offset': '+01:00',
    'zone-name': 'Europe/Monaco',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '摩爾多瓦',
      en: 'Moldova',
    },
    continent: 'Europe',
    'alpha-2': 'MD',
    'alpha-3': 'MDA',
    code: '498',
    'require-postal-code': false,
    'postal-code-sample': '2001',
    'zone-code': 'EET',
    'time-zone-offset': '+02:00',
    'zone-name': 'Europe/Chisinau',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '黑山',
      en: 'Montenegro',
    },
    continent: 'Europe',
    'alpha-2': 'ME',
    'alpha-3': 'MNE',
    code: '499',
    'require-postal-code': true,
    'postal-code-sample': '81000',
    'zone-code': 'CET',
    'time-zone-offset': '+01:00',
    'zone-name': 'Europe/Podgorica',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '馬達加斯加',
      en: 'Madagascar',
    },
    continent: 'Africa',
    'alpha-2': 'MG',
    'alpha-3': 'MDG',
    code: '450',
    'require-postal-code': true,
    'postal-code-sample': '101',
    'zone-code': 'EAT',
    'time-zone-offset': '+03:00',
    'zone-name': 'Indian/Antananarivo',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '馬紹爾群島',
      en: 'Marshall Islands',
    },
    continent: 'Oceania',
    'alpha-2': 'MH',
    'alpha-3': 'MHL',
    code: '584',
    'require-postal-code': true,
    'postal-code-sample': '96960',
    'zone-code': 'MHT',
    'time-zone-offset': '+12:00',
    'zone-name': 'Pacific/Kwajalein',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '馬其頓',
      en: 'Macedonia',
    },
    continent: 'Europe',
    'alpha-2': 'MK',
    'alpha-3': 'MKD',
    code: '807',
    'require-postal-code': true,
    'postal-code-sample': '1000',
    'zone-code': 'CET',
    'time-zone-offset': '+01:00',
    'zone-name': 'Europe/Skopje',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '馬里',
      en: 'Mali',
    },
    continent: 'Africa',
    'alpha-2': 'ML',
    'alpha-3': 'MLI',
    code: '466',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'GMT',
    'time-zone-offset': '+00:00',
    'zone-name': 'Africa/Bamako',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '緬甸',
      en: 'Myanmar',
    },
    continent: 'Asia',
    'alpha-2': 'MM',
    'alpha-3': 'MMR',
    code: '104',
    'require-postal-code': true,
    'postal-code-sample': '15011',
    'zone-code': 'MMT',
    'time-zone-offset': '+06:30',
    'zone-name': 'Asia/Yangon',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '蒙古國',
      en: 'Mongolia',
    },
    continent: 'Asia',
    'alpha-2': 'MN',
    'alpha-3': 'MNG',
    code: '496',
    'require-postal-code': true,
    'postal-code-sample': '210130',
    'zone-code': 'CHOT',
    'time-zone-offset': '+08:00',
    'zone-name': 'Asia/Choibalsan',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '澳門',
      en: 'Macao',
    },
    continent: 'Asia',
    'alpha-2': 'MO',
    'alpha-3': 'MAC',
    code: '446',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'CT',
    'time-zone-offset': '+08:00',
    'zone-name': 'Asia/Macau',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '北馬利安納羣島',
      en: 'Northern Mariana Islands',
    },
    continent: 'Oceania',
    'alpha-2': 'MP',
    'alpha-3': 'MNP',
    code: '580',
    'require-postal-code': false,
    'postal-code-sample': '96950',
    'zone-code': 'ChST',
    'time-zone-offset': '+10:00',
    'zone-name': 'Pacific/Saipan',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '馬提尼克',
      en: 'Martinique',
    },
    continent: 'North America',
    'alpha-2': 'MQ',
    'alpha-3': 'MTQ',
    code: '474',
    'require-postal-code': true,
    'postal-code-sample': '97200',
    'zone-code': 'AST',
    'time-zone-offset': '-04:00',
    'zone-name': 'America/Martinique',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '毛里塔尼亞',
      en: 'Mauritania',
    },
    continent: 'Africa',
    'alpha-2': 'MR',
    'alpha-3': 'MRT',
    code: '478',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'GMT',
    'time-zone-offset': '+00:00',
    'zone-name': 'Africa/Nouakchott',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '蒙塞拉特島',
      en: 'Montserrat',
    },
    continent: 'North America',
    'alpha-2': 'MS',
    'alpha-3': 'MSR',
    code: '500',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'AST',
    'time-zone-offset': '-04:00',
    'zone-name': 'America/Montserrat',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '馬耳他',
      en: 'Malta',
    },
    continent: 'Europe',
    'alpha-2': 'MT',
    'alpha-3': 'MLT',
    code: '470',
    'require-postal-code': false,
    'postal-code-sample': 'VLT 1000',
    'zone-code': 'CET',
    'time-zone-offset': '+01:00',
    'zone-name': 'Europe/Malta',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '毛里求斯',
      en: 'Mauritius',
    },
    continent: 'Africa',
    'alpha-2': 'MU',
    'alpha-3': 'MUS',
    code: '480',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'MUT',
    'time-zone-offset': '+04:00',
    'zone-name': 'Indian/Mauritius',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '馬爾代夫',
      en: 'Maldives',
    },
    continent: 'Asia',
    'alpha-2': 'MV',
    'alpha-3': 'MDV',
    code: '462',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'MVT',
    'time-zone-offset': '+05:00',
    'zone-name': 'Indian/Maldives',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '馬拉維',
      en: 'Malawi',
    },
    continent: 'Africa',
    'alpha-2': 'MW',
    'alpha-3': 'MWI',
    code: '454',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'CAT',
    'time-zone-offset': '+02:00',
    'zone-name': 'Africa/Blantyre',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '墨西哥',
      en: 'Mexico',
    },
    continent: 'North America',
    'alpha-2': 'MX',
    'alpha-3': 'MEX',
    code: '484',
    'require-postal-code': true,
    'postal-code-sample': '02860',
    'zone-code': 'CST',
    'time-zone-offset': '-06:00',
    'zone-name': 'America/Mexico_City',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '馬來西亞',
      en: 'Malaysia',
    },
    continent: 'Asia',
    'alpha-2': 'MY',
    'alpha-3': 'MYS',
    code: '458',
    'require-postal-code': true,
    'postal-code-sample': '50000',
    'zone-code': 'MYT',
    'time-zone-offset': '+08:00',
    'zone-name': 'Asia/Kuala_Lumpur',
    hot: true,
    enable: true,
  },
  {
    name: {
      zh: '莫桑比克',
      en: 'Mozambique',
    },
    continent: 'Africa',
    'alpha-2': 'MZ',
    'alpha-3': 'MOZ',
    code: '508',
    'require-postal-code': true,
    'postal-code-sample': '',
    'zone-code': 'CAT',
    'time-zone-offset': '+02:00',
    'zone-name': 'Africa/Maputo',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '納米比亞',
      en: 'Namibia',
    },
    continent: 'Africa',
    'alpha-2': 'NA',
    'alpha-3': 'NAM',
    code: '516',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'WAT',
    'time-zone-offset': '+01:00',
    'zone-name': 'Africa/Windhoek',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '新喀里多尼亞',
      en: 'New Caledonia',
    },
    continent: 'Oceania',
    'alpha-2': 'NC',
    'alpha-3': 'NCL',
    code: '540',
    'require-postal-code': false,
    'postal-code-sample': '98800',
    'zone-code': 'NCT',
    'time-zone-offset': '+11:00',
    'zone-name': 'Pacific/Noumea',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '尼日爾',
      en: 'Niger',
    },
    continent: 'Africa',
    'alpha-2': 'NE',
    'alpha-3': 'NER',
    code: '562',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'WAT',
    'time-zone-offset': '+01:00',
    'zone-name': 'Africa/Niamey',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '諾福島',
      en: 'Norfolk Island',
    },
    continent: 'Oceania',
    'alpha-2': 'NF',
    'alpha-3': 'NFK',
    code: '574',
    'require-postal-code': false,
    'postal-code-sample': '2899',
    'zone-code': 'NFT',
    'time-zone-offset': '+11:30',
    'zone-name': 'Pacific/Norfolk',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '尼日利亞',
      en: 'Nigeria',
    },
    continent: 'Africa',
    'alpha-2': 'NG',
    'alpha-3': 'NGA',
    code: '566',
    'require-postal-code': true,
    'postal-code-sample': '',
    'zone-code': 'WAT',
    'time-zone-offset': '+01:00',
    'zone-name': 'Africa/Lagos',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '尼加拉瓜',
      en: 'Nicaragua',
    },
    continent: 'North America',
    'alpha-2': 'NI',
    'alpha-3': 'NIC',
    code: '558',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'CST',
    'time-zone-offset': '-06:00',
    'zone-name': 'America/Managua',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '荷蘭',
      en: 'Netherlands',
    },
    continent: 'Europe',
    'alpha-2': 'NL',
    'alpha-3': 'NLD',
    code: '528',
    'require-postal-code': true,
    'postal-code-sample': '1011 AA',
    'zone-code': 'CET',
    'time-zone-offset': '+01:00',
    'zone-name': 'Europe/Amsterdam',
    hot: true,
    enable: true,
  },
  {
    name: {
      zh: '挪威',
      en: 'Norway',
    },
    continent: 'Europe',
    'alpha-2': 'NO',
    'alpha-3': 'NOR',
    code: '578',
    'require-postal-code': true,
    'postal-code-sample': '0045',
    'zone-code': 'CET',
    'time-zone-offset': '+01:00',
    'zone-name': 'Europe/Oslo',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '尼泊爾',
      en: 'Nepal',
    },
    continent: 'Asia',
    'alpha-2': 'NP',
    'alpha-3': 'NPL',
    code: '524',
    'require-postal-code': true,
    'postal-code-sample': '44600',
    'zone-code': 'NPT',
    'time-zone-offset': '+05:45',
    'zone-name': 'Asia/Kathmandu',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '紐西蘭',
      en: 'New Zealand',
    },
    continent: 'Oceania',
    'alpha-2': 'NZ',
    'alpha-3': 'NZL',
    code: '554',
    'require-postal-code': true,
    'postal-code-sample': '6011',
    'zone-code': 'NZST',
    'time-zone-offset': '+12:00',
    'zone-name': 'Pacific/Auckland',
    hot: true,
    enable: true,
  },
  {
    name: {
      zh: '阿曼',
      en: 'Oman',
    },
    continent: 'Asia',
    'alpha-2': 'OM',
    'alpha-3': 'OMN',
    code: '512',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'GST',
    'time-zone-offset': '+04:00',
    'zone-name': 'Asia/Muscat',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '巴拿馬',
      en: 'Panama',
    },
    continent: 'North America',
    'alpha-2': 'PA',
    'alpha-3': 'PAN',
    code: '591',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'EST',
    'time-zone-offset': '-05:00',
    'zone-name': 'America/Panama',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '秘魯',
      en: 'Peru',
    },
    continent: 'South America',
    'alpha-2': 'PE',
    'alpha-3': 'PER',
    code: '604',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'PET',
    'time-zone-offset': '-05:00',
    'zone-name': 'America/Lima',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '法屬波利尼西亞',
      en: 'French Polynesia',
    },
    continent: 'Oceania',
    'alpha-2': 'PF',
    'alpha-3': 'PYF',
    code: '258',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'GAMT',
    'time-zone-offset': '-09:00',
    'zone-name': 'Pacific/Gambier',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '巴布亞新畿內亞',
      en: 'Papua New Guinea',
    },
    continent: 'Oceania',
    'alpha-2': 'PG',
    'alpha-3': 'PNG',
    code: '598',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'PGT',
    'time-zone-offset': '+10:00',
    'zone-name': 'Pacific/Port_Moresby',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '菲律賓',
      en: 'Philippines',
    },
    continent: 'Asia',
    'alpha-2': 'PH',
    'alpha-3': 'PHL',
    code: '608',
    'require-postal-code': true,
    'postal-code-sample': '1308',
    'zone-code': 'PHT',
    'time-zone-offset': '+08:00',
    'zone-name': 'Asia/Manila',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '巴基斯坦',
      en: 'Pakistan',
    },
    continent: 'Asia',
    'alpha-2': 'PK',
    'alpha-3': 'PAK',
    code: '586',
    'require-postal-code': false,
    'postal-code-sample': '44000',
    'zone-code': 'PKT',
    'time-zone-offset': '+05:00',
    'zone-name': 'Asia/Karachi',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '波蘭',
      en: 'Poland',
    },
    continent: 'Europe',
    'alpha-2': 'PL',
    'alpha-3': 'POL',
    code: '616',
    'require-postal-code': true,
    'postal-code-sample': '00-001',
    'zone-code': 'CET',
    'time-zone-offset': '+01:00',
    'zone-name': 'Europe/Warsaw',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '波多黎各',
      en: 'Puerto Rico',
    },
    continent: 'North America',
    'alpha-2': 'PR',
    'alpha-3': 'PRI',
    code: '630',
    'require-postal-code': true,
    'postal-code-sample': '901',
    'zone-code': 'AST',
    'time-zone-offset': '-04:00',
    'zone-name': 'America/Puerto_Rico',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '葡萄牙',
      en: 'Portugal',
    },
    continent: 'Europe',
    'alpha-2': 'PT',
    'alpha-3': 'PRT',
    code: '620',
    'require-postal-code': true,
    'postal-code-sample': '1000-001',
    'zone-code': 'WET',
    'time-zone-offset': '+00:00',
    'zone-name': 'Europe/Lisbon',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '帛琉',
      en: 'Palau',
    },
    continent: 'Oceania',
    'alpha-2': 'PW',
    'alpha-3': 'PLW',
    code: '585',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'PWT',
    'time-zone-offset': '+09:00',
    'zone-name': 'Pacific/Palau',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '巴拉圭',
      en: 'Paraguay',
    },
    continent: 'South America',
    'alpha-2': 'PY',
    'alpha-3': 'PRY',
    code: '600',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'PYT',
    'time-zone-offset': '-04:00',
    'zone-name': 'America/Asuncion',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '卡塔爾',
      en: 'Qatar',
    },
    continent: 'Asia',
    'alpha-2': 'QA',
    'alpha-3': 'QAT',
    code: '634',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'AST',
    'time-zone-offset': '+03:00',
    'zone-name': 'Asia/Qatar',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '留尼旺島',
      en: 'Reunion',
    },
    continent: 'Africa',
    'alpha-2': 'RE',
    'alpha-3': 'REU',
    code: '638',
    'require-postal-code': true,
    'postal-code-sample': '97400',
    'zone-code': 'RET',
    'time-zone-offset': '+04:00',
    'zone-name': 'Indian/Reunion',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '羅馬尼亞',
      en: 'Romania',
    },
    continent: 'Europe',
    'alpha-2': 'RO',
    'alpha-3': 'ROU',
    code: '642',
    'require-postal-code': true,
    'postal-code-sample': '010011',
    'zone-code': 'EET',
    'time-zone-offset': '+02:00',
    'zone-name': 'Europe/Bucharest',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '塞爾維亞',
      en: 'Serbia',
    },
    continent: 'Europe',
    'alpha-2': 'RS',
    'alpha-3': 'SRB',
    code: '688',
    'require-postal-code': false,
    'postal-code-sample': '11180',
    'zone-code': 'CET',
    'time-zone-offset': '+01:00',
    'zone-name': 'Europe/Belgrade',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '俄羅斯',
      en: 'Russia',
    },
    continent: 'Europe',
    'alpha-2': 'RU',
    'alpha-3': 'RUS',
    code: '643',
    'require-postal-code': true,
    'postal-code-sample': '101000',
    'zone-code': 'MSK',
    'time-zone-offset': '+04:00',
    'zone-name': 'Europe/Moscow',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '盧旺達',
      en: 'Rwanda',
    },
    continent: 'Africa',
    'alpha-2': 'RW',
    'alpha-3': 'RWA',
    code: '646',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'CAT',
    'time-zone-offset': '+02:00',
    'zone-name': 'Africa/Kigali',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '沙地阿拉伯',
      en: 'Saudi Arabia',
    },
    continent: 'Asia',
    'alpha-2': 'SA',
    'alpha-3': 'SAU',
    code: '682',
    'require-postal-code': true,
    'postal-code-sample': '11411',
    'zone-code': 'AST',
    'time-zone-offset': '+03:00',
    'zone-name': 'Asia/Riyadh',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '塞舌爾',
      en: 'Seychelles',
    },
    continent: 'Africa',
    'alpha-2': 'SC',
    'alpha-3': 'SYC',
    code: '690',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'SCT',
    'time-zone-offset': '+04:00',
    'zone-name': 'Indian/Mahe',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '蘇丹',
      en: 'Sudan',
    },
    continent: 'Africa',
    'alpha-2': 'SD',
    'alpha-3': 'SDN',
    code: '729',
    'require-postal-code': true,
    'postal-code-sample': '79371',
    'zone-code': 'EAT',
    'time-zone-offset': '+03:00',
    'zone-name': 'Africa/Khartoum',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '瑞典',
      en: 'Sweden',
    },
    continent: 'Europe',
    'alpha-2': 'SE',
    'alpha-3': 'SWE',
    code: '752',
    'require-postal-code': true,
    'postal-code-sample': '11351',
    'zone-code': 'CET',
    'time-zone-offset': '+01:00',
    'zone-name': 'Europe/Stockholm',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '新加坡',
      en: 'Singapore',
    },
    continent: 'Asia',
    'alpha-2': 'SG',
    'alpha-3': 'SGP',
    code: '702',
    'require-postal-code': true,
    'postal-code-sample': '178906',
    'zone-code': 'SGT',
    'time-zone-offset': '+08:00',
    'zone-name': 'Asia/Singapore',
    hot: true,
    enable: true,
  },
  {
    name: {
      zh: '斯洛文尼亞',
      en: 'Slovenia',
    },
    continent: 'Europe',
    'alpha-2': 'SI',
    'alpha-3': 'SVN',
    code: '705',
    'require-postal-code': true,
    'postal-code-sample': '1000',
    'zone-code': 'CET',
    'time-zone-offset': '+01:00',
    'zone-name': 'Europe/Ljubljana',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '斯洛伐克',
      en: 'Slovak Republic',
    },
    continent: 'Europe',
    'alpha-2': 'SK',
    'alpha-3': 'SVK',
    code: '703',
    'require-postal-code': true,
    'postal-code-sample': '810 00',
    'zone-code': 'CET',
    'time-zone-offset': '+01:00',
    'zone-name': 'Europe/Bratislava',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '塞拉利昂',
      en: 'Sierra Leone',
    },
    continent: 'Africa',
    'alpha-2': 'SL',
    'alpha-3': 'SLE',
    code: '694',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'GMT',
    'time-zone-offset': '+00:00',
    'zone-name': 'Africa/Abidjan',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '聖馬連奴',
      en: 'San Marino',
    },
    continent: 'Europe',
    'alpha-2': 'SM',
    'alpha-3': 'SMR',
    code: '674',
    'require-postal-code': false,
    'postal-code-sample': '47890',
    'zone-code': 'CET',
    'time-zone-offset': '+01:00',
    'zone-name': 'Europe/San_Marino',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '塞內加爾',
      en: 'Senegal',
    },
    continent: 'Africa',
    'alpha-2': 'SN',
    'alpha-3': 'SEN',
    code: '686',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'GMT',
    'time-zone-offset': '+00:00',
    'zone-name': 'Africa/Dakar',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '索馬里',
      en: 'Somalia',
    },
    continent: 'Africa',
    'alpha-2': 'SO',
    'alpha-3': 'SOM',
    code: '706',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'EAT',
    'time-zone-offset': '+03:00',
    'zone-name': 'Africa/Mogadishu',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '蘇里南',
      en: 'Suriname',
    },
    continent: 'South America',
    'alpha-2': 'SR',
    'alpha-3': 'SUR',
    code: '740',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'SRT',
    'time-zone-offset': '-03:00',
    'zone-name': 'America/Paramaribo',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '薩爾瓦多',
      en: 'El Salvador',
    },
    continent: 'North America',
    'alpha-2': 'SV',
    'alpha-3': 'SLV',
    code: '222',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'CST',
    'time-zone-offset': '-06:00',
    'zone-name': 'America/El_Salvador',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '敘利亞',
      en: 'Syria',
    },
    continent: 'Asia',
    'alpha-2': 'SY',
    'alpha-3': 'SYR',
    code: '760',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'EET',
    'time-zone-offset': '+02:00',
    'zone-name': 'Asia/Damascus',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '斯威士蘭',
      en: 'Swaziland',
    },
    continent: 'Africa',
    'alpha-2': 'SZ',
    'alpha-3': 'SWZ',
    code: '748',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'SAST',
    'time-zone-offset': '+02:00',
    'zone-name': 'Africa/Mbabane',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '特克斯與凱科斯群島',
      en: 'Turks & Caicos Islands',
    },
    continent: 'North America',
    'alpha-2': 'TC',
    'alpha-3': 'TCA',
    code: '796',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'EST',
    'time-zone-offset': '-05:00',
    'zone-name': 'America/Grand_Turk',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '乍得',
      en: 'Chad',
    },
    continent: 'Africa',
    'alpha-2': 'TD',
    'alpha-3': 'TCD',
    code: '148',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'WAT',
    'time-zone-offset': '+01:00',
    'zone-name': 'Africa/Ndjamena',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '多哥',
      en: 'Togo',
    },
    continent: 'Africa',
    'alpha-2': 'TG',
    'alpha-3': 'TGO',
    code: '768',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'GMT',
    'time-zone-offset': '+00:00',
    'zone-name': 'Africa/Lome',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '泰國',
      en: 'Thailand',
    },
    continent: 'Asia',
    'alpha-2': 'TH',
    'alpha-3': 'THA',
    code: '764',
    'require-postal-code': true,
    'postal-code-sample': '30150',
    'zone-code': 'ICT',
    'time-zone-offset': '+07:00',
    'zone-name': 'Asia/Bangkok',
    hot: true,
    enable: true,
  },
  {
    name: {
      zh: '突尼西亞',
      en: 'Tunisia',
    },
    continent: 'Africa',
    'alpha-2': 'TN',
    'alpha-3': 'TUN',
    code: '788',
    'require-postal-code': true,
    'postal-code-sample': '1000',
    'zone-code': 'CET',
    'time-zone-offset': '+01:00',
    'zone-name': 'Africa/Tunis',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '湯加',
      en: 'Tonga',
    },
    continent: 'Oceania',
    'alpha-2': 'TO',
    'alpha-3': 'TON',
    code: '776',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'TOT',
    'time-zone-offset': '+13:00',
    'zone-name': 'Pacific/Tongatapu',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '土耳其',
      en: 'Turkey',
    },
    continent: 'Europe',
    'alpha-2': 'TR',
    'alpha-3': 'TUR',
    code: '792',
    'require-postal-code': true,
    'postal-code-sample': '01960',
    'zone-code': 'EET',
    'time-zone-offset': '+02:00',
    'zone-name': 'Europe/Istanbul',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '千里達',
      en: 'Trinidad & Tobago',
    },
    continent: 'North America',
    'alpha-2': 'TT',
    'alpha-3': 'TTO',
    code: '780',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'AST',
    'time-zone-offset': '-04:00',
    'zone-name': 'America/Port_of_Spain',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '臺灣',
      en: 'Taiwan',
    },
    continent: 'Asia',
    'alpha-2': 'TW',
    'alpha-3': 'TWN',
    code: '158',
    'require-postal-code': true,
    'postal-code-sample': '10001',
    'zone-code': 'CST',
    'time-zone-offset': '+08:00',
    'zone-name': 'Asia/Taipei',
    hot: true,
    enable: true,
  },
  {
    name: {
      zh: '坦桑尼亞',
      en: 'Tanzania',
    },
    continent: 'Africa',
    'alpha-2': 'TZ',
    'alpha-3': 'TZA',
    code: '834',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'EAT',
    'time-zone-offset': '+03:00',
    'zone-name': 'Africa/Dar_es_Salaam',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '烏克蘭',
      en: 'Ukraine',
    },
    continent: 'Europe',
    'alpha-2': 'UA',
    'alpha-3': 'UKR',
    code: '804',
    'require-postal-code': true,
    'postal-code-sample': '2088',
    'zone-code': 'EET',
    'time-zone-offset': '+02:00',
    'zone-name': 'Europe/Kiev',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '烏干達',
      en: 'Uganda',
    },
    continent: 'Africa',
    'alpha-2': 'UG',
    'alpha-3': 'UGA',
    code: '800',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'EAT',
    'time-zone-offset': '+03:00',
    'zone-name': 'Africa/Kampala',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '美國',
      en: 'United States',
    },
    continent: 'North America',
    'alpha-2': 'US',
    'alpha-3': 'USA',
    code: '840',
    'require-postal-code': true,
    'postal-code-sample': '10275',
    'zone-code': 'PST',
    'time-zone-offset': '-08:00',
    'zone-name': 'America/Los_Angeles',
    hot: true,
    enable: true,
  },
  {
    name: {
      zh: '烏拉圭',
      en: 'Uruguay',
    },
    continent: 'South America',
    'alpha-2': 'UY',
    'alpha-3': 'URY',
    code: '858',
    'require-postal-code': true,
    'postal-code-sample': '11000',
    'zone-code': 'UYT',
    'time-zone-offset': '-03:00',
    'zone-name': 'America/Montevideo',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '烏茲別克斯坦',
      en: 'Uzbekistan',
    },
    continent: 'Asia',
    'alpha-2': 'UZ',
    'alpha-3': 'UZB',
    code: '860',
    'require-postal-code': true,
    'postal-code-sample': '100000',
    'zone-code': 'UZT',
    'time-zone-offset': '+05:00',
    'zone-name': 'Asia/Tashkent',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '梵蒂岡',
      en: 'Vatican City',
    },
    continent: 'Europe',
    'alpha-2': 'VA',
    'alpha-3': 'VAT',
    code: '336',
    'require-postal-code': true,
    'postal-code-sample': '120',
    'zone-code': 'CET',
    'time-zone-offset': '+01:00',
    'zone-name': 'Europe/Vatican',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '委內瑞拉',
      en: 'Venezuela',
    },
    continent: 'South America',
    'alpha-2': 'VE',
    'alpha-3': 'VEN',
    code: '862',
    'require-postal-code': false,
    'postal-code-sample': '1070',
    'zone-code': 'VET',
    'time-zone-offset': '-04:30',
    'zone-name': 'America/Caracas',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '英屬處女羣島',
      en: 'British Virgin Islands',
    },
    continent: 'North America',
    'alpha-2': 'VG',
    'alpha-3': 'VGB',
    code: '92',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'AST',
    'time-zone-offset': '-04:00',
    'zone-name': 'America/Tortola',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '美屬處女群島',
      en: 'United States Virgin Islands',
    },
    continent: 'North America',
    'alpha-2': 'VI',
    'alpha-3': 'VIR',
    code: '850',
    'require-postal-code': true,
    'postal-code-sample': '00801',
    'zone-code': 'AST',
    'time-zone-offset': '-04:00',
    'zone-name': 'America/Tortola',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '越南',
      en: 'Vietnam',
    },
    continent: 'Asia',
    'alpha-2': 'VN',
    'alpha-3': 'VNM',
    code: '704',
    'require-postal-code': true,
    'postal-code-sample': '69830',
    'zone-code': 'ICT',
    'time-zone-offset': '+07:00',
    'zone-name': 'Asia/Ho_Chi_Minh',
    hot: true,
    enable: true,
  },
  {
    name: {
      zh: '瓦努阿圖',
      en: 'Vanuatu',
    },
    continent: 'Oceania',
    'alpha-2': 'VU',
    'alpha-3': 'VUT',
    code: '548',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'VUT',
    'time-zone-offset': '+11:00',
    'zone-name': 'Pacific/Efate',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '薩摩亞',
      en: 'Samoa',
    },
    continent: 'Oceania',
    'alpha-2': 'WS',
    'alpha-3': 'WSM',
    code: '882',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'WST',
    'time-zone-offset': '+13:00',
    'zone-name': 'Pacific/Apia',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '也門',
      en: 'Yemen',
    },
    continent: 'Asia',
    'alpha-2': 'YE',
    'alpha-3': 'YEM',
    code: '887',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'AST',
    'time-zone-offset': '+03:00',
    'zone-name': 'Asia/Aden',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '南非',
      en: 'South Africa',
    },
    continent: 'Africa',
    'alpha-2': 'ZA',
    'alpha-3': 'ZAF',
    code: '710',
    'require-postal-code': true,
    'postal-code-sample': '0001',
    'zone-code': 'SAST',
    'time-zone-offset': '+02:00',
    'zone-name': 'Africa/Johannesburg',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '贊比亞',
      en: 'Zambia',
    },
    continent: 'Africa',
    'alpha-2': 'ZM',
    'alpha-3': 'ZMB',
    code: '894',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'CAT',
    'time-zone-offset': '+02:00',
    'zone-name': 'Africa/Lusaka',
    hot: false,
    enable: true,
  },
  {
    name: {
      zh: '津巴布韋',
      en: 'Zimbabwe',
    },
    continent: 'Africa',
    'alpha-2': 'ZW',
    'alpha-3': 'ZWE',
    code: '716',
    'require-postal-code': false,
    'postal-code-sample': '',
    'zone-code': 'CAT',
    'time-zone-offset': '+02:00',
    'zone-name': 'Africa/Harare',
    hot: false,
    enable: true,
  },
];

export const COUNTRIES_SORTED = COUNTRIES.sort((a: any, b: any) => {
  if (a.name.en < b.name.en) {
    return -1;
  }
  if (a.name.en > b.name.en) {
    return 1;
  }
  return 0;
});

export const IOSS_EU_COUNTRIES_LIST: string[] = [
  'AT',
  'FR',
  'DE',
  'BE',
  'BG',
  'IT',
  'ES',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'GR',
  'HU',
  'IE',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'SE',
];

export const COUNTRIES_NAME_MAP: Partial<Record<Country, string>> = {
  AD: 'Andorra',
  AE: 'United Arab Emirates',
  AF: 'Afghanistan',
  AI: 'Anguilla',
  AL: 'Albania',
  AM: 'Armenia',
  AO: 'Angola',
  AR: 'Argentina',
  AS: 'American Samoa',
  AT: 'Austria',
  AU: 'Australia',
  AW: 'Aruba',
  AZ: 'Azerbaijan',
  BA: 'Bosnia-Herzegovina',
  BB: 'Barbados',
  BD: 'Bangladesh',
  BE: 'Belgium',
  BF: 'Burkina Faso',
  BG: 'Bulgaria',
  BH: 'Bahrain',
  BI: 'Burundi',
  BJ: 'Benin',
  BL: 'St. Barthelemy',
  BM: 'Bermuda',
  BN: 'Brunei',
  BO: 'Bolivia',
  BR: 'Brazil',
  BS: 'Bahamas',
  BT: 'Bhutan',
  BW: 'Botswana',
  BY: 'Belarus',
  BZ: 'Belize',
  CA: 'Canada',
  CD: 'Congo, Dem Rep Of',
  CG: 'Congo',
  CH: 'Switzerland',
  CI: 'Ivory Coast',
  CK: 'Cook Islands',
  CL: 'Chile',
  CM: 'Cameroon',
  CO: 'Colombia',
  CR: 'Costa Rica',
  CV: 'Cape',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DE: 'Germany',
  DJ: 'Djibouti',
  DK: 'Denmark',
  DM: 'Dominica',
  DO: 'Dominican Republic',
  DZ: 'Algeria',
  EC: 'Ecuador',
  EE: 'Estonia',
  EG: 'Egypt',
  ER: 'Eritrea',
  ES: 'Spain',
  ET: 'Ethiopia',
  FI: 'Finland',
  FJ: 'Fiji',
  FM: 'Micronesia',
  FR: 'France',
  GA: 'Gabon',
  GB: 'United Kingdom',
  GD: 'Grenada',
  GE: 'Georgia',
  GF: 'French Guiana',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GL: 'Greenland',
  GM: 'Gambia',
  GN: 'Guinea',
  GP: 'Guadeloupe',
  GQ: 'Guinea',
  GR: 'Greece',
  GT: 'Guatemala',
  GU: 'Guam',
  GY: 'Guyana',
  HK: 'Hong Kong',
  HN: 'Honduras',
  HR: 'Croatia',
  HT: 'Haiti',
  HU: 'Hungary',
  ID: 'Indonesia',
  IE: 'Ireland',
  IL: 'Israel',
  IN: 'India',
  IQ: 'Iraq',
  IS: 'Iceland',
  IT: 'Italy',
  JM: 'Jamaica',
  JO: 'Jordan',
  JP: 'Japan',
  KE: 'Kenya',
  KG: 'Kyrgyzstan',
  KH: 'Cambodia',
  KR: 'South Korea',
  KW: 'Kuwait',
  KY: 'Cayman',
  KZ: 'Kazakhstan',
  LB: 'Lebanon',
  LI: 'Liechtenstein',
  LK: 'Sri Lanka',
  LR: 'Liberia',
  LS: 'Lesotho',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  LV: 'Latvia',
  LY: 'Libya',
  MA: 'Morocco',
  MC: 'Monaco',
  MD: 'Moldova',
  ME: 'Montenegro',
  MG: 'Madagascar',
  MH: 'Marshall Islands',
  MK: 'Macedonia',
  ML: 'Mali',
  MM: 'Myanmar',
  MN: 'Mongolia',
  MO: 'Macao',
  MP: 'Northern Mariana Islands',
  MQ: 'Martinique',
  MR: 'Mauritania',
  MS: 'Montserrat',
  MT: 'Malta',
  MU: 'Mauritius',
  MV: 'Maldives',
  MW: 'Malawi',
  MX: 'Mexico',
  MY: 'Malaysia',
  MZ: 'Mozambique',
  NA: 'Namibia',
  NC: 'New Caledonia',
  NE: 'Niger',
  NF: 'Norfolk Island',
  NG: 'Nigeria',
  NI: 'Nicaragua',
  NL: 'Netherlands',
  NO: 'Norway',
  NP: 'Nepal',
  NZ: 'New Zealand',
  OM: 'Oman',
  PA: 'Panama',
  PE: 'Peru',
  PF: 'French Polynesia',
  PG: 'Papua New Guinea',
  PH: 'Philippines',
  PK: 'Pakistan',
  PL: 'Poland',
  PR: 'Puerto Rico',
  PT: 'Portugal',
  PW: 'Palau',
  PY: 'Paraguay',
  QA: 'Qatar',
  RE: 'Reunion',
  RO: 'Romania',
  RS: 'Serbia',
  RU: 'Russia',
  RW: 'Rwanda',
  SA: 'Saudi Arabia',
  SC: 'Seychelles',
  SD: 'Sudan',
  SE: 'Sweden',
  SG: 'Singapore',
  SI: 'Slovenia',
  SK: 'Slovak Republic',
  SL: 'Sierra Leone',
  SM: 'San Marino',
  SN: 'Senegal',
  SO: 'Somalia',
  SR: 'Suriname',
  SV: 'El Salvador',
  SY: 'Syria',
  SZ: 'Swaziland',
  TC: 'Turks & Caicos Islands',
  TD: 'Chad',
  TG: 'Togo',
  TH: 'Thailand',
  TN: 'Tunisia',
  TO: 'Tonga',
  TR: 'Turkey',
  TT: 'Trinidad & Tobago',
  TW: 'Taiwan',
  TZ: 'Tanzania',
  UA: 'Ukraine',
  UG: 'Uganda',
  US: 'United States',
  UY: 'Uruguay',
  UZ: 'Uzbekistan',
  VA: 'Vatican City',
  VE: 'Venezuela',
  VG: 'British Virgin Islands',
  VI: 'United States Virgin Islands',
  VN: 'Vietnam',
  VU: 'Vanuatu',
  WS: 'Samoa',
  YE: 'Yemen',
  ZA: 'South Africa',
  ZM: 'Zambia',
  ZW: 'Zimbabwe',
};
