import { IsEmail, IsString, MinLength } from 'class-validator';
import { Expose } from 'class-transformer';

export class Login {
  constructor(props: Partial<Login>) {
    Object.assign(this, props);
  }

  @Expose()
  @IsEmail({}, { message: 'Signin.errors.invalidEmailFormat' })
  email!: string;

  @Expose()
  @IsString()
  @MinLength(6, { message: 'Signin.errors.invalidPasswordFormat' })
  password!: string;
}
