import * as yup from 'yup';

const searchTextAreaSchema = yup
  .object()
  .shape({
    transactionIds: yup.string().required('SearchFilter.searchMultipleModal.emptyTextError'),
  })
  .defined();

export type searchTextAreaForm = yup.InferType<typeof searchTextAreaSchema>;

export default searchTextAreaSchema;
