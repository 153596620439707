import { apiService } from './api';
import { BoxType } from '../dto';
import { Pagination } from '../types';

interface Response extends Pagination {
  resources: BoxType[];
}

export async function listPackage(url?: string): Promise<Response> {
  try {
    const path = url || '/purchase-orders/saved-box-types';
    const res = await apiService({ method: 'get', path });
    const { success, data } = res.data || {};

    if (!success) throw 'Error';

    return data;
  } catch (err: any) {
    throw err?.response?.data ?? err;
  }
}

export async function createPackage(data: BoxType): Promise<BoxType | void> {
  try {
    const path = '/purchase-orders/saved-box-types';
    const res = await apiService({ method: 'post', path, data });
    const { success } = res.data || {};

    if (!success) throw 'Error';
  } catch (err: any) {
    throw err?.response?.data ?? err;
  }
}

export async function deletePackage(url: string): Promise<any> {
  try {
    const path = url;
    const res = await apiService({ method: 'delete', path });
    const { success } = res.data || {};

    if (!success) throw 'Error';
  } catch (err: any) {
    throw err?.response?.data ?? err;
  }
}

export async function updatePackage(url: string, data: BoxType): Promise<BoxType | void> {
  try {
    const path = url;
    const res = await apiService({ method: 'put', path, data });
    const { success } = res.data || {};

    if (!success) throw 'Error';
  } catch (err: any) {
    throw err?.response?.data ?? err;
  }
}
