import { IsDefined, IsOptional, IsString, IsBoolean, IsNumber, Length } from 'class-validator';
import { Expose } from 'class-transformer';
import { stringToCountry } from '../common/enums';

export class AddressBook {
  constructor(props: Partial<AddressBook>) {
    const { country } = props || {};
    Object.assign(this, {
      ...props,
      ...(country && { country: stringToCountry(country) }),
    });
  }

  @Expose()
  @IsNumber()
  id!: number;

  @Expose()
  @IsDefined()
  @IsString()
  @Length(1, 35)
  addressName!: string;

  @Expose()
  @IsBoolean()
  @IsOptional()
  isDefault?: boolean;

  @Expose()
  @IsBoolean()
  @IsOptional()
  isResidential?: boolean;

  @Expose()
  @IsDefined()
  @IsString()
  @Length(1, 35)
  name!: string;

  @Expose({ name: 'company' })
  @IsOptional()
  @IsString()
  companyName?: string;

  @Expose()
  @IsString()
  @IsDefined()
  phone!: string;

  @Expose()
  @IsDefined()
  @IsString()
  phoneCountryCode!: string;

  @Expose()
  @IsString()
  completePhone?: string;

  @Expose()
  @IsString()
  email?: string;

  @Expose()
  @IsDefined()
  @IsString()
  street1!: string;

  @Expose()
  @IsOptional()
  @IsString()
  street2?: string;

  @Expose()
  @IsOptional()
  @IsString()
  street3?: string;

  @Expose()
  @IsDefined()
  @IsString()
  city!: string;

  @Expose()
  @IsOptional()
  @IsString()
  state?: string;

  @Expose()
  @IsString()
  @IsDefined()
  country!: string;

  @Expose()
  countryName?: Record<string, string>;

  @Expose()
  @IsOptional()
  @IsString()
  postalCode?: string;
}
