import React, { createContext, useReducer, useContext, useMemo } from 'react';

import alertReducer, { initState } from './reducer';
import * as alertActions from './actions';
import Alert from './component';
import { Dispatch } from '../types';

export enum AlertLevels {
  'info' = 'info',
  'error' = 'error',
  'success' = 'success',
}
export interface AlertState {
  level: AlertLevels | string;
  message?: string | React.ReactNode | React.ReactElement | React.ReactChildren;
  onClose?: () => void;
  delay?: number;
}

const AlertContext = createContext<[AlertState, Dispatch]>([initState, () => {}]);

export const AlertProvider: React.FC = ({ children }) => {
  const value = useReducer(alertReducer, initState);
  return (
    <AlertContext.Provider value={value}>
      <Alert />
      {children}
    </AlertContext.Provider>
  );
};

export const useAlert = (): [
  AlertState,
  { showAlert: (payload: AlertState) => void; closeAlert: () => void },
] => {
  const context = useContext(AlertContext);

  if (context === undefined) {
    throw new Error('[Alert] useAlert must be used within AlertProvider.');
  }

  const [state, dispatch] = context;
  const dispatchActions = useMemo(
    () => ({
      showAlert: alertActions.show(dispatch),
      closeAlert: alertActions.close(dispatch),
    }),
    [dispatch],
  );

  return [state, dispatchActions];
};

export default useAlert;
