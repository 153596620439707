import { css } from '@linaria/core';
import { styled } from '@linaria/react';
import { colors } from '@spaceshipapp/cambridge';

export const subTextStyle = css`
  color: ${colors.subtext};
`;

export const refundTextStyle = css`
  color: ${colors.success};
`;

export const symbolStyle = css`
  margin: 0;
  &[data-valid='invalid'] {
    &:before {
      content: '-';
    }
  }
`;

export const textPadding = css`
  margin-left: 1rem;
`;

export const btnStyle = css`
  height: 100%;
  width: 100%;
  margin: 0.5rem 0;
  text-decoration: none;
`;

export const modalStyle = css`
  width: 76.6875rem;
  padding: 1rem 0.5rem 0;
  background-color: ${colors.surface};
`;

export const ModalWrapper = styled.div`
  background-color: ${colors.surface};
`;
