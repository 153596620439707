import {
  IsArray,
  IsDefined,
  IsEnum,
  IsOptional,
  IsString,
  IsUrl,
  ValidateNested,
} from 'class-validator';
import { Expose, Transform, Type } from 'class-transformer';
import { Address, Package, Pickup, Money, Tracking } from '../dto';
import { LogisticProviderCode, LogisticServiceCode, OrderStatus } from '../common/enums';
import { ParserError } from '../common/errors';

export const translateOrderStatus = (code: number | string): OrderStatus => {
  switch (code) {
    case -1:
      return OrderStatus.CANCEL;
    case 0:
      return OrderStatus.PENDING;
    case 1:
      return OrderStatus.SUCCESS;
    case 2:
      return OrderStatus.COMPLETE;
    case 3:
      return OrderStatus.PAYING;
    case 4:
      return OrderStatus.HOLD;
    case 5:
      return OrderStatus.RETURN;
    default:
      return code as OrderStatus;
  }
};

export class Shipment {
  constructor(props: Partial<Shipment>) {
    Object.assign(this, props);
  }

  @Expose({ name: 'ref' })
  @IsString()
  id!: string;

  @Expose()
  @IsString()
  shipmentRef!: string;

  @Expose()
  @IsString()
  orderNumber!: string;

  @Expose()
  @IsString()
  trackingNumber!: string;

  @Expose()
  @IsString()
  trackingUrl!: string;

  @Expose()
  @IsUrl()
  labelUrl!: string;

  @Expose()
  @IsEnum(LogisticProviderCode)
  logisticProvider: string;

  @Expose()
  @IsEnum(LogisticServiceCode)
  logisticServiceCode: LogisticServiceCode;

  @Expose({ name: 'toAddress' })
  @IsDefined()
  @Type(() => Address)
  @ValidateNested()
  receiverAddress!: Address;

  @Expose({ name: 'fromAddress' })
  @IsDefined()
  @Type(() => Address)
  @ValidateNested()
  shipperAddress!: Address;

  @Expose({ name: 'boxes' })
  @IsArray()
  @Type(() => Package)
  packages: Package[] = [];

  @Expose()
  @IsDefined()
  @Type(() => Pickup)
  @ValidateNested()
  pickup: Pickup;

  @Expose()
  @IsEnum(OrderStatus)
  @Transform(({ value }) => translateOrderStatus(value), { toClassOnly: true })
  status: OrderStatus;

  @Expose()
  @IsOptional()
  totalPackages?: number;

  @Expose()
  totalWeight!: number;

  @Expose()
  chargeableWeight: number;

  @Expose()
  createdAt: Date;

  @Expose()
  lastUpdate: Date;

  @Expose()
  updatedAt: Date;

  @Expose()
  deliveryDate?: Date;

  @Expose()
  minDeliveryDays?: number;

  @Expose()
  maxDeliveryDays?: number;

  @Expose({ name: 'finalTotal' })
  @Type(() => Money)
  totalCharge: Money;

  @Expose()
  @Type(() => Money)
  firstRate: Money;

  @Expose()
  @Type(() => Money)
  chargeDifference: Money;

  @Expose({ name: 'returnFee' })
  @Type(() => Money)
  refund: Money;

  @Expose()
  @IsOptional()
  metadata?: Record<string, any>;

  @Expose()
  @Type(() => Tracking)
  @ValidateNested()
  tracking: Tracking;

  errors: ParserError[] = [];
}
