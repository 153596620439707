import { deserializeArray, deserialize } from 'class-transformer';
import format from 'date-fns/format';

import { Shipment, BulkPickup, Timeslot, AddressBook, Pickup } from '../dto';
import { ShipmentStatus } from '../common/enums';
import { getMoney } from '../utils/helpers';
import { PaginationMeta } from '../types';
import { apiService } from './api';

export interface PaginatedShipments {
  meta: PaginationMeta;
  shipments: Shipment[];
}

export interface GetShipmentsInput {
  page: number;
  orderStatus?: ShipmentStatus;
  keyword?: string;
  perPage: boolean | number;
  from?: string;
  to?: string;
  transactionIds?: string[];
}

export interface AdjustmentPayload {
  orderId: string;
  shipmentRef: string;
}

export async function getShipments(payload: GetShipmentsInput): Promise<PaginatedShipments> {
  const { page, keyword, orderStatus, perPage, from, to, transactionIds } = payload;

  const { data } = await apiService({
    method: 'get',
    path: '/purchase-orders/orders',
    params: {
      page,
      keyword,
      perPage,
      from,
      to,
      orderStatus,
      transactionIds,
    },
  });

  if (data?.data) {
    const { meta, resources } = data.data;
    const shipments = (resources || []).map(parseShipmentFromOrder);
    return {
      meta,
      shipments: deserializeArray(Shipment, JSON.stringify(shipments)),
    };
  }

  throw new Error('[Shipments] Fail to get shipments');
}

export async function getPickupTimeslots(shipmentIds: string[]): Promise<BulkPickup[]> {
  const { data } = await apiService({
    method: 'post',
    path: '/orders/bulk/available-pickup-time',
    data: {
      orders: shipmentIds,
    },
  });

  if (data?.data) {
    const pickups = (data.data || []).map(parseBulkPickup);
    return deserializeArray(BulkPickup, JSON.stringify(pickups));
  }

  if (data?.message) {
    throw new Error(data?.message);
  }

  throw new Error('[Pickup] Fail to get timeslots');
}

interface CreatePickupInput extends Timeslot {
  shipmentIds: string[];
  type: string;
  serviceProvider: string;
  address: AddressBook;
  withOuterCollectionBags: boolean;
  numberOfBags: number;
}

export async function createPickup(input: CreatePickupInput): Promise<Pickup> {
  const pickupData = {
    provider: input.serviceProvider,
    orders: input.shipmentIds,
    type: input.type,
    date: input.date,
    minTime: input.from,
    maxTime: input.to,
    address: convertAddress(input.address),
    withOuterCollectionBags: input.withOuterCollectionBags,
    numberOfBags: input.numberOfBags,
  };

  const { data } = await apiService({
    method: 'post',
    path: '/orders/bulk/create-pickup',
    data: pickupData,
  });

  if (data?.data) {
    const pickup = parsePickup(data.data);
    return deserialize(Pickup, JSON.stringify(pickup));
  }

  if (data?.errors) {
    throw new Error(data?.message);
  }

  throw new Error('[Pickups] Fail to create pickup');
}

export interface GetPickupsInput {
  page?: number;
  from?: Date;
  to?: Date;
  perPage?: number;
}

interface PaginatedPickups {
  meta: PaginationMeta;
  pickups: Pickup[];
}

export async function getPickups(input: GetPickupsInput): Promise<PaginatedPickups> {
  const { page = 1, from, to, perPage } = input;
  const { data } = await apiService({
    method: 'get',
    path: '/pickups',
    params: {
      page,
      types: ['NORMAL', 'FIRST_MILE'],
      from: from && format(from, 'yyyy-MM-dd'),
      to: to && format(to, 'yyyy-MM-dd'),
      perPage,
    },
  });

  if (data?.data) {
    const pickups = (data?.data?.resources || []).map(parsePickup);
    return {
      meta: data.data.meta,
      pickups: deserializeArray(Pickup, JSON.stringify(pickups)),
    };
  }

  throw new Error('[Pickups] Fail to get pickups');
}

export interface GetShipmentsCountInput {
  keyword?: string;
}

export interface ShipmentsCount {
  status: ShipmentStatus | null;
  count: number;
}

export async function getShipmentsCount(params: GetShipmentsCountInput): Promise<ShipmentsCount[]> {
  const { data } = await apiService({
    method: 'get',
    path: '/purchase-orders/orders/count',
    params,
  });

  if (data?.data) {
    return data?.data?.resources || [];
  }

  throw new Error('[Pickups] Fail to get shipments count');
}

export async function approveAdjustment(payload: AdjustmentPayload): Promise<Shipment> {
  const { orderId, shipmentRef } = payload;
  const adjustmentData = {
    shipmentRef,
    confirm: true,
  };
  const path = `/orders/${orderId}/confirm-adjustment`;

  const { data } = await apiService({
    method: 'post',
    path,
    data: adjustmentData,
  });

  if (data?.data) {
    const shipment = parseShipmentFromOrder(data.data);
    return deserialize(Shipment, JSON.stringify(shipment));
  }

  if (data?.errors) {
    throw new Error(data?.message);
  }

  throw new Error('[Adjustment] Fail to approve adjustment');
}

export async function rejectAdjustment(payload: AdjustmentPayload): Promise<Shipment> {
  const { orderId, shipmentRef } = payload;
  const adjustmentData = {
    shipmentRef,
    confirm: false,
  };
  const path = `/orders/${orderId}/confirm-adjustment`;

  const { data } = await apiService({
    method: 'post',
    path,
    data: adjustmentData,
  });

  if (data?.data) {
    const shipment = parseShipmentFromOrder(data.data);
    return deserialize(Shipment, JSON.stringify(shipment));
  }

  if (data?.errors) {
    throw new Error(data?.message);
  }

  throw new Error('[Adjustment] Fail to reject adjustment');
}

const parseTrackingUrl = (shipment: any) => {
  return generateTrackingUrl(shipment.trackingNumber);
};

export const generateTrackingUrl = (trackingNumber: string): string => {
  const template = process.env.REACT_APP_PUBLIC_TRACKING_URL || '';
  return template.replace('{{tracking_number}}', trackingNumber);
};

const parseShipmentFromOrder = (order: any) => {
  const shipment = order?.shipments?.[0];
  const pickup = order?.pickup;
  const logisticProvider = shipment?.carrierService?.carrier;
  const logisticServiceCode = shipment?.carrierService?.slug;

  const data = {
    ...order,
    id: order.ref,
    orderNumber: shipment.metadata?.orderNumber,
    shipmentRef: shipment.shipmentRef,
    boxes: shipment.boxes,
    metadata: shipment.metadata,
    logisticProvider,
    logisticServiceCode,
    pickup: pickup ? parsePickup(pickup) : null,
    fromAddress: shipment.fromAddress,
    toAddress: shipment.toAddress,
    totalPackages: shipment.totalBox,
    totalWeight: shipment.totalWeight,
    tracking: shipment.tracking,
    trackingNumber: shipment.trackingNumber,
    trackingUrl: parseTrackingUrl(shipment),
    deliveryDate: shipment.deliveryDate,
    minDeliveryDays: shipment.minDeliveryDays,
    maxDeliveryDays: shipment.maxDeliveryDays,
    refund: order.returnFee ? getMoney(order.returnFee) : null,
    firstRate: shipment.firstRate ? getMoney(shipment.firstRate) : null,
    finalTotal: shipment.totalRate ? getMoney(shipment.totalRate) : null,
    chargeDifference: order.chargeDifference ? getMoney(order.chargeDifference) : null,
    chargeableWeight: Number(shipment.chargeableWeight),
  };

  return data;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const parseShipment = (shipment: any) => {
  const logisticProvider = shipment?.carrierService?.carrier;
  const logisticServiceCode = shipment?.carrierService?.slug;

  const data = {
    ...shipment,
    id: shipment.ref,
    boxes: shipment.boxes,
    metadata: shipment.metadata,
    logisticProvider,
    logisticServiceCode,
    fromAddress: shipment.fromAddress,
    toAddress: shipment.toAddress,
    totalPackages: shipment.totalBox,
    totalWeight: shipment.totalWeight,
    trackingNumber: shipment.trackingNumber,
    trackingUrl: parseTrackingUrl(shipment),
    finalTotal: shipment.totalRate ? getMoney(shipment.totalRate) : null,
  };

  return data;
};

const parsePickup = (input: any) => {
  const data = {
    ...input,
    shipments: input?.shipments?.map(parseShipment) ?? [],
    serviceProvider: input?.firstMileProvider?.slug || input?.carrier?.slug,
  };

  return data;
};

const parseBulkPickup = (input: any) => {
  return {
    ...input,
    timeslots: parseTimeslots(input.timeSlot),
  };
};

const parseTimeslots = (input: any) => {
  return input.reduce((acc: any, curr: any) => {
    const times = (curr.time || []).map((time: any) => ({
      date: curr.date,
      ...time,
    }));
    return [...acc, ...times];
  }, []);
};

const convertAddress = (address?: AddressBook) => {
  if (!address) {
    return {};
  }

  const { street1, street2, street3, phone, phoneCountryCode, ...remain } = address;
  return {
    phone,
    phoneCountryCode,
    street1,
    street2,
    street3,
    ...remain,
  };
};
