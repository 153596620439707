import {
  IsDefined,
  IsOptional,
  IsString,
  IsBoolean,
  Length,
  Matches,
  MaxLength,
} from 'class-validator';
import { Expose } from 'class-transformer';

const PHONE_REGEX = /^[0-9]{8}$/;
const ADDRESS_REGEX = /^[0-9A-Za-z\s\-.,/'"():;$&@!?]+$/;

export class AddressBookCreate {
  constructor(props: Partial<AddressBookCreate>) {
    Object.assign(this, props);
  }

  @IsDefined({ message: 'AddressError.addressName.required' })
  @IsString()
  @Length(1, 35)
  addressName!: string;

  @IsBoolean()
  @IsOptional()
  isDefault?: boolean;

  @IsBoolean()
  @IsOptional()
  isResidential?: boolean;

  @IsDefined({ message: 'AddressError.name.required' })
  @IsString()
  @Length(1, 35)
  name!: string;

  @Expose({ name: 'companyName' })
  @IsOptional()
  @IsString()
  company?: string;

  @IsString()
  @IsDefined({ message: 'AddressError.phone.required' })
  @Matches(PHONE_REGEX, {
    message: 'AddressError.phone.contains-characters-other-than-number',
  })
  phone!: string;

  @Expose()
  @IsDefined()
  @IsString()
  phoneCountryCode!: string;

  @Expose()
  @IsOptional()
  @IsString()
  email?: string;

  @Expose()
  @IsDefined({ message: 'AddressError.street1.required' })
  @IsString()
  @Length(1, 32)
  @Matches(ADDRESS_REGEX, {
    message: 'AddressError.street1.length-cannot-be-more-than-32',
  })
  street1!: string;

  @Expose()
  @IsOptional()
  @IsString()
  @MaxLength(32)
  @Matches(ADDRESS_REGEX, {
    message: 'AddressError.street2.length-cannot-be-more-than-32',
  })
  street2?: string;

  @Expose()
  @IsOptional()
  @IsString()
  @MaxLength(32)
  @Matches(ADDRESS_REGEX, {
    message: 'AddressError.street3.length-cannot-be-more-than-32',
  })
  street3?: string;

  @IsDefined({ message: 'AddressError.city.required' })
  @IsString()
  city!: string;

  @IsOptional()
  @IsString()
  state?: string;

  @IsDefined()
  @IsString()
  country!: string;

  @Expose()
  @IsOptional()
  @IsString()
  postalCode?: string;
}
