import { apiService } from './api';
import { User } from '../contexts';

export async function getInfo(): Promise<User> {
  try {
    const res = await apiService({
      method: 'get',
      path: '/customer/info',
    });
    const { success, data, message } = res.data;
    if (!success) throw new Error(message);

    return data;
  } catch (err: any) {
    return Promise.reject(err?.response?.data);
  }
}

export async function updateIsMergeLabels(enable: string): Promise<User> {
  try {
    const res = await apiService({
      method: 'put',
      path: `/customer/is-merge-labels/${enable}`,
    });
    const { success, data, message } = res.data;
    if (!success) throw new Error(message);

    return data;
  } catch (err: any) {
    return Promise.reject(err?.response?.data);
  }
}
