import React from 'react';
import { styled } from '@linaria/react';
import { useTranslation } from 'react-i18next';

const RootWrapper = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fcc269;
  z-index: 110;
`;

const SwitchToNewVersionBanner: React.FC = () => {
  const { t } = useTranslation();

  return (
    <RootWrapper>
      <div>{t('SwitchToNewVersionBanner.description')}</div>
      <a href={t('SwitchToNewVersionBanner.url')}>
        {t('SwitchToNewVersionBanner.buttonSwitchToNewVersion')}
      </a>
    </RootWrapper>
  );
};

export default SwitchToNewVersionBanner;
