import { createModel } from '@rematch/core';
import { Shipment } from '../dto';
import type { RootModel } from './index';
import { PaginationMeta } from '../types';
import {
  getShipments,
  getShipmentsCount,
  GetShipmentsInput,
  PaginatedShipments,
  ShipmentsCount,
  GetShipmentsCountInput,
  approveAdjustment,
  AdjustmentPayload,
  rejectAdjustment,
} from '../services';
import { RootState } from '../stores';
import { ResultType } from '../containers/Shipments/AdjustmentModals/AdjustmentResult';
import { ShipmentStatus } from '../common/enums';
import { getShipmentStatus } from '../utils/helpers';

export type ShipmentsState = {
  selectedIds: Shipment['id'][];
  selectedShipments: Shipment[];
  page: string[];
  byId: Record<string, Shipment>;
  meta: Partial<PaginationMeta>;
  counts: ShipmentsCount[];
  currentTab?: ShipmentStatus;
  adjustmentSuccessType?: ResultType;
};

function normalize<T extends { id: string }>(payload: T[]) {
  return payload.reduce<Record<string, T>>((obj, item) => {
    obj[item.id] = item;
    return obj;
  }, {});
}

export const shipments = createModel<RootModel>()({
  state: {
    selectedIds: [],
    selectedShipments: [],
    page: [],
    byId: {},
    meta: {},
    counts: [],
  } as ShipmentsState,
  reducers: {
    read: (state, payload: PaginatedShipments) => {
      const { meta, shipments } = payload;

      if (
        shipments[0] &&
        state.currentTab &&
        state.currentTab !== ShipmentStatus.ALL &&
        getShipmentStatus(shipments[0]) !== state.currentTab
      )
        return { ...state };

      return {
        ...state,
        meta,
        page: shipments.map(({ id }) => id),
        byId: {
          ...state.byId,
          ...normalize<Shipment>(shipments),
        },
      };
    },
    adjustmentUpdate: (
      state,
      payload: { shipment: Shipment; adjustmentSuccessType: ResultType },
    ) => {
      const { byId, selectedIds, selectedShipments, page } = state;
      const newById = { ...byId };
      delete newById[payload.shipment.id];
      const newPage = page.filter((poId) => !payload.shipment.id.includes(poId));
      const newSelectedIds = selectedIds.filter((poId) => !payload.shipment.id.includes(poId));
      const newSelectedShipments = selectedShipments.filter(
        (shipment) => !payload.shipment.id.includes(shipment.id),
      );
      return {
        ...state,
        byId: newById,
        page: newPage,
        selectedIds: newSelectedIds,
        selectedShipments: newSelectedShipments,
        adjustmentSuccessType: payload.adjustmentSuccessType,
      };
    },
    shipmentsSelect: (state: ShipmentsState, ids: string[]) => {
      return {
        ...state,
        selectedIds: [...ids],
      };
    },
    shipmentsToggled: (
      state: ShipmentsState,
      { ids, onOff }: { ids: string[]; onOff: boolean },
    ) => {
      const set = new Set(state.selectedIds);
      if (onOff) {
        ids.forEach(set.add, set);
      } else {
        ids.forEach(set.delete, set);
      }
      return {
        ...state,
        selectedIds: Array.from(set),
      };
    },
    shipmentsCountUpdated: (state: ShipmentsState, counts: ShipmentsCount[]) => {
      return {
        ...state,
        counts,
      };
    },
    currentTabUpdated: (state: ShipmentsState, currentTab: ShipmentStatus) => {
      return {
        ...state,
        currentTab,
      };
    },
    removeAdjustmentResult: (state: ShipmentsState) => {
      const newState = { ...state };
      delete newState.adjustmentSuccessType;
      return {
        ...newState,
      };
    },
  },
  effects: (dispatch) => ({
    async getShipments(payload: GetShipmentsInput): Promise<void> {
      const results = await getShipments(payload);
      dispatch.shipments.read(results);
    },
    async getShipmentsCount(payload: GetShipmentsCountInput): Promise<void> {
      const results = await getShipmentsCount(payload);
      dispatch.shipments.shipmentsCountUpdated(results);
    },
    async approveAdjustment(payload: AdjustmentPayload): Promise<void> {
      const result = await approveAdjustment(payload);
      dispatch.shipments.adjustmentUpdate({
        shipment: result,
        adjustmentSuccessType: ResultType.PAYMENT_SUCCESS,
      });
    },
    async rejectAdjustment(payload: AdjustmentPayload): Promise<void> {
      const result = await rejectAdjustment(payload);
      dispatch.shipments.adjustmentUpdate({
        shipment: result,
        adjustmentSuccessType: ResultType.SHIPMENT_CANCEL,
      });
    },
    removeAdjustmentSuccessType() {
      dispatch.shipments.removeAdjustmentResult();
    },
    updateCurrentTab(currentTab: ShipmentStatus) {
      dispatch.shipments.currentTabUpdated(currentTab);
    },
  }),
});

export const selectShipments = (state: RootState): Shipment[] => {
  const { page, byId } = state.shipments;
  return page.map((id) => byId[id]);
};

export const selectShipmentsMeta = (state: RootState): Partial<PaginationMeta> => {
  return state.shipments.meta;
};

export const selectShipmentSelectedIds = (state: RootState): Shipment['id'][] => {
  return state.shipments.selectedIds;
};

export const selectAllShipments = (state: RootState): Shipment[] => {
  const { byId } = state.shipments;
  return Object.values(byId);
};

export const selectShipmentsCount = (state: RootState): ShipmentsCount[] => {
  return state.shipments.counts;
};

export const selectAdjustmentSuccessType = (state: RootState): ResultType | undefined => {
  return state.shipments.adjustmentSuccessType;
};
