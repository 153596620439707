import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Route, RouteProps } from 'react-router-dom';
import { RootState } from '../../stores';
import Layout from '../Layout';
import Loading from './Loading';
import { Redirect } from '../../components/Link';

const PrivateRoute: React.FC<RouteProps> = ({ component: Component, path }) => {
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  return (
    <Layout>
      <Suspense fallback={<Loading />}>
        <Route component={Component} path={path} />
      </Suspense>
    </Layout>
  );
};

export default PrivateRoute;
