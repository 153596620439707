import { IsString } from 'class-validator';
import { Expose } from 'class-transformer';

export class Translation {
  constructor(props: Partial<Translation>) {
    Object.assign(this, props);
  }

  @Expose()
  @IsString()
  en!: string;

  @Expose()
  @IsString()
  zh!: string;
}
