import 'reflect-metadata';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { css } from '@linaria/core';
import { Provider } from 'react-redux';
import { breakpoints, colors } from '@spaceshipapp/cambridge';
import '@spaceshipapp/cambridge/dist/cambridge.css';

import { AlertProvider } from './contexts/alert';
import reportWebVitals from './reportWebVitals';
import { store } from './stores';
import App from './containers/App';

import './i18n';
import './pdf';

export const globals = css`
  :global() {
    #root {
      white-space: pre-line;
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .public {
      background-color: ${colors.brandSecondary};
    }

    .container {
      width: 100%;
      margin-right: auto;
      margin-left: auto;
      padding-right: 1rem;
      padding-left: 1rem;

      @media (min-width: ${breakpoints.sm}) {
        max-width: ${breakpoints.sm};
      }

      @media (min-width: ${breakpoints.md}) {
        max-width: ${breakpoints.md};
      }

      @media (min-width: ${breakpoints.lg}) {
        max-width: ${breakpoints.lg};
      }

      @media (min-width: ${breakpoints.xl}) {
        max-width: ${breakpoints.xl};
      }

      /* override css of Modal */
      div[role='dialog'] {
        padding: 0;
        overflow-y: auto !important;
      }
    }
  }
`;

ReactDOM.render(
  <React.StrictMode>
    <AlertProvider>
      <Router>
        <Provider store={store}>
          <Suspense fallback="">
            <App />
          </Suspense>
        </Provider>
      </Router>
    </AlertProvider>
    <div id="modal" />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
