import { IsEnum, IsNumber, IsOptional, IsString, Min } from 'class-validator';
import { Expose, Type } from 'class-transformer';
import { Currency, IncotermType, PurposeType } from '../common/enums';

export class BulkEditForm {
  constructor(props: Partial<BulkEditForm>) {
    Object.assign(this, props);
  }

  @Expose()
  @IsOptional()
  @IsEnum(Currency)
  currency?: Currency;

  @Expose()
  @IsOptional()
  @IsEnum(PurposeType)
  type?: PurposeType;

  @Expose()
  @IsOptional()
  @IsEnum(IncotermType)
  incoterm?: IncotermType;

  @Expose()
  @IsOptional()
  @IsString()
  logisticServiceCode?: string;

  @Expose()
  @IsOptional()
  @IsNumber()
  shipperAddressId?: number;

  @Expose()
  @IsOptional()
  @IsNumber()
  boxPresetId?: number;

  @Expose()
  @IsOptional()
  @IsNumber()
  @Type(() => Number)
  @Min(0.1, { message: 'PackageCardError.weight.minimum' })
  unitWeight?: number;
}
