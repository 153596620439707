import { Expose, Type } from 'class-transformer';
import { IsEnum, IsNumber, IsArray } from 'class-validator';
import { PurchaseOrder } from './PurchaseOrder.dto';
import { ShipmentEventStatus } from '../common/enums';

export class ShipmentEventResponse {
  constructor(props: Partial<ShipmentEventResponse>) {
    Object.assign(this, props);
  }

  @Expose()
  @IsNumber()
  id!: number;

  @IsArray()
  @Type(() => PurchaseOrder)
  purchaseOrders?: PurchaseOrder[] = [];

  @Expose()
  @IsEnum(ShipmentEventStatus)
  status!: ShipmentEventStatus;
}
