import { plainToInstance } from 'class-transformer';
import { apiService } from './api';
import { PaymentMethod } from '../common/enums';
import { StripeResponse } from '../dto';

export async function getPaymentInfoByMethod(method: PaymentMethod): Promise<StripeResponse> {
  try {
    const path = `/customer/method/${method}`;
    const res = await apiService({
      method: 'get',
      path,
    });
    const { data, success } = res?.data;

    if (data && success) {
      return plainToInstance(StripeResponse, data as StripeResponse);
    } else {
      throw new Error('Error getting payment info');
    }
  } catch (err) {
    throw err;
  }
}

export const addStripePaymentMethod = async (token: string): Promise<StripeResponse> => {
  try {
    const path = `/customer/method/${PaymentMethod.STRIPE}/update`;
    const res = await apiService({
      method: 'post',
      path,
      data: { token },
    });
    const { data, success } = res?.data;

    if (data && success) {
      return plainToInstance(StripeResponse, data as StripeResponse);
    } else {
      throw new Error('Error adding stripe payment');
    }
  } catch (err) {
    throw err;
  }
};
