import { deserialize } from 'class-transformer';
import { parseShipment } from './shipments';
import { Shipment } from '../dto';
import { apiService } from './api';

export const getShipmentByTrackingNumber = async (trackingNumber: string): Promise<Shipment> => {
  const { data } = await apiService({
    method: 'get',
    path: `/tracking/${trackingNumber}`,
  });
  if (!data.success) throw new Error(data.message);

  const shipment = parseShipment(data.data);
  return deserialize(Shipment, JSON.stringify(shipment));
};
