import { styled } from '@linaria/react';
import { colors } from '@spaceshipapp/cambridge';
import { Table } from '../../../components/Table';

interface WrapperProps {
  isModalElement?: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  padding: ${({ isModalElement }) => (isModalElement ? `0.5rem 1rem 1rem` : `0.5rem 0 0 0`)};
  background-color: ${colors.surface};
`;

export const StyledTable = styled(Table)<WrapperProps>`
  th,
  td {
    width: 12.5%;
  }
  th:nth-of-type(3),
  td:nth-of-type(3),
  th:nth-of-type(4),
  td:nth-of-type(4),
  th:nth-of-type(5),
  td:nth-of-type(5),
  th:nth-of-type(7),
  td:nth-of-type(7) {
    width: 16%;
  }

  td {
    vertical-align: middle;
  }
  margin-bottom: ${({ isModalElement }) => (isModalElement ? `1rem` : `0`)};
`;
