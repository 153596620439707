import { styled } from '@linaria/react';
import { rgba } from 'polished';
import { colors } from '@spaceshipapp/cambridge';

const { border, subtext, text } = colors;
const tablePaddingX = '0.375rem';
const tablePaddingY = '0.5rem';

interface TableProps {
  hoverable?: boolean;
}

export const Table = styled.table<TableProps>`
  width: 100%;

  tbody {
    tr:hover {
      background-color: ${(props) => (props.hoverable ? colors.brandSecondary : 'transparent')};
    }
  }
`;

export const TableHeader = styled.thead`
  color: ${subtext};
  font-size: 0.875rem;
  font-weight: normal;
  text-align: left;
`;

export const TH = styled.th`
  padding: ${tablePaddingY} ${tablePaddingX};
  border-bottom: 1px solid ${border};
`;

export const TBody = styled.tbody`
  td {
    vertical-align: top;
    padding: ${tablePaddingY} ${tablePaddingX};
  }

  .right {
    text-align: right;
  }

  .xs-text {
    color: ${subtext};
    font-size: 0.75rem;
  }

  .details {
    div {
      margin-bottom: 0.5rem;
    }

    span {
      display: block;
    }

    .meta {
      margin-bottom: 0.25rem;
      color: ${rgba(subtext, 0.75)};
      font-size: 0.875rem;
    }
  }
`;

export const TFoot = styled.tfoot`
  td {
    border-top: 1px solid ${text};
    vertical-align: top;
    padding: ${tablePaddingY} ${tablePaddingX};
  }
`;
