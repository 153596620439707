import { Expose, Transform } from 'class-transformer';
import { IsString } from 'class-validator';

export class StripeResponse {
  constructor(props: Partial<StripeResponse>) {
    Object.assign(this, props);
  }

  @Expose()
  @IsString()
  brand!: string;

  @Expose()
  @IsString()
  funding!: string;

  @Expose()
  @IsString()
  last4!: string;

  @Expose()
  @IsString()
  @Transform(({ value }) =>
    typeof value === 'number' ? (value >= 10 ? value.toString() : `0${value}`) : value,
  )
  expMonth!: string;

  @Expose()
  @IsString()
  @Transform(({ value }) => (typeof value === 'number' ? value.toString().substr(-2) : value))
  expYear!: string;
}
