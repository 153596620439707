export enum TrackingTag {
  TAG_PENDING = 'pending',
  INFO_RECEIVED = 'info-received',
  IN_TRANSIT = 'in-transit',
  OUT_FOR_DELIVERY = 'out-for-delivery',
  ATTEMPT_FAIL = 'attempt-fail',
  DELIVERED = 'delivered',
  EXCEPTION = 'exception',
  EXPIRED = 'expired',
  AVAILABLE_FOR_PICKUP = 'available-for-pickup',
  FIRST_MILE_DELIVERD = 'first-mile-delivered',
  FIRST_MILE_INFO_REVEIVED = 'first-mile-info-received',

  TAG_PPS_PARCEL_SCANNED = 'parcel-scanned',
  TAG_PPS_SHIPMENT_RETURNED = 'shipment-returned',
}
