export enum LogisticServiceCode {
  'APG_PREMIUM' = 'apg-premium',
  'ARAMEX_PDX' = 'aramex-pdx',
  'ARAMEX_PPX' = 'aramex-ppx',
  'ARAMEX_DPX' = 'aramex-dpx',
  'ARAMEX_EPX' = 'aramex-epx',
  'CHECKOUT_PREMIUM' = 'checkout-premium',
  'DHL_EXPRESS' = 'dhl-express',
  'DHL_EXPRESS_DOCUMENT' = 'dhl-express_document',
  'FEDEX_IE' = 'fedex-ie',
  'FEDEX_IP' = 'fedex-ip',
  'FEDEX_IEF' = 'fedex-ief',
  'FEDEX_IPF' = 'fedex-ipf',
  'FEDEX_IE_DOCUMENT' = 'fedex-ie_document',
  'FEDEX_IP_DOCUMENT' = 'fedex-ip_document',
  'SPEEDPOST_SPEED_POST' = 'speedpost-speed_post',
  'SPEEDPOST_E_EXPRESS' = 'speedpost-e_express',
  'SPEEDPOST_AIR_REGISTERED_MAIL' = 'speedpost-air_registered_mail',
  'SPEEDPOST_AIR_PARCEL' = 'speedpost-air_parcel',
  'TNT_EXPRESS' = 'tnt-express',
  'TNT_ECONOMY_EXPRESS' = 'tnt-economy_express',
  'TNT_EXPRESS_DOCUMENT' = 'tnt-express_document',
  'UPS_EXPEDITED' = 'ups-expedited',
  'UPS_SAVER' = 'ups-saver',
  'UPS_WORLDWIDE_EXPEDITED' = 'ups-worldwide_expedited',
  'UPS_WORLDWIDE_EXPRESS' = 'ups-worldwide_express',
  'UPS_WORLDWIDE_EXPRESS_PLUS' = 'ups-worldwide_express_plus',
  'UPS_SAVER_DOCUMENT' = 'ups-saver_document',
  'UPS_WORLDWIDE_EXPRESS_DOCUMENT' = 'ups-worldwide_express_document',
  'UPS_WORLDWIDE_EXPRESS_PLUS_DOCUMENT' = 'ups-worldwide_express_plus_document',
}
