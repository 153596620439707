import { Expose } from 'class-transformer';
import { IsString } from 'class-validator';

export class Timeslot {
  constructor(props: Partial<Timeslot>) {
    Object.assign(this, props);
  }

  @Expose()
  @IsString()
  date!: string;

  @Expose()
  @IsString()
  from!: string;

  @Expose()
  @IsString()
  to!: string;
}
