import { createModel } from '@rematch/core';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { FileMeta, createInputWithMeta, createZipFile } from '../utils/file';
import { PurchaseOrder, PurchaseOrderCreate, PurchaseOrderUpdate } from '../dto';
import {
  createPurchaseOrder,
  getPurchaseOrders,
  bulkUpdatePurchaseOrders,
  getShipmentEvent,
  GetPaginatedPurchaseOrdersInput,
  getPurchaseOrdersByIds,
  updatePurchaseOrder,
  createPurchaseOrdersPayment,
  BulkEditPayload,
  getPurchaseOrder,
  bulkCreatePurchaseOrdersQuotation,
  removePurchaseOrder as removePurchaseOrders,
} from '../services/purchaseOrders';
import { ShipmentEventStatus } from '../common/enums';
import { Pagination } from '../types';
import { RootState } from '../stores';
import type { RootModel } from './index';
import { exportLabelsPdf } from '../utils/export-label';

export type PurchaseOrdersState = {
  byId: Record<string, PurchaseOrder>;
  page: string[];
  selected: string[];
};

interface BulkCreateStatusResponse {
  status: ShipmentEventStatus;
  purchaseOrders: PurchaseOrder[];
}

function normalize(payload: PurchaseOrder[], state: PurchaseOrdersState) {
  return payload.reduce<PurchaseOrdersState['byId']>((obj, item) => {
    obj[item.id] = {
      ...state.byId[item.id],
      ...item,
      quotation: item?.quotation ?? state.byId[item.id]?.quotation,
    };
    return obj;
  }, {});
}

export const purchaseOrders = createModel<RootModel>()({
  state: {
    byId: {},
    page: [],
    selected: [],
  } as PurchaseOrdersState,
  reducers: {
    bulkUpdate: (state: PurchaseOrdersState, payload: PurchaseOrder[]) => {
      return {
        ...state,
        byId: {
          ...state.byId,
          ...normalize(payload, state),
        },
      };
    },
    read: (state: PurchaseOrdersState, payload: PurchaseOrder[]) => {
      return {
        ...state,
        byId: {
          ...state.byId,
          ...normalize(payload, state),
        },
        page: payload.map(({ id }) => id),
      };
    },
    update: (state: PurchaseOrdersState, payload: PurchaseOrder) => {
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.id]: {
            ...state.byId,
            ...payload,
            quotation: payload?.quotation ?? state.byId[payload.id]?.quotation,
          },
        },
      };
    },
    orderSelected: (state: PurchaseOrdersState, ids: string[]) => {
      return {
        ...state,
        selected: ids,
      };
    },
    orderToggled: (
      state: PurchaseOrdersState,
      { ids, onOff }: { ids: string[]; onOff: boolean },
    ) => {
      const set = new Set(state.selected);
      if (onOff) {
        ids.forEach(set.add, set);
      } else {
        ids.forEach(set.delete, set);
      }
      return {
        ...state,
        selected: Array.from(set),
      };
    },
    removeByIds: (state: PurchaseOrdersState, ids: string[]) => {
      const { byId, page, selected } = state;
      const newById = { ...byId };
      ids.forEach((id) => {
        delete newById[id];
      });
      const newPage = page.filter((poId) => !ids.includes(poId));
      const newSelected = selected.filter((poId) => !ids.includes(poId));

      return {
        ...state,
        page: newPage,
        selected: newSelected,
        byId: newById,
      };
    },
  },
  effects: (dispatch) => ({
    async createPurchaseOrder(payload: PurchaseOrderCreate): Promise<PurchaseOrder> {
      const order = await createPurchaseOrder(payload);
      return order;
    },
    async getPurchaseOrders(payload: GetPaginatedPurchaseOrdersInput): Promise<Pagination> {
      const { meta, links, purchaseOrders } = await getPurchaseOrders(payload);
      dispatch.purchaseOrders.read(purchaseOrders);
      return { meta, links };
    },
    async getPurchaseOrdersByIds(ids: string[]): Promise<PurchaseOrder[]> {
      const purchaseOrders = await getPurchaseOrdersByIds(ids);
      dispatch.purchaseOrders.bulkUpdate(purchaseOrders);
      return purchaseOrders;
    },
    async getPurchaseOrderById(id: string) {
      const fetchedOrder = await getPurchaseOrder(id);
      dispatch.purchaseOrders.update(fetchedOrder);
    },
    async bulkEditPurchaseOrders(payload: BulkEditPayload): Promise<void> {
      dispatch.purchaseOrders.bulkUpdate(await bulkUpdatePurchaseOrders(payload));
    },
    async getBulkCreateStatus(eventId: number): Promise<BulkCreateStatusResponse> {
      const { status, purchaseOrders = [] } = await getShipmentEvent(eventId);
      dispatch.purchaseOrders.bulkUpdate(purchaseOrders);
      return { purchaseOrders, status };
    },
    async bulkPayPurchaseOrders(payload: PurchaseOrder[]): Promise<boolean> {
      const isPurchaseOrdersSuccess = await createPurchaseOrdersPayment(payload);
      return isPurchaseOrdersSuccess;
    },
    async updatePurchaseOrder(payload: Partial<PurchaseOrderUpdate>): Promise<PurchaseOrder> {
      const order = await updatePurchaseOrder(payload);
      dispatch.purchaseOrders.update(order);
      return order;
    },
    async removePurchaseOrderByIds(ids: string[]) {
      await removePurchaseOrders(ids);
      dispatch.purchaseOrders.removeByIds(ids);
    },
    async generateLabelsPdf(ids: string[], state) {
      const { byId } = state.purchaseOrders;
      const labels = ids.map((id) => byId[id].labelUrl).filter(Boolean) as string[];
      await exportLabelsPdf(labels);
    },
    async generateLabelsZip(ids: string[], state) {
      const { byId } = state.purchaseOrders;
      const files = ids
        .map((id) => byId[id])
        .filter((file) => Boolean(file.labelUrl))
        .map(({ labelUrl, orderId, shipment, trackingNumber }) => {
          return {
            url: labelUrl,
            filename:
              orderId && trackingNumber
                ? `${orderId}-${trackingNumber}.pdf`
                : `${trackingNumber}.pdf` || undefined,
            lastModified: shipment?.lastUpdate
              ? parseISO(shipment.lastUpdate as unknown as string)
              : undefined,
          };
        }) as FileMeta[];

      const datetime = format(new Date(), 'yyyyMMddHHmmss');
      const labels = await Promise.all(files.map(createInputWithMeta));

      await createZipFile(labels, `labels-exported-at-${datetime}.zip`);
    },
    async bulkCreatePurchaseOrdersQuotation(payload: PurchaseOrder[]): Promise<void> {
      const purchaseOrders = await bulkCreatePurchaseOrdersQuotation(payload);
      dispatch.purchaseOrders.bulkUpdate(purchaseOrders);
    },
  }),
});

export const selectPurchaseOrders = (state: RootState): PurchaseOrder[] => {
  const { byId, page } = state.purchaseOrders;
  return page.map((id) => byId[id]);
};

export const selectPurchaseOrdersSelected = (state: RootState): PurchaseOrder[] => {
  const { byId, selected } = state.purchaseOrders;
  return selected.map((id) => byId[id]);
};
