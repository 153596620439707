import { Models } from '@rematch/core';
import { auth } from './auth';
import { address } from './address';
import { purchaseOrders } from './purchase-orders';
import { services } from './services';
import { settings } from './settings';
import { shipments } from './shipments';
import { transactions } from './transactions';

export interface RootModel extends Models<RootModel> {
  auth: typeof auth;
  address: typeof address;
  purchaseOrders: typeof purchaseOrders;
  services: typeof services;
  settings: typeof settings;
  shipments: typeof shipments;
  transactions: typeof transactions;
}

export const models: RootModel = {
  auth,
  address,
  purchaseOrders,
  services,
  settings,
  shipments,
  transactions,
};
