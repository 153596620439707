import { IsEmail, IsString, MaxLength } from 'class-validator';

export class Credential {
  constructor(props: Partial<Credential>) {
    Object.assign(this, props);
  }

  @IsEmail()
  @MaxLength(255)
  email!: string | undefined;

  @IsString()
  @MaxLength(255)
  password!: string | undefined;
}
