import { IsString } from 'class-validator';
import { Expose, Type } from 'class-transformer';
import { PaymentStatistics } from './PaymentStatistics.dto';
import { Transaction } from './Transaction.dto';

export class Payment {
  constructor(props: Partial<Payment>) {
    Object.assign(this, props);
  }

  @Expose()
  @IsString()
  id!: string;

  @Expose()
  @Type(() => Transaction)
  transaction!: Transaction;

  @Expose()
  @Type(() => PaymentStatistics)
  statistics!: PaymentStatistics;
}
