import React, { Fragment } from 'react';
import { css, LinariaClassName } from '@linaria/core';
import { styled } from '@linaria/react';
import {
  Button,
  List,
  Popover,
  usePopoverOpen,
  Icon,
  zIndex,
  colors,
} from '@spaceshipapp/cambridge';
import rgba from 'polished/lib/color/rgba';
import logo from '../../assets/images/logo.svg';
import { Link, NavLink } from '../../components/Link';
import { useTranslation } from 'react-i18next';

export const NAVBAR_WIDTH = '236px';

interface NavbarProps {
  className?: LinariaClassName;
  isAuthenticated: boolean;
}

const NavbarDesktop: React.FC<NavbarProps> = (props) => {
  const { isAuthenticated } = props;
  const { t } = useTranslation();
  const HELP_CENTER_URL = t('URL.helpCenterURL');

  return (
    <Navbar className={props.className}>
      <Logo>
        <Link to="/">
          <img src={logo} alt="Spaceship Pro" height={28} width={195} />
        </Link>
      </Logo>
      {isAuthenticated && (
        <UL>
          <li>
            <NavLink to="/orders">
              <Icon.Assignment className={iconStyle} width={24} height={24} />
              {t('NavBar.linkTitleOrders')}
            </NavLink>
          </li>
          <li>
            <NavLink to="/shipments">
              <Icon.Flight className={iconStyle} width={24} height={24} />
              {t('NavBar.linkTitleShipments')}
            </NavLink>
          </li>
          <li>
            <NavLink to="/transactions">
              <Icon.Assignment className={iconStyle} width={24} height={24} />
              {t('NavBar.linkTitleTransactions')}
            </NavLink>
          </li>
          <li data-userguiding="menu-settings">
            <NavLink to="/settings">
              <Icon.Settings className={iconStyle} width={24} height={24} />
              {t('NavBar.linkTitleSettings')}
            </NavLink>
          </li>
          <li>
            <a href={HELP_CENTER_URL} target="_blank" rel="noreferrer">
              <Icon.Info className={iconStyle} width={24} height={24} />
              {t('NavBar.linkTitleHelp')}
              <Icon.ExternalLink className={externalStyle} />
            </a>
          </li>
        </UL>
      )}
      <BtnGroup>
        <Popover
          trigger={<Button iconName="Person" variant="link" size="sm" aria-label="Profile" />}
        >
          <Dropdown isAuthenticated={isAuthenticated} />
        </Popover>
      </BtnGroup>
    </Navbar>
  );
};

const Dropdown: React.FC<NavbarProps> = (props) => {
  const { t } = useTranslation();
  const { isAuthenticated } = props;
  const togglePopover = usePopoverOpen();

  const handleClose = () => {
    togglePopover(false);
  };

  return (
    <Menu onClick={handleClose}>
      <List>
        {isAuthenticated ? (
          <Fragment>
            <Item>
              <Link to="/logout">{t('NavBar.linkTitleSignOut')}</Link>
            </Item>
          </Fragment>
        ) : (
          <Fragment>
            <Item>
              <Link to="/login">{t('NavBar.linkTitleSignIn')}</Link>
            </Item>
          </Fragment>
        )}
      </List>
    </Menu>
  );
};

const Navbar = styled.header`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: ${NAVBAR_WIDTH};
  padding: 1rem;
  background-color: ${colors.text};
  color: #fff;
  z-index: ${zIndex.HEADER};
`;

const Logo = styled.div`
  display: flex;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`;

const UL = styled.ul`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 2rem;
  margin-top: 4rem;
  margin-bottom: auto;

  a {
    color: ${colors.surface};
    font-weight: 500;
    text-decoration: none;
    opacity: 0.6;

    &.active {
      opacity: 1;
    }
  }

  svg {
    display: inline-block;
  }
`;

const BtnGroup = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-top: auto;
  align-self: flex-end;

  button {
    color: ${colors.surface};
  }
`;

const Item = styled.li`
  display: flex;
  cursor: default;

  > * {
    display: block;
    width: 100%;
    padding: 1em;
    text-decoration: none;
  }
`;

const Menu = styled.div`
  border: solid 1px ${colors.border};
  border-radius: 0.25rem;
  box-shadow: 0 0.25em 0.75em ${rgba(colors.text, 0.08)};
  background-color: ${colors.surface};
  width: max(320px, 100%);
`;

const iconStyle = css`
  margin-right: 1rem;
`;

const externalStyle = css`
  display: inline-block;
  margin-left: 0.5rem;
`;

export default NavbarDesktop;
