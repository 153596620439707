import { apiService } from './api';
import { deserializeArray } from 'class-transformer';
import { Service } from '../dto';

// TODO: fetch data from api and remove hardcoded service values in portal-webapp

export async function getCountries(): Promise<Record<string, any>[]> {
  const { data } = await apiService({
    method: 'get',
    path: '/countries',
  });
  return data?.resources;
}

export async function getCarrierServices(): Promise<Service[]> {
  try {
    const res = await apiService({
      method: 'get',
      path: '/carrier-services/services/bulk-order',
    });
    const { success, data, message } = res.data;
    if (!success) throw new Error(message);
    return deserializeArray(Service, JSON.stringify(data?.resources || []));
  } catch (err: any) {
    return Promise.reject(err?.response?.data);
  }
}
