import { sendShipmentInvoiceDownloaded } from '../../../analytics/transactions';

export const isDataFiltered = (totalValue: number, filteredValue: number): boolean =>
  filteredValue !== totalValue;

export const handleInvoiceDownload = (
  invoiceUrl?: string,
  courier_service?: string,
  waybill_no?: string,
  total_value?: string,
  destination?: string,
): void => {
  if (invoiceUrl) {
    window.open(invoiceUrl, '_blank');
    sendShipmentInvoiceDownloaded({
      courier_service,
      waybill_no,
      total_value,
      destination,
    });
  }
};
