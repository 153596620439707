import { User } from '../contexts/types';
import formatter from '../utils/formatter';
import { ProfileUpdate } from '../dto/ProfileUpdate.dto';

const translateAvgParcels = (monthlyShipments: ProfileUpdate['monthlyShipments']) => {
  if (!monthlyShipments.min) return `<${monthlyShipments.max}`;
  if (!monthlyShipments.max) return `>${monthlyShipments.min}`;
  return `${monthlyShipments.min}-${monthlyShipments.max}`;
};

const APP_VERSION = process.env.REACT_APP_VERSION || '';
const VARIANT = process.env.REACT_APP_BRANCH || process.env.BRANCH || 'local';

const superOpts = {
  platform: 'web',
  variant: VARIANT,
  version: APP_VERSION,
  device_locale: window?.navigator?.language || 'en',
  locale: 'en',
};

/* eslint-disable @typescript-eslint/no-explicit-any */
declare global {
  interface Window {
    analytics: any;
    userGuiding: any;
  }
}
/* eslint-enable @typescript-eslint/no-explicit-any */

class Analytics {
  timers: Record<string, number> = {};
  identify(user: User, formData?: ProfileUpdate, isProfileUpdate?: boolean) {
    let hubspotContact = {};
    const { ref, name, email, userType, phone, phoneCc } = user;
    if (isProfileUpdate) {
      hubspotContact = {
        firstname: name,
        lastname: '',
        website: formData?.website ?? '',
        referral_code: user.referrerCode,
        avg_monthly_no_of_parcel:
          formData?.monthlyShipments && translateAvgParcels(formData.monthlyShipments),
        spaceship_pro_user: userType === 'business',
      };
    }

    if (window.analytics) {
      window.analytics.identify(
        ref,
        {
          name,
          email,
          phone: phone && phoneCc ? formatter.phone(phone, phoneCc) : '',
          type: userType === 'business' ? 'business' : 'personal',
          user_type: userType === 'business' ? 'business' : 'personal',
          ...hubspotContact,
        },
        {},
        () => {
          // HubSpot need a fake page call to update contacts.
          // https://segment.com/docs/connections/destinations/catalog/hubspot/#identify
          // https://developers.hubspot.com/docs/api/events/tracking-code#identify-a-visitor
          if (window.analytics && formData) {
            window.analytics.page();
          }
        },
      );
    }

    if (window.userGuiding) {
      window.userGuiding.identify(ref, {
        email,
        name,
      });
    }
  }

  reset() {
    if (window.analytics) {
      window.analytics.reset();
    }
  }

  event(name: string, properties = {}) {
    if (window.analytics) {
      window.analytics.track(name, {
        ...superOpts,
        ...properties,
      });
    }
  }

  page() {
    if (window.analytics) {
      window.analytics.page({
        ...superOpts,
      });
    }
  }

  setEventTimer(eventName: string, timestamp: number) {
    this.timers[eventName] = timestamp;
  }

  removeEventTimer(eventName: string): number {
    const timestamp = this.timers[eventName];
    if (timestamp) {
      delete this.timers[eventName];
    }
    return timestamp;
  }
}

export default new Analytics();
