import { IsEnum, IsOptional, IsString, IsUrl, MaxLength } from 'class-validator';
import { Expose } from 'class-transformer';
import { Country } from '../common/enums';
import { Money } from './Money.dto';
import { TransactionsType } from '../types/transactions';

export class Transaction {
  constructor(props: Partial<Transaction>) {
    Object.assign(this, props);
  }

  @Expose()
  @IsString()
  id!: string;

  @Expose()
  @IsString()
  orderId!: string;

  @Expose({ name: 'reasonType' })
  @IsEnum(TransactionsType)
  type!: TransactionsType;

  @Expose()
  @IsOptional()
  @IsString()
  logisticProviderName?: string;

  @Expose()
  @IsOptional()
  @IsString()
  logisticServiceName?: string;

  @Expose()
  @IsOptional()
  @IsString()
  trackingNumber?: string;

  @Expose()
  @IsOptional()
  @IsString()
  @MaxLength(2)
  receiverCountry?: Country;

  @Expose()
  @IsOptional()
  @IsUrl()
  invoiceUrl?: string;

  @Expose()
  createdAt!: Date;

  @Expose()
  @IsOptional()
  finalTotal?: Money;
}
