import { createModel } from '@rematch/core';
import { deserialize } from 'class-transformer';
import { AddressBook } from '../dto';
import { Country } from '../common/enums';
import { getDefaultAddressBook } from '../services/addressBook';
import type { RootModel } from './index';

export type AddressState = Record<string, AddressBook>;

export const address = createModel<RootModel>()({
  state: {} as Record<string, AddressBook>,
  reducers: {
    readDefault: (state: AddressState, payload: AddressBook) => ({
      ...state,
      [payload.country]: payload,
    }),
  },
  effects: (dispatch) => ({
    async getDefault(country: Country): Promise<AddressBook> {
      const res = await getDefaultAddressBook(country);
      const address = deserialize(AddressBook, JSON.stringify(res));
      dispatch.address.readDefault(address);
      return address;
    },
  }),
});
