import React, { Fragment, useMemo, useState } from 'react';
import {
  Button,
  Input,
  Icon,
  Text,
  Select,
  Option,
  FormControl,
  FormLabel,
  SelectButton,
  Modal,
  usePopoverOpen,
  OptGroup,
  colors,
} from '@spaceshipapp/cambridge';
import { useLocation, useHistory } from 'react-router-dom';
import { css } from '@linaria/core';
import { styled } from '@linaria/react';
import { createURLString } from '../../hooks/useQuery';
import { COUNTRIES_SORTED } from '../../common/constants/countries';
import formatter from '../../utils/formatter';
import DatePicker, { DatePickerProps } from '../../containers/DatePicker';
import { DatePicker as DatePickerDto } from '../../dto';
import { FormProvider, useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../stores';
import SearchTextArea from '../../components/SearchTextArea';
import HiddenButton from '../../components/SearchTextArea/HiddenButton';
import { searchTextAreaForm } from '../../schemas/searchTextArea';
import { parseKeywords } from './utils';

export enum OrdersStatusFilter {
  ALL = 'all',
  IMPORTED = 'imported',
  LABEL_CREATED = 'label-created',
}

export enum TransactionsStatusFilter {
  All = 'all',
  SHIPMENT_CHARGE = 'shipmentCharge',
  ADJUSTMENT = 'adjustment',
  REFUND = 'refund',
}

export enum FilterType {
  ORDERS = 'orders',
  TRANSACTIONS = 'transactions',
}

interface Props {
  filterType: FilterType;
  page: number;
  search: string;
  multiSearch?: string;
  status: string;
  carrier?: string;
  receiverCountry: string;
  createdFrom: string;
  createdTo: string;
}

export type OnSearchType = React.ChangeEvent<HTMLInputElement> | searchTextAreaForm;

const SearchFilters: React.FC<Props> = ({
  page,
  status,
  carrier,
  search,
  multiSearch,
  receiverCountry,
  createdFrom,
  createdTo,
  filterType,
}) => {
  const history = useHistory();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.resolvedLanguage.split('-')[0];
  const [isOpen, setIsOpen] = useState<boolean>();
  const [focused, setFocused] = useState<boolean>(false);
  const services = useSelector((state: RootState) => state.services);
  const defaultOptionsValue = 'All';
  const keywords = parseKeywords(search).join(',');
  const value =
    !focused && keywords.length > 80 ? [keywords.substring(0, 80), '...'].join('') : keywords;

  const params = useMemo(
    () => Object.fromEntries(new URLSearchParams(location.search)),
    [location],
  );

  const onSearch = (e: OnSearchType) => {
    const url = createURLString(location.pathname, {
      ...params,
      search: e?.target?.value ? e?.target?.value : !(typeof e === 'object') ? e : '',
      multiSearch: !(typeof e === 'object') ? e : '',
      page: 1,
    });
    history.replace(url);
  };

  const onFilterChange = (options = {}) => {
    const url = createURLString(location.pathname, {
      ...params,
      ...options,
      page: 1,
    });
    history.replace(url);
  };

  const onResetDate = () => {
    const url = createURLString(location.pathname, {
      ...params,
      createdFrom: '',
      createdTo: '',
      page: 1,
    });
    history.replace(url);
  };

  const onReset = () => {
    const url = createURLString(location.pathname, { page });
    history.replace(url);
  };

  const handleOnClick = () => {
    setIsOpen(!isOpen);
  };

  const renderStatusFilterOptions = (type: FilterType) => {
    switch (type) {
      case FilterType.TRANSACTIONS:
        return (
          Object.keys(TransactionsStatusFilter) as (keyof typeof TransactionsStatusFilter)[]
        ).map((key) => (
          <Option key={key} value={key}>
            {t(`SearchFilter.options.${TransactionsStatusFilter[key]}`)}
          </Option>
        ));
      default:
        return (Object.keys(OrdersStatusFilter) as (keyof typeof OrdersStatusFilter)[]).map(
          (key) => (
            <Option key={key} value={OrdersStatusFilter[key]}>
              {t(`SearchFilter.options.${OrdersStatusFilter[key]}`)}
            </Option>
          ),
        );
    }
  };

  return (
    <Fragment>
      <Input
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        name="search"
        id="search"
        prependAddon={<Icon.Search width={20} height={20} />}
        appendAddon={
          filterType === FilterType.TRANSACTIONS && (
            <Button className={searchButtonStyle} variant="link" onClick={handleOnClick}>
              {t('SearchFilter.inputPlaceholder.searchMultipleButton')}
            </Button>
          )
        }
        placeholder={t(`SearchFilter.inputPlaceholder.${filterType}`)}
        defaultValue={value}
        value={multiSearch ? value : search}
        onChange={onSearch}
      />
      <Modal
        className={modalStyle}
        title={t('SearchFilter.searchMultipleModal.title')}
        trigger={<HiddenButton isOpen={isOpen} />}
      >
        <SearchTextArea multiSearch={search} onSearch={onSearch} filterType={filterType} />
      </Modal>

      <Container>
        <FormControl>
          <FormLabel htmlFor="status">{t('SearchFilter.formLabelOrder')}</FormLabel>
          <Select
            id="status"
            selectedItem={status.length === 0 ? defaultOptionsValue : status}
            onChange={(status) =>
              onFilterChange({
                status: status !== defaultOptionsValue && status,
              })
            }
            className={selectStyle}
          >
            {renderStatusFilterOptions(filterType)}
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="receiverCountry">{t('SearchFilter.formLabelCountry')}</FormLabel>
          <Select
            id="receiverCountry"
            selectedItem={receiverCountry.length === 0 ? defaultOptionsValue : receiverCountry}
            onChange={(receiverCountry) =>
              onFilterChange({
                receiverCountry: receiverCountry !== defaultOptionsValue && receiverCountry,
              })
            }
            defaultLabel={t('SearchFilter.buttonSelect')}
            className={selectStyle}
          >
            <Option value={defaultOptionsValue}>
              <span style={{ whiteSpace: 'nowrap' }}>
                {t('SearchFilter.optionDefaultLabelCountry')}
              </span>
            </Option>
            {COUNTRIES_SORTED.map((region) => (
              <Option key={region['alpha-2']} value={region['alpha-2']}>
                <span style={{ whiteSpace: 'nowrap' }}>
                  {region.name[currentLanguage as keyof { zh: string; en: string }]}
                </span>
              </Option>
            ))}
          </Select>
        </FormControl>
        {filterType === FilterType.TRANSACTIONS && (
          <FormControl>
            <FormLabel htmlFor="carrier">{t('SearchFilter.formLabelCarrier')}</FormLabel>
            <Select
              id="carrier"
              selectedItem={carrier?.length === 0 ? 'All' : carrier}
              onChange={(carrier) =>
                onFilterChange({
                  carrier: carrier !== defaultOptionsValue && carrier,
                })
              }
              className={selectStyle}
            >
              <Option value={'All'}>
                <span style={{ whiteSpace: 'nowrap' }}>{t('SearchFilter.options.all')}</span>
              </Option>
              {Object.keys(services.carrierServicesIdMap).map((id: string) => (
                <OptGroup key={id} label={services.carrierNameMap[id] || id}>
                  {services.carrierServicesIdMap[id].map((slug: any) => (
                    <Option key={slug} value={slug}>
                      {services.serviceNameMap[slug] || slug}
                    </Option>
                  ))}
                </OptGroup>
              ))}
            </Select>
          </FormControl>
        )}

        <Modal
          title={t('SearchFilter.filterTitle')}
          trigger={
            <FormControl>
              <FormLabel>{t('SearchFilter.formLabelDate')}</FormLabel>
              <SelectButton className={selectStyle}>
                {createdFrom && createdTo ? (
                  <Fragment>
                    {`${formatter.date(
                      createdFrom,
                      currentLanguage,
                      t('DateFormat.dateFormat'),
                    )} - ${formatter.date(createdTo, currentLanguage, t('DateFormat.dateFormat'))}`}
                  </Fragment>
                ) : (
                  <Text>{t('SearchFilter.buttonSelect')}</Text>
                )}
              </SelectButton>
            </FormControl>
          }
        >
          <DatePickerForm
            validDateRange={30}
            createdFrom={createdFrom}
            createdTo={createdTo}
            onReset={onResetDate}
            onSubmit={onFilterChange}
          />
        </Modal>
        <Button size="sm" variant="default" onClick={onReset}>
          {t('SearchFilter.buttonClearAll')}
        </Button>
      </Container>
    </Fragment>
  );
};

const resolver = classValidatorResolver(DatePickerDto);

interface DatePickerFormProps extends DatePickerProps {
  onSubmit(data: DatePickerDto): void;
}

const DatePickerForm: React.FC<DatePickerFormProps> = ({
  validDateRange,
  createdFrom,
  createdTo,
  onReset,
  onSubmit,
}) => {
  const togglePopover = usePopoverOpen();

  const methods = useForm<DatePickerDto>({ resolver });

  const handleReset = () => {
    togglePopover(false);
    onReset();
  };
  const handleSubmit = (data: DatePickerDto) => {
    togglePopover(false);
    onSubmit(data);
  };
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <DatePicker
          validDateRange={validDateRange}
          createdFrom={createdFrom}
          createdTo={createdTo}
          onReset={handleReset}
        />
      </form>
    </FormProvider>
  );
};

const searchButtonStyle = css`
  padding: 0;
  &::before {
    content: none;
  }
`;

const Container = styled.div`
  display: flex;
  gap: 1em;
`;

const selectStyle = css`
  min-width: 14em;
`;

const modalStyle = css`
  color: ${colors.text};
`;

export default SearchFilters;
