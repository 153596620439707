import { styled } from '@linaria/react';
import { css } from '@linaria/core';
import { colors, Stack, Text, Button } from '@spaceshipapp/cambridge';

interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  textAlign?: string;
}

interface FontProps {
  fontSize: string;
  fontWeight?: string;
}

interface ButtonProps {
  cancelState?: boolean;
}

export const Row = styled.div`
  display: grid;
  gap: 1rem;
  align-content: center;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
`;

export const AdjustmentRow = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
`;

export const ContentWrapper = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  flex-basis: 40%;
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
`;

export const StyledStack = styled(Stack)`
  margin-top: 1.5rem;
  max-width: 39.75rem;
`;

export const StyledResultStack = styled(Stack)`
  margin-top: 0.5rem;
`;

export const StyledText = styled(Text)<FontProps>`
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 600)};
  font-size: ${(props) => props.fontSize};
`;

export const StyledPricing = styled(Text)`
  font-weight: 600;
  font-size: 1.5rem;
`;

export const ButtonSubmit = styled(Button)<ButtonProps>`
  background: ${(props) => (props.cancelState ? '' : `${colors.error}`)};
  &:hover {
    background: ${(props) => (props.cancelState ? '' : 'RGBA(240,68,46,0.8)')} !important;
  }
`;

export const modalStyle = css`
  max-width: 39.75rem;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .title {
    display: flex;
    gap: 1rem;
    font-size: 1.5rem;
    align-items: center;
  }
  .close {
    position: absolute;
    right: 1.5rem;
    cursor: pointer;
  }
`;

export const Hint = styled.a`
  font-size: 0.875rem;
  text-decoration: none;
  color: ${colors.subtext};
`;

export const PickFeeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
`;

export const successIconStyle = css`
  color: ${colors.success};
`;

export const backButtonStyle = css`
  max-width: 15rem;
`;

export const modalTitle = css`
  position: absolute;
  top: 2.5rem;
`;
