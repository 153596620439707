import {
  ValidatorConstraint,
  ValidatorConstraintInterface,
  ValidationArguments,
} from 'class-validator';

@ValidatorConstraint({ name: 'IsDateBeforeOrEqual', async: false })
export class IsDateBeforeOrEqual implements ValidatorConstraintInterface {
  validate(propertyValue: string, args: ValidationArguments): boolean {
    return new Date(propertyValue) <= new Date((args.object as any)[args.constraints[0]]);
  }

  defaultMessage(args: ValidationArguments): string {
    return `"${args.property}" must be before "${args.constraints[0]}"`;
  }
}
