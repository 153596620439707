import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TBody } from '../../../components/Table';
import { selectAllPayments, selectCurrentPaymentTransactions } from '../../../models/transactions';
import { StyledTable, Wrapper } from './styled';
import TransactionRow, { RowDataType } from './TransactionRow';
import TransactionsTableHeader from './TransactionsTableHeader/index';
import SkeletonTable from '../../../components/SkeletonTable';
import { PaginationMeta } from '../../../types';
import useQuery, { createURLString } from '../../../hooks/useQuery';
import _toNumber from 'lodash/toNumber';
import { FormControl, FormLabel, Option, Pagination, Select, Stack } from '@spaceshipapp/cambridge';
import { BottomBar, pageSizeSelectStyle } from '../../../pages/Transactions/Transactions';
import { useTranslation } from 'react-i18next';
import { useHistory, Link as ReactRouterLink } from 'react-router-dom';
import { TransactionsStatusFilter } from '../../SearchFilters';
import { getDatePickerDefaultDates } from '../../../utils/helpers';
import { sendTransactionRecordsBreakdownFetched } from '../../../analytics/transactions';
import PaymentRow from './PaymentRow';
import { parseKeywords } from '../../SearchFilters/utils';

export interface TransactionsListProps {
  isLoaded?: boolean;
  isModalElement?: boolean;
  paymentId?: string;
}

const TransactionsList: React.FC<TransactionsListProps> = ({ isModalElement, paymentId }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [paginationMeta, setPaginationMeta] = useState<PaginationMeta>();
  const { t } = useTranslation();
  const history = useHistory();
  const query = useQuery();
  const dispatch = useDispatch();
  const page = _toNumber(query.get('page') || '1');
  const perPage = Number(query.get('pageSize')) || 15;
  const breakdownPage = _toNumber(query.get('breakdownPage') || '1');
  const breakdownPageSize = Number(query.get('breakdownPageSize')) || 15;
  const search = query.get('search') || '';
  const reasonType = (query.get('status') as TransactionsStatusFilter) || '';
  const receiverCountry = query.get('receiverCountry') || '';
  const carrier = query.get('carrier') || '';
  const createdFrom = query.get('createdFrom') || getDatePickerDefaultDates().prevDate;
  const createdTo = query.get('createdTo') || getDatePickerDefaultDates().currDate;
  const { lastPage } = paginationMeta || {};
  const { getPurchaseOrdersTransactionsByPaymentId } = dispatch.transactions;
  const payments = useSelector(selectAllPayments);
  const transactions = useSelector(selectCurrentPaymentTransactions);

  const refetchTransactions = useCallback(async () => {
    setIsLoading(true);

    const params = {
      paymentId,
      page: breakdownPage,
      reasonTypes: reasonType ? [reasonType] : '',
      keywords: parseKeywords(search),
      receiverCountries: receiverCountry ? [receiverCountry] : '',
      from: createdFrom,
      to: createdTo,
      perPage: breakdownPageSize,
      carrierServices: carrier ? [carrier] : '',
    };
    const { meta } = await getPurchaseOrdersTransactionsByPaymentId(params);

    setPaginationMeta(meta);
    setIsLoading(false);
    sendTransactionRecordsBreakdownFetched({
      courier_service: carrier,
      destination: receiverCountry,
      input_count: params?.keywords?.length,
      transaction_date_max: createdTo,
      transaction_date_min: createdFrom,
      transaction_type: reasonType,
      payment_id: paymentId as string,
      breakdown_transaction_count: paginationMeta?.total,
    });
  }, [
    search,
    reasonType,
    receiverCountry,
    carrier,
    createdFrom,
    createdTo,
    isModalElement,
    paymentId,
    breakdownPage,
    breakdownPageSize,
  ]);

  useEffect(() => {
    if (isModalElement && paymentId) {
      refetchTransactions();
    }
  }, [
    search,
    reasonType,
    receiverCountry,
    carrier,
    createdFrom,
    createdTo,
    isModalElement,
    paymentId,
    breakdownPage,
    breakdownPageSize,
  ]);

  const handlePageSizeChange = (value: string) => {
    const url = createURLString(location.pathname, {
      breakdownPage: 1,
      search,
      carrier,
      status: reasonType,
      receiverCountry,
      createdFrom,
      createdTo,
      breakdownPageSize: Number(value),
      page,
      pageSize: perPage,
    });
    history.replace(url);
  };

  return (
    <Stack>
      <Wrapper isModalElement={isModalElement}>
        <SkeletonTable isLoaded={!isLoading}>
          <StyledTable hoverable isModalElement={isModalElement}>
            <TransactionsTableHeader />
            <TBody>
              {paymentId
                ? transactions.map((transaction, index) => (
                    <TransactionRow
                      key={`${transaction.id}-${index}`}
                      rowData={transaction as RowDataType}
                    />
                  ))
                : payments.map((payment, index) => (
                    <PaymentRow key={`${payment.id}-${index}`} rowData={payment} />
                  ))}
            </TBody>
          </StyledTable>
        </SkeletonTable>
        <BottomBar>
          {paymentId && (paginationMeta as PaginationMeta)?.total > 15 && (
            <FormControl className={pageSizeSelectStyle}>
              <FormLabel>{t('ShipmentList.formLabelMaxRowPerPage')}</FormLabel>
              <Select
                id="pageSize"
                onChange={handlePageSizeChange}
                selectedItem={breakdownPageSize}
              >
                <Option value={15}>15</Option>
                <Option value={50}>50</Option>
              </Select>
            </FormControl>
          )}
          {transactions?.length > 0 && paymentId && breakdownPage && lastPage && (
            <Pagination
              size="xs"
              rootPath={`${createURLString(location.pathname, {
                breakdownPageSize,
                search,
                carrier,
                status: reasonType,
                receiverCountry,
                createdFrom,
                createdTo,
                page,
                pageSize: perPage,
              })}&breakdownPage=`}
              currentPage={breakdownPage}
              lastPage={lastPage}
              link={ReactRouterLink}
              loading={isLoading}
              hideLastButton
            />
          )}
        </BottomBar>
      </Wrapper>
    </Stack>
  );
};

export default TransactionsList;
