import { deserializeArray, plainToInstance } from 'class-transformer';
import { TransactionsStatusFilter } from '../containers/SearchFilters';
import { Payment } from '../dto/Payment.dto';
import { PaymentSummary } from '../dto/PaymentSummary.dto';
import { Transaction } from '../dto/Transaction.dto';
import { Pagination } from '../types';
import { apiService } from './api';
import { PaymentPayload, TransactionPayload } from '../types/transactions';
import { getShippingFee } from './purchaseOrders';

export interface GetTransactionsInputType {
  paymentId?: string;
  page?: number;
  reasonTypes?: TransactionsStatusFilter[];
  from?: string;
  to?: string;
  keywords?: string[];
  carrierServices?: string[];
  receiverCountries?: string[];
  perPage?: number;
}

export async function getPurchaseOrdersPaymentSummary(
  input: GetTransactionsInputType,
): Promise<PaymentSummary> {
  try {
    const { keywords, reasonTypes, carrierServices, receiverCountries, from, to } = input;
    const path = '/purchase-orders/payment-summary';
    const res = await apiService({
      method: 'get',
      path,
      params: {
        keywords,
        reasonTypes,
        receiverCountries,
        carrierServices,
        from,
        to,
      },
    });
    const { data } = res?.data;

    if (data) {
      const paymentSummary = {
        ...data,
        chargeAmount: getShippingFee(data.chargeAmount),
        refundAmount: getShippingFee(data.refundAmount),
      };
      return plainToInstance(PaymentSummary, paymentSummary as PaymentSummary);
    }
    throw new Error('Failed to get payment summary');
  } catch (err) {
    throw err;
  }
}

export async function getPurchaseOrdersPayments(
  input: GetTransactionsInputType,
): Promise<{ payments: Payment[] } & Pagination> {
  try {
    const {
      page,
      keywords,
      reasonTypes,
      carrierServices,
      receiverCountries,
      from,
      to,
      perPage = 15,
    } = input;
    const path = '/purchase-orders/payments';

    const res = await apiService({
      method: 'get',
      path,
      params: {
        page,
        keywords,
        reasonTypes,
        receiverCountries,
        carrierServices,
        from,
        to,
        orderBy: 'createdAt',
        sorting: 'DESC',
        perPage,
      },
    });
    const { data } = res?.data;

    if (data) {
      const { resources, meta, links } = data;
      const transactions = resources.map(parsePayment);

      return {
        payments: deserializeArray(Payment, JSON.stringify(transactions)),
        meta,
        links,
      };
    }
    throw new Error('Failed to get payments');
  } catch (err) {
    throw err;
  }
}

export async function getPurchaseOrdersTransactionsByPaymentId(
  input: GetTransactionsInputType,
): Promise<{ transactions: Transaction[] } & Pagination> {
  try {
    const {
      paymentId,
      page,
      keywords,
      reasonTypes,
      carrierServices,
      receiverCountries,
      from,
      to,
      perPage = 15,
    } = input;
    const path = `/purchase-orders/payments/${paymentId}/transactions`;
    const res = await apiService({
      method: 'get',
      path,
      params: {
        page,
        keywords,
        reasonTypes,
        receiverCountries,
        carrierServices,
        from,
        to,
        orderBy: 'createdAt',
        sorting: 'DESC',
        perPage,
      },
    });
    const { data } = res?.data;

    if (data) {
      const { resources, meta, links } = data;
      const transactions = resources.map(parseTransaction);

      return {
        transactions: deserializeArray(Transaction, JSON.stringify(transactions)),
        meta,
        links,
      };
    }
    throw new Error('Failed to get transactions');
  } catch (err) {
    throw err;
  }
}

const parsePayment = (payment: PaymentPayload) => {
  return {
    ...payment,
    transaction: {
      orderId: payment.shipment.orderNumber,
      trackingNumber: payment.shipment.trackingNumber,
      logisticProviderName: payment.shipment.carrierService.carrierName.en,
      logisticServiceName: payment.shipment.carrierService.name.en,
      receiverCountry: payment.shipment.toAddress.country,
      invoiceUrl: payment.invoice.url,
      createdAt: payment.createdAt,
      reasonType: payment.reasonType,
    },
    statistics: {
      ...payment.statistics,
      totalPaymentAmount: getShippingFee(payment.statistics.totalPaymentAmount),
      filteredPaymentAmount: getShippingFee(payment.statistics.filteredPaymentAmount),
    },
  };
};

const parseTransaction = (transaction: TransactionPayload) => {
  return {
    ...transaction,
    id: transaction.payment.id,
    finalTotal: getShippingFee(transaction.finalTotal),
    orderId: transaction.shipment.orderNumber,
    trackingNumber: transaction.shipment.trackingNumber,
    logisticProviderName: transaction.shipment.carrierService.carrierName?.en,
    logisticServiceName: transaction?.shipment.carrierService.name.en,
    receiverCountry: transaction.shipment.toAddress.country,
    invoiceUrl: transaction.invoice.url,
    createdAt: transaction.payment.createdAt,
    reasonType: transaction.payment.reasonType,
  };
};
