import { Expose } from 'class-transformer';
import { IsEnum, IsNotEmpty, IsArray } from 'class-validator';

import { LogisticProviderCode, LogisticServiceCode } from '../common/enums';
import { IncotermType } from '../common/enums';

export class CarrierServiceForm {
  constructor(props: Partial<CarrierServiceForm>) {
    Object.assign(this, props);
  }

  @Expose()
  @IsEnum(LogisticProviderCode)
  @IsNotEmpty()
  logisticProvider!: string;

  @Expose()
  @IsEnum(LogisticServiceCode)
  @IsNotEmpty()
  logisticServiceCode!: string;

  @Expose()
  @IsArray()
  @IsNotEmpty()
  incoterms!: IncotermType[];
}
